
export class UIUtils {

  static validateNumber(val: any, min: any, max: any, decimals: number) {

    if(isNaN(val) || (!val && val !== 0)) {
      return false;
    }

    const v = +val;

    if(min !== null && v < min) {
      return false;
    }
    if(max !== null && v > max) {
      return false;
    }
    let str = '' + val;
    if (str.indexOf(".") !== -1) {
      str = str + '0';
      const dec = str.split(".")[1].length || 0;
      if (dec > decimals + 1){
        return false;
      }
    }
    

    return true;
  }
  
}
  