import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppCtx, { UnsavedActionEnum } from '../../AppCtx';
import { PAGE_NAVIGATION } from '../../core/AppSettings';
import { AuthService } from '../../services/AuthService';
import Logo from '../ui-elements/logo/Logo';
import './Sidebar.scss';

function Sidebar() {
  
  const [active, setActive] = useState('');
  const appCtxRef = React.useContext(AppCtx);
  const location = useLocation();
  const [unsavedActionOn, setUnsavedActionOn] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {

    let found = false;
    for(const item of PAGE_NAVIGATION) {
      if (location.pathname.indexOf(item.path) >= 0) {
        setActive(item.path);
        found = true;
        break;
      }      
    }

    if (!found) {
      if (location.pathname === '/') {
        setActive(PAGE_NAVIGATION[0].path);
      } else {
        setActive('');

      }

    }

  }, [location.pathname]);

  const link = useCallback((link: string) => {

    if (appCtxRef.ui.unsavedActionOn === UnsavedActionEnum.EXIST) {
      setUnsavedActionOn(link);
      appCtxRef.setUi({...appCtxRef.ui, unsavedActionOn: UnsavedActionEnum.CHECK});
      return;
    }

    navigate(link, { replace: true });
    appCtxRef.setUi({...appCtxRef.ui, sidebar: false});
  }, [appCtxRef, navigate]);

  const logout = () => {
    AuthService.logout();
    appCtxRef.setUi({...appCtxRef.ui, loading: true, sidebar: false});
  }
 
  useEffect(() => {
    if (unsavedActionOn && (appCtxRef.ui.unsavedActionOn === UnsavedActionEnum.SAVE || appCtxRef.ui.unsavedActionOn === UnsavedActionEnum.DISCARD)) {
      link(unsavedActionOn);
      setUnsavedActionOn(null);
    } else if (appCtxRef.ui.unsavedActionOn === UnsavedActionEnum.EXIST) {
      setUnsavedActionOn(null);
    }
  }, [appCtxRef.ui.unsavedActionOn, unsavedActionOn, link]);

  return (
    <div className='app-sidebar'>

      <span className={`app-sidebar-banner-mobile`}>
        <Logo />
      </span>

      {PAGE_NAVIGATION.map(function(item, idx){

        
        return (
          <span className={`app-sidebar-item ${item.path === active ? "active" : ""} ${item.mobile ? "app-sidebar-item-mobile" : ""}`} 
            key={idx} onClick={() => {link(item.path)}}>
            <img className='noactive' src={item.img} alt=""/>
            <img className='active' src={item.imgActive} alt=""/>
            {item.name}
          </span>
        )
      })}

      <span className={`app-sidebar-item app-sidebar-item-mobile`} onClick={logout}>
        Logout
      </span>

      <span className={`app-sidebar-toggle ${appCtxRef.ui.sidebar ? "active" : "noactive"}`} onClick={() => {appCtxRef.setUi({...appCtxRef.ui, sidebar: !appCtxRef.ui.sidebar});}} >
        <img className='noactive' src='/assets/img/icons/chevrons-right-gray.svg' alt=""/>
        <img className='active' src='/assets/img/icons/chevrons-left-gray.svg' alt=""/>
      </span>
        
    </div>
  );
}
export default Sidebar;
