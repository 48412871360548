import axios from "axios";

export class ManufacturersService {

  static all() {
    return axios({
      method: 'get',
      url: '/api/manufacturer'
    });
  }


  static save(data: any) {
    return axios({
      method: 'post',
      url: '/api/manufacturer',
      data
    });
  }
  
  static retire(id: number) {
    return axios({
      method: 'put',
      url: '/api/manufacturer/retire',
      data : [{id}]
    });
  }


  static getFlux(id: number) {
    return axios({
      method: 'get',
      url: '/api/color/' + id + '/flux'
    });
  }

  static getBinningFlux(cid: number, id: number) {
    return axios({
      method: 'get',
      url: '/api/color/' + cid + '/flux/' + id + '/active'
    })
  }
  
  static saveFlux(data: any) {
    return axios({
      method: 'post',
      url: '/api/flux',
      data
    });
  }

  static retireFlux(id: number) {
    return axios({
      method: 'put',
      url: '/api/flux/retire',
      data : [{id}]
    });
  }

  static getForwardvoltage(id: number) {
    return axios({
      method: 'get',
      url: '/api/color/' + id + '/forwardvoltage'
    });
  }

  static getBinningForwardVoltage(cid: number, id: number) {
    return axios({
      method: 'get',
      url: '/api/color/' + cid + '/forwardvoltage/' + id + '/active'
    })
  }
  
  static retireForwardvoltage(id: number) {
    return axios({
      method: 'put',
      url: '/api/forwardvoltage/retire',
      data : [{id}]
    });
  }

  static saveForwardvoltage(data: any) {
    return axios({
      method: 'post',
      url: '/api/forwardvoltage',
      data
    });
  }

  static getChromaticity(id: number) {
    return axios({
      method: 'get',
      url: '/api/color/' + id + '/chromaticity'
    })
  }

  static getBinningChromaticity(cid: number, id: number) {
    return axios({
      method: 'get',
      url: '/api/color/' + cid + '/chromaticity/' + id + '/active'
    })
  }

  static saveChromaticity(data: any) {
    return axios({
      method: 'post',
      url: '/api/chromaticity',
      data
    });
  }

  static retireChromaticity(id: number) {
    return axios({
      method: 'put',
      url: '/api/chromaticity/retire',
      data : [{id}]
    });
  }

  static getWavelength(id: number) {
    return axios({
      method: 'get',
      url: '/api/color/' + id + '/wavelength'
    })
  }

  static getBinningWavelength(cid: number, id: number) {
    return axios({
      method: 'get',
      url: '/api/color/' + cid + '/wavelength/' + id + '/active'
    })
  }
  
  static saveWavelength(data: any) {
    return axios({
      method: 'post',
      url: '/api/wavelength',
      data
    });
  }

  static retireWavelength(id: number) {
    return axios({
      method: 'put',
      url: '/api/wavelength/retire',
      data : [{id}]
    });
  }

  static allColors() {
    return axios({
      method: 'get',
      url: '/api/color'
    });
  }

  static color(id: number) {
    return axios({
      method: 'get',
      url: '/api/manufacturer/' + id + '/color'
    });
  }

  static saveColor(data: any) {
    return axios({
      method: 'post',
      url: '/api/color',
      data
    });
  }

  static cloneColor(id: number, data: any) {
    return axios({
      method: 'post',
      url: '/api/color/' + id + '/clone',
      data
    });
  }

  static retireColor(id: number) {
    return axios({
      method: 'put',
      url: '/api/color/retire',
      data : [{id}]
    });
  }

  static deleteColor(id: number) {
    return axios({
      method: 'delete',
      url: '/api/color/' + id
    });
  }

  static usedin(id: number) {
    return axios({
      method: 'get',
      url: '/api/color/' + id + '/products'
    });
  }

  static export(id: any, navFilter: any) {
    let q = '';
    if (id) {
      q = '/' + id;
    } else if (navFilter) {
      q = "?search=name:'" + navFilter + "'";
    }
    return axios({
      method: 'get',
      url: '/api/manufacturer/export' + q,
      responseType: 'arraybuffer'
    });
  }

}
