import axios from "axios";
import { DateUtils } from "../utils/DateUtils";

export class QueryService {


  static query(product: any, from: string, to: string, bincode: string) {
    let q = '';
    q = q + (product ? (q ? '&productId=' : '?productId=') + product : '');
    q = q + (from ? (q ? '&fromDate=' : '?fromDate=') + from : '');
    q = q + (to ? (q ? '&toDate=' : '?toDate=') + to : '');
    q = q + (bincode ? (q ? '&binCode=' : '?binCode=') + bincode : '');
    
    return axios({
      method: 'get',
      url: '/api/query' + q
    });
  }

  static export(product: any, from: any, to: any, itemId: any, bincode: any) {
    let q = '';
    q = q + (itemId ? '/' + itemId : '');
    q = q  +'?timezone=' + DateUtils.getTZ();
    q = q + (product ? (q ? '&productId=' : '?productId=') + product : '');
    q = q + (from ? (q ? '&fromDate=' : '?fromDate=') + from : '');
    q = q + (to ? (q ? '&toDate=' : '?toDate=') + to : '');
    q = q + (bincode ? (q ? '&binCode=' : '?binCode=') + bincode : '');
    
    return axios({
      method: 'get',
      url: '/api/query/export' + q,
      responseType: 'arraybuffer'
    });
  }
  
  static queryItem(id: any) {    
    return axios({
      method: 'get',
      url: '/api/query/' + id
    });
  }


}
