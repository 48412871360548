
export class UrlUtils {

  static getQeuryParam(param: string) {
    let code = null;
    const codeIndexStart = window.location.search.indexOf(param);
    if (!isNaN(codeIndexStart) && codeIndexStart > -1) {
      code = window.location.search.substring(codeIndexStart + param.length + 1);

      // check if other params exist
      const andIndexStart = code.indexOf('&');
      if (!isNaN(andIndexStart) && andIndexStart > -1) {
        code = code.substring(0, andIndexStart);
      }
    }
    return code;
  }


}
