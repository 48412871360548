import { useEffect, useRef, useState, useCallback } from 'react';
import { ProductsService } from '../../../services/ProductsService';
import { BiningService } from '../../../services/BiningService';
import { Logger } from '../../../utils/Logger';
import ChromacityGraph from '../../chromaticity/chromacity-graph/ChromacityGraph';
import Modal, { ModalInterface } from '../../ui-elements/modal/Modal';
import './BiningResult.scss';
import Loader from '../../ui-elements/loader/Loader';

function BiningResult(props: any) {

  Logger.debug('BiningResult component render.');

  const [flux, setFlux] = useState<any>({});
  const [wavelength, setWavelength] = useState<any>({});
  const [chromaticity, setChromaticity] = useState<any>({});
  const [voltage, setvoltage] = useState<any>({});
  const [channel, setChannel] = useState<any>({});
  const [sum, setSum] = useState<any>({});
  const [data, setData] = useState<any>({});
  const [graphData, setGraphData] = useState<any>({});
  const modalGraphRef = useRef<ModalInterface>();
  const modalEditBinCodeRef = useRef<ModalInterface>();
  const [loading, setLoading] = useState<any>(false);
  const [binCodeEdit, setbinCodeEdit] = useState<any>(0);
  const [binCodeOld, setbinCodeOld] = useState<any>(0);
  const [binCodeEditErr, setbinCodeEditErr] = useState<any>(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500)
    
    const graphDataItems: any = {};

    if(props.result.channelRecipes) {
      const d:any = {};
      for (const i of props.result.channelRecipes) {
  
        if (!d[i.channelId]) {
          d[i.channelId] = {};
        }
        if (!graphDataItems[i.channelId]) {
          graphDataItems[i.channelId] = [];
        }
  
        d[i.channelId][i.binCode] = {
          id: i.id,
          createdBy: i.createdBy,
          createDate: i.createDate,
          recipeEmitters: i.recipeEmitters
        };
  
        for (const a of i.additionalInfo) {
          d[i.channelId][i.binCode][a.name] = a.value; 
        }

        if (d[i.channelId][i.binCode].recipeCx) {
          graphDataItems[i.channelId].push({
            x: +(d[i.channelId][i.binCode].recipeCx).toFixed(4),
            y: +(d[i.channelId][i.binCode].recipeCy).toFixed(4)
          });
        }
  
      }

      setGraphData(graphDataItems);
      setData(d);
    }
    

    const s:any = {};
    if (props.result.outputInventories) {
      for (const e of props.result.outputInventories) {
        if (props.channel?.id !== e.channelId) {
          continue;
        }
        const tag = '' + e.flux?.id + '.' + e.waveLength?.id + '.' +e.chromaticity?.id + '.' + e?.forwardVoltage?.id;
        if (!s[tag]) {
          s[tag] = {
            channel: e.channelId,
            flux: e.flux?.id,
            wavelength: e.waveLength?.id,
            chromaticity: e.chromaticity?.id,
            forwardVoltage: e?.forwardVoltage?.id  ? e.forwardVoltage.id : null,
            used: 0,
            total: 0
          }
        }
        s[tag].used= e.inventoryCount;
      }
    }
    
    if (props.result.inputInventories) {
      for (const e of props.result.inputInventories) {
        if (props.channel?.id !== e.channelId) {
          continue;
        }
        const tag = '' + e.flux?.id + '.' + e.waveLength?.id + '.' +e.chromaticity?.id + '.' + e?.forwardVoltage?.id;
        if (!s[tag]) {
          s[tag] = {
            channel: e.channelId,
            flux: e.flux?.id,
            wavelength: e.waveLength?.id,
            chromaticity: e.chromaticity?.id,
            forwardVoltage: e?.forwardVoltage?.id  ? e.forwardVoltage.id : null,
            used: 0,
            total: 0
          }
        }
        s[tag].total= e.inventoryCount;
      }
    }
    setSum(s);

    setChannel({});
    if (props.flux) {
      const obj: any = {};
      for (const i of props.flux) {
        obj[i.id] = i.name;
      }
      setFlux(obj);
    }
    if (props.waveLength) {
      const obj: any = {};
      for (const i of props.waveLength) {
        obj[i.id] = i.name;
      }
      setWavelength(obj);
    }
    if (props.voltage) {
      const obj: any = {};
      for (const i of props?.voltage) {
        if (i) {
          obj[i?.id] = i.name;
        }
      }
      setvoltage(obj);
    }
    if (props.chromaticity) {
      const obj: any = {};
      for (const i of props.chromaticity) {
        obj[i.id] = i.name;
      }
      setChromaticity(obj);
      ProductsService.getChannel(props.channel?.id).then((resp: any) => {
        setChannel(resp.data);
      });
    }
    
  }, [props]);

  const showGraph = useCallback(() => {
    modalGraphRef?.current?.open(true);
  }, [modalGraphRef]);

  const changeBinCode = useCallback(() => {
    setbinCodeEditErr(false);
    //console.log(binCodeEdit, props?.channel, data, props.result,222)
    //return
    BiningService.updateBinCode(
      props?.result?.productId,
      props?.result?.pcaNumber?.id,
      props?.result?.id,
      binCodeOld,
      binCodeEdit
    ).then((resp: any) => {
      if(props?.reload) {
        props?.reload();
      }
      modalEditBinCodeRef?.current?.open(false);
    }, (e) => {
      setbinCodeEditErr(true);
      console.log(e)
    });
  }, [binCodeEdit, binCodeOld, data, props]);

  return (
    <div className='app-binning-results app-width-100 p20'>
      {!data[props?.channel?.id] && <p>No recipes found</p>}

      {flux && data[props?.channel?.id] && <div className=''>
        <table className=''>
          <tbody>
          {
            Object.keys(data[props?.channel?.id]).length > 0 && 
              <tr>
                <td>Bin Code</td>
                {data[props?.channel?.id][Object.keys(data[props?.channel?.id])[0]].recipeEmitters.length > 0 && 
                  data[props?.channel?.id][Object.keys(data[props?.channel?.id])[0]].recipeEmitters.map((item: any, idx1: number) => {
                    return(
                        <td key={idx1 + 'header'}>
                          {flux && flux[item.flux.id] &&  <b>Bin {idx1}</b>}
                        </td>
                      )
                  })}
                  {data[props?.channel?.id][Object.keys(data[props?.channel?.id])[0]].recipeFlux && <td>Recipe Flux</td>}
                  {data[props?.channel?.id][Object.keys(data[props?.channel?.id])[0]].recipeWavelength && <td>Recipe Wavelength</td>}
                  {data[props?.channel?.id][Object.keys(data[props?.channel?.id])[0]].recipeCx > 0 && <td>Recipe Chromaticity</td>}
              </tr>
          }
          {Object.keys(data[props?.channel?.id]).length > 0 && Object.keys(data[props?.channel?.id]).map((binCode: any, idx: number) => {
              return(
                  <tr key={idx}>
                    <td>{binCode} 
                    {props?.allowBinUpdate && <button className="app-btn-neutral p5 ml15" onClick={() => {
                      setbinCodeEdit(binCode); 
                      setbinCodeOld(binCode); 
                      setbinCodeEditErr(false);
                      modalEditBinCodeRef?.current?.open(true);
                    }}>
                      <img src='/assets/img/icons/edit-black.svg' alt='' />
                    </button>}
                    </td>
                    {data[props?.channel?.id][binCode].recipeEmitters.length > 0 && 
                      data[props?.channel?.id][binCode].recipeEmitters.map((item: any, idx1: number) => {
                          return(
                            <td key={idx1}>
                              {flux && flux[item.flux.id] &&  <b>{flux[item.flux.id]}</b>}
                              {wavelength && wavelength[item.waveLength?.id] && <b> {wavelength[item.waveLength.id]}</b>}
                              {chromaticity && chromaticity[item.chromaticity?.id] && <b> { chromaticity[item.chromaticity.id]}</b>}
                              {voltage && voltage[item?.forwardVoltage?.id] && <b> { voltage[item?.forwardVoltage?.id]}</b>}
                            </td>)
                        })}
                        {data[props?.channel?.id][binCode].recipeFlux && <td>{data[props?.channel?.id][binCode].recipeFlux}</td>}
                        {data[props?.channel?.id][binCode].recipeWavelength && <td>{data[props?.channel?.id][binCode].recipeWavelength}</td>}
                        {data[props?.channel?.id][binCode].recipeCx > 0 && <td>
                          X: {data[props?.channel?.id][binCode].recipeCx} Y: {data[props?.channel?.id][binCode].recipeCy}</td>}
                  </tr>)
          })}
           </tbody>
        </table>

        <div className='mt10'>
          {graphData[props?.channel?.id]?.length > 0 &&
          <button className='app-btn-neutral p5 mr10'
              onClick={ () => { showGraph() }}>
                Show results on graph
              <img alt='' className='app-cursor-pointer ml10' src='/assets/img/icons/line-chart-black.svg' />
          </button>}
        </div>

        <h4 className='mt15 '>Inventory Summary:</h4>
        {Object.keys(sum).length > 0 && 
        <table className=''>
          <tr>
            <td>Bins</td>
            {Object.keys(sum).map((k: any, idx: number) => {
              return(
                <td key={idx}>
                  {flux && flux[sum[k].flux] && <b>{flux[sum[k].flux]}</b>}
                  {wavelength && sum[k].wavelength && wavelength[sum[k].wavelength] && <b> {wavelength[sum[k].wavelength]}</b>}
                  {chromaticity && sum[k].chromaticity && chromaticity[sum[k].chromaticity] && <b> {chromaticity[sum[k].chromaticity]}</b>}
                  {voltage && voltage[sum[k]?.forwardVoltage] && <b> {voltage[sum[k]?.forwardVoltage]}</b>}
                </td>)
            })}
          </tr>
          <tr>
            <td>Starting</td>
            {Object.keys(sum).map((k: any, idx: number) => {
              return(
                <td key={idx}>
                  {sum[k].total}
                </td>)
            })}
          </tr>
          <tr>
            <td>Used</td>
            {Object.keys(sum).map((k: any, idx: number) => {
              return(
                <td key={idx}>
                  {sum[k].used}
                </td>)
            })}
          </tr>
          <tr>
            <td>Remained</td>
            {Object.keys(sum).map((k: any, idx: number) => {
              return(
                <td key={idx}>
                  {sum[k].total - sum[k].used}
                </td>)
            })}
          </tr>
          <tr>
            <td>#LEDs Used</td>
            {Object.keys(sum).map((k: any, idx: number) => {
              return(
                <td key={idx}>
                  {sum[k].used * props?.spoolSize}
                </td>)
            })}
          </tr>
        </table>}
      </div>}

      <Modal ref={modalGraphRef}>
        {channel?.id && graphData[props?.channel?.id]?.length > 0 && <div className='m20'>
            <ChromacityGraph 
              chromaticity={props?.chromaticity} 
              hideControls="true"
              channel={channel} 
              binningTarget={graphData[props?.channel?.id]}
              product={props?.product} />
          </div>}
      </Modal>

    <Modal ref={modalEditBinCodeRef}>
      <h4 className='m20'><b>Edit Bin Code</b></h4>

      <div className='ml20 mr20'>
        <input type='number' className={`app-input`} value={binCodeEdit} onChange={(event: any) => {setbinCodeEdit(event.target.value)}}/>
        </div>

      <div className='m20'>
        <button className='app-btn-secondary app-width-100' 
          onClick={() => {changeBinCode()}}>
            Change
        </button>
      </div>

      {binCodeEditErr && <div className='m20 app-text-darkred'>
        * Bin Code cannot be saved!
      </div>}
    </Modal>

      
      {loading && <Loader />}
      
      
    </div>
  );
}
export default BiningResult;

