
import React from 'react';

// Manufacturers context
const ManufacturersCtx = React.createContext({
  manufacturers: [],
  setManufacturers: (s: any) => {},
  colors: [],
  setColors: (s: any) => {},
});
export default ManufacturersCtx;