import { useCallback, useEffect, useRef, useState } from 'react';
import { APP_COLOR_TYPES } from '../../../core/AppConstants';
import { ManufacturersService } from '../../../services/ManufacturersService';
import { Logger } from '../../../utils/Logger';
import NavMap from '../../ui-elements/nav-map/NavMap';
import AddColor from '../color/add-color/AddColor';
import DelColor from '../color/del-color/DelColor';
import './Color.scss';
import Flux from './flux/Flux';
import ForwardVoltage from './forwardVoltage/ForwardVoltage';
import Wavelength from './wavelength/Wavelength';
import Chromaticity from './chromaticity/Chromaticity';
import React from 'react';
import AppCtx from '../../../AppCtx';
import ManufacturersCtx from '../../../pages/manufacturers/ManufacturersCtx';
import CloneColor from './clone-color/CloneColor';
import Modal, { ModalInterface } from '../../ui-elements/modal/Modal';

function Color(props: any) {

  Logger.debug('Color component render.');

  const modalUsedInRef = useRef<ModalInterface>();
  const [colors, setColors] = useState<any>([]);
  const [usedIn, setUsedIn] = useState<any>([]);
  const [selectedColor, setSelectedColor] = useState<any>(null);
  const appCtxRef = React.useContext(AppCtx);
  const manufacturersCtxRef = React.useContext((ManufacturersCtx));
  const modalNavMapRef = useRef<any>();

  const selectColor = useCallback((c: any) => {
    setSelectedColor(c);
    modalNavMapRef?.current?.select(c);
  }, []);

  const updateColors = useCallback((c: any) => {
    setColors(c);
    modalNavMapRef?.current?.load(c);
  }, []);

  const init = useCallback(() => {
    /** check cache */
    if (manufacturersCtxRef.colors[props?.manufacturer?.id]) {
      const c:any = manufacturersCtxRef.colors[props?.manufacturer?.id];
      updateColors(c);
      if (!selectedColor || !c.some((led:any) => led.id === selectedColor.id)) {
        selectColor(c.length ? c[0]  : null);
      }
      return;
    }

    updateColors([]);
    selectColor(null);
    ManufacturersService.color(props.manufacturer.id).then((resp: any) => {
      updateColors(resp.data);
      const tmp = JSON.parse(JSON.stringify(manufacturersCtxRef.colors));
      tmp[props?.manufacturer?.id] = resp.data;
      manufacturersCtxRef.setColors(tmp);
    });
  }, [props.manufacturer, manufacturersCtxRef, selectColor, updateColors]);
  
  useEffect(() => {
    init();
  }, [props.manufacturer, init, manufacturersCtxRef.colors]);

  const onSelect = useCallback((event: any) => {
    setSelectedColor(event);
  }, []);
  
  //] = useState<boolean>(appCtxRef?.session?.features[APP_FEATURES.GeneralManufacturerUpdate]);

  const loadById = useCallback((id: number) => {

    id = id > 0 ? id : props.manufacturer.id;

    ManufacturersService.color(id).then((resp: any) => {
      const tmp = JSON.parse(JSON.stringify(manufacturersCtxRef.colors));
      tmp[id] = resp.data;
      manufacturersCtxRef.setColors(tmp);

      if (+props.manufacturer.id === +id) {
        updateColors(resp.data);

        if (selectedColor) {
          if (resp.data?.length === 0) {
            selectColor(null);
          }
          for (const it of resp.data) {
            if (selectedColor.id === it.id) {
              if (selectedColor.name !== it.name
                || selectedColor.orderCode !== it.orderCode
                || selectedColor.scmInternalId !== it.scmInternalId
                || selectedColor.colorType !== it.colorType) {
                  selectColor(it);
              }
              break;
            }
          }
        }
      }
    });
  }, [props.manufacturer, selectedColor, manufacturersCtxRef, selectColor, updateColors]);

  const load = useCallback(() => {
    loadById(0);
  }, [loadById]);

  const onAdd = useCallback((selected: any) => {
    if (selected?.length > 0) {
      const tmp = JSON.parse(JSON.stringify(manufacturersCtxRef.colors));
      const c = JSON.parse(JSON.stringify(colors));
      c.push(selected[0]);
      tmp[props?.manufacturer?.id] = c;
      manufacturersCtxRef.setColors(tmp);
      updateColors(c);
      selectColor(selected[0]);
    }
  }, [colors, selectColor, updateColors]);

  const usedin = useCallback(() => {
    ManufacturersService.usedin(selectedColor?.id).then((resp: any) => {
      setUsedIn(resp.data);
      modalUsedInRef.current?.open(true);
    });
  }, [selectedColor]);

  return (
    <div className='app-color'>

      <div className='app-color-list'>
        <div className='app-color-list-wrapper'>
          <NavMap 
            ref={modalNavMapRef}
            title='Colors'
            selectProp='id' 
            presentProp='name' 
            filter={false} 
            updateOnlyOnActions={true}
            selectFirst={true}
            onSelect={onSelect}
          />
        </div>
        
        <div className='app-color-list-buttons'>
          <AddColor onSave={onAdd} manufacturerId={props.manufacturer?.id}></AddColor>
        </div>
      </div>

      
      <div className='app-color-data'>

        {selectedColor && 
          <div className='app-color-data-header'>
            <div className='app-color-data-header-title'>
              <span className='app-color-data-header-title-text mb10'>Color:
                <span className='app-color-data-header-title-text-active'> {selectedColor?.name}</span> 
              </span>

              <button className='app-btn-neutral app-color-used-in' onClick={() => {usedin()}}>Used In</button>
              <span className='ml10 mt10'></span>
              <CloneColor onSave={loadById} manufacturerId={props.manufacturer?.id} color={selectedColor}></CloneColor>
              <span className='ml10 mt10'></span>
              { (appCtxRef?.session?.details?.identifier === selectedColor?.createdBy || appCtxRef?.session?.details?.userProtected) && 
              <AddColor onSave={load} manufacturerId={props.manufacturer?.id} edit={selectedColor}></AddColor>}
              { (appCtxRef?.session?.details?.identifier === selectedColor?.createdBy || appCtxRef?.session?.details?.userProtected) && 
              <span className='ml10 mt10'></span>}
              { (appCtxRef?.session?.details?.identifier === selectedColor?.createdBy || appCtxRef?.session?.details?.userProtected) && 
              <DelColor delId={selectedColor?.id} onSave={load}></DelColor>}
            </div>
            <p className='app-color-data-header-subtitle'>12NC: <b>{selectedColor?.scmInternalId}</b> <span className='ml10'></span>Order Code: <b>{selectedColor?.orderCode}</b></p>
          </div>
        }

        <div className='app-color-data-content'>
          {selectedColor?.colorType === APP_COLOR_TYPES.WAVELENGTH && 
            <Wavelength color={selectedColor}></Wavelength>
          }
          {selectedColor?.colorType === APP_COLOR_TYPES.CHROMATICITY && 
            <Chromaticity color={selectedColor} manufacturer={props.manufacturer}></Chromaticity>
          }

          {!selectedColor &&        
            <p className='app-color-warn-no-color'>No Available Colors</p>  
          }
        </div>
        
      </div>

      {selectedColor && 
        <div className='app-color-forwardvoltage'>
          <ForwardVoltage color={selectedColor}></ForwardVoltage>
        </div>
      }

      {selectedColor && 
        <div className='app-color-flux'>
          <Flux color={selectedColor}></Flux>
        </div>
      }
     
     <Modal ref={modalUsedInRef}>
       <h5 className='p15 m0'><b>{selectedColor?.name}</b> color is used in</h5> 
        <div className='app-color-usedin pl15 pr15 pb15'>
          {usedIn?.length > 0 && usedIn.map(function(row: any, rowIdx: number) {
            return(
            <div key={rowIdx}>
              - {row?.name} 
            </div>);
          })}
        </div>
      </Modal>
    </div>
  );
}
export default Color;

