
import React from 'react';

export enum UnsavedActionEnum {
  EXIST = 0,
  CHECK = 1,
  CANCEL = 2,
  DISCARD = 3,
  SAVE = 4,
}

// UI global control
interface UIAppContextInterface {
  loading: boolean;
  sidebar: boolean;
  unsavedActionOn: any;
  toast: any;
}
export const UIAppContextInitValue: UIAppContextInterface = {
  loading: true,
  sidebar: false,
  unsavedActionOn: null,
  toast: []
}


// Session 
interface SessionAppContextInterface {
  status: boolean;
  details: any;
  features: any;
  auth: any;
}
export const SessionAppContextInitValue: SessionAppContextInterface = {
  status: false,
  details: {},
  features: {},
  auth: {}
}


// Core 
interface CoreAppContextInterface {
  init: boolean;
  products: any;
  manufacturers: any;
}
export const CoreAppContextInitValue: CoreAppContextInterface = {
  init: false,
  products: null,
  manufacturers: null
}


// App main context
const AppCtx = React.createContext({
  ui: UIAppContextInitValue,
  setUi: (u: UIAppContextInterface) => {},
  session: SessionAppContextInitValue,
  setSession: (s: SessionAppContextInterface) => {},
  core: CoreAppContextInitValue,
  setCore: (s: CoreAppContextInterface) => {},
});
export default AppCtx;