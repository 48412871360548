export const BEARER = 'Bearer';
export const APP_SESSION_LOCALSTORAGE = 'OPTIBIN_SESSION_LOCALSTORAGE';
export const USER_INVITE_STATUS_CODE = 0;
export const APP_FEATURES_DESC: any = {
    'Accounts.User': 'Access to user list',
    'Accounts.User.Update': 'Add/edit/delete users',
    'Accounts.User.Profile': 'Edit personal information only',
    'Accounts.Role': 'Access to role list (Permissions)',
    'Accounts.Role.Update': 'Add/edit/delete Permissions',
    'Accounts.Client': 'Access to client list',
    'Accounts.Client.Update': 'Add/edit/delete Client groups',
    'General.Manufacturer': 'Access to Manufacturer list',
    'General.Manufacturer.Update': 'Add/edit/delete Manufacturers',
    'General.Manufacturer.Export': 'Export Manufacturer data for debug purposes',
    'General.LedSpecification': 'Access to LED specifications',
    'General.LedSpecification.Update': 'Add/edit/delete LED specifications',
    'General.Product': 'Access to Product list',
    'General.Product.Update': 'Add/edit/delete products',
    'General.Product.Export': 'Export Product data for debug purposes',
    'Algorithm.Binning.Info': 'View binning information',
    'Algorithm.Binning.Execute': 'Perform binning',
    'Algorithm.Binning.Query': 'View Query information of past binning',
    'Algorithm.Binning.Delete': 'Allowing a user to delete previously created bin runs by the same user',
    'Algorithm.Binning.Delete.Others': 'Allowing a user to delete previously created bin runs by other users'
};

export const APP_FEATURES: any = {
    AccountsUser: 'Accounts.User',
    AccountsUserUpdate: 'Accounts.User.Update',
    AccountsUserProfile: 'Accounts.User.Profile',
    AccountsRole: 'Accounts.Role',
    AccountsRoleUpdate: 'Accounts.Role.Update',
    AccountsClient: 'Accounts.Client',
    AccountsClientUpdate: 'Accounts.Client.Update',
    GeneralManufacturer: 'General.Manufacturer',
    GeneralManufacturerUpdate: 'General.Manufacturer.Update',
    GeneralManufacturerExport: 'General.Manufacturer.Export',
    GeneralLedSpecification: 'General.LedSpecification',
    GeneralLedSpecificationUpdate: 'General.LedSpecification.Update',
    GeneralProduct: 'General.Product',
    GeneralProductUpdate: 'General.Product.Update',
    GeneralProductExport: 'General.Product.Export',
    AlgorithmBinningInfo: 'Algorithm.Binning.Info',
    AlgorithmBinningExecute: 'Algorithm.Binning.Execute',
    AlgorithmBinningQuery: 'Algorithm.Binning.Query',
    AlgorithmBinningDelete: 'Algorithm.Binning.Delete',
    AlgorithmBinningDeleteOthers: 'Algorithm.Binning.Delete.Others'
};

export const APP_COLOR_TYPES: any = {
    CHROMATICITY: 'CHROMATICITY',
    WAVELENGTH: 'WAVELENGTH'
};

export const APP_PRODUCT_TYPES: any = {
    LINEAR: 'Linear',
    CLUSTER: 'Cluster'
};