
import React from 'react';

// Products context
const ProductsCtx = React.createContext({
  products: [],
  setProducts: (s: any) => {},
  productsData: {},
  setProductsData: (s: any) => {},
});
export default ProductsCtx;