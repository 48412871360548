import { APP_FEATURES } from "./AppConstants";

export const PROD = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

export const AUTH_URL = PROD ? 'https://optibin.d.acts.gso.cloud' : 'https://optibin.d.acts.gso.cloud';

export const PAGE_NAVIGATION = [
    {
      name: 'Home',
      img: '/assets/img/icons/home-black.svg',
      imgActive: '/assets/img/icons/home-red.svg',
      path: '/home',
      mobile: false,
      children: [APP_FEATURES.GeneralManufacturer]
    },
    {
      name: 'Products',
      img: '/assets/img/icons/clipboard-black.svg',
      imgActive: '/assets/img/icons/clipboard-red.svg',
      path: '/products',
      mobile: false,
      children: [],
      role: APP_FEATURES.GeneralProduct
    },
    {
      name: 'Bining',
      img: '/assets/img/icons/play-circle-black.svg',
      imgActive: '/assets/img/icons/play-circle-red.svg',
      path: '/bining',
      mobile: false,
      children: [],
      role: APP_FEATURES.AlgorithmBinningExecute
    },
    {
      name: 'Query',
      img: '/assets/img/icons/search-black.svg',
      imgActive: '/assets/img/icons/search-red.svg',
      path: '/query',
      mobile: false,
      children: [],
      role: APP_FEATURES.AlgorithmBinningQuery
    },
    {
      name: 'Accounts',
      img: '/assets/img/icons/users-black.svg',
      imgActive: '/assets/img/icons/users-red.svg',
      path: '/accounts',
      mobile: false,
      children: [
        {
          name: 'Users',
          img: '/assets/img/icons/users-black.svg',
          imgActive: '/assets/img/icons/users-red.svg',
          path: '',
          mobile: true,
          children: []
        },
        {
          name: 'Groups',
          img: '/assets/img/icons/users-black.svg',
          imgActive: '/assets/img/icons/users-red.svg',
          path: '/groups',
          mobile: true,
          children: []
        }
      ]
    },
    {
      name: 'Profile',
      img: '/assets/img/icons/user-black.svg',
      imgActive: '/assets/img/icons/user-red.svg',
      path: '/profile',
      mobile: true,
      children: []
    }
  ]