import { useState, forwardRef, useImperativeHandle, memo } from 'react';
import { Logger } from '../../../utils/Logger';
import './Modal.scss';

export interface ModalInterface {
  open: any,
  block: any,
  err: any,
  isOpen: () => boolean
}

const Modal = forwardRef((props: any, ref: any) => {

  Logger.debug('Modal component render.');
  
  const [modal, setModal] = useState({
    open: false,
    block: false,
    err: null
  });
  
  useImperativeHandle(ref, () => ({

    open(status: boolean) {
      setModal({...modal, open: status, err: null});
    },
    block(status: boolean) {
      setModal({...modal, block: status});
    },
    err(err: any) {
      setModal({...modal, err});
    },
    isOpen() {
      return modal.open;
    }
  }));

  if (!modal.open) {
    return (null);
  }
  
  return (
    <div className="app-modal">
      <div className='app-modal-backdrop'></div>
      {modal.open && <div className='app-modal-content'>
          {props.children}

        {modal.err && <p className='app-text-darkred mr20 ml20 mb20 mt10'>{modal.err}</p>}
        
        <span className='app-modal-content-close' onClick={($event) => {setModal({...modal, open: false});$event.stopPropagation()}}>
          <img src='/assets/img/icons/x-black.svg' alt='' />
        </span>
      </div>}
    </div>
  );
});
export default memo(Modal);