
export class DateUtils {

  static format(date: any, format: any) {
    try {
      return new Date(date).toLocaleString();
    } catch {
      return '';
    }
  }

  static formatIso(date: any) {
    try {
      if (date) {
        return new Date(date).toISOString()
        .replace(/T/, ' - ') // Replace the 'T' separator with ' - '
        .replace(/\..+/, '');
      }
      return new Date().toISOString()
      .replace(/T/, ' - ') // Replace the 'T' separator with ' - '
      .replace(/\..+/, '');
    } catch {
      return '';
    }
  }

  static getTZ() {
    const userTimeZoneOffset = new Date().getTimezoneOffset();
    const hours = Math.abs(Math.floor(userTimeZoneOffset / 60));
    const minutes = Math.abs(userTimeZoneOffset % 60);
    return `${userTimeZoneOffset > 0 ? '-' : '%2B'}${String(hours).padStart(2, '0')}${String(minutes).padStart(2, '0')}`;

  }



}
