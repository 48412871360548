import { useState, useEffect, useCallback } from 'react';
import { Logger } from '../../../utils/Logger';
import './JsonObjectEdit.scss';

function JsonObjectEdit(props: any) {

  Logger.debug('JsonObjectEdit component render.');
  
  const [data, setData] = useState<any>(null);
  const [fail, setFail] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);


  const validate = useCallback((dt: any) => {
    if (!dt) {
      setValid(false);
    }
    for (const k of props.keys) {
      if ((!dt[k] && dt[k] !== 0 ) || (props?.validation && props?.validation[k] && !props?.validation[k](dt[k], dt))) {
        setValid(false);
        return;
      }
    }
    setValid(true);
  }, [props]);

  useEffect(() => {
    
    
    let init: any = {};
    if (!props.data) {

      if (data) {
        return;
      }

      for (const k of props.keys) {
        init[k] = '';
      }
    } else {
      init = JSON.parse(JSON.stringify(props.data));
    }

    setData(init);

    validate(init);
    
  }, [props.keys, props.data, validate]);

  useEffect(() => {
    setFail(props.fail);
  }, [props.fail]);


  const onEdit = (k: string, v: any) => {
    const t = JSON.parse(JSON.stringify(data));
    t[k] = v;
    setData(t);    
    validate(t);
  }

  const save = () => {
    if (props.onSave) {
      props.onSave(data);
    }
  }

  const saveAndAdd = () => {
    if (props.onSaveAndAdd) {
      props.onSaveAndAdd(data);

      let init: any = {};
      for (const k of props.keys) {
        init[k] = '';
      }
      setData(init);
    }   
  }

  return (
    <div className='app-json-object-edit'>
      {props.title && <p className='app-json-object-edit-title'>{props.title}</p>}

      <div className='app-json-object-edit-content'>

        {data && props.keys.map(function(k: any, idx: number) {
          return (
            <div key={idx} className='ml20 mr20 mt10'>
              <p>{props.header[idx]}</p>
              {!props?.options[k] && 
              <input className={(!data[k] && data[k] !== 0 ) || (props?.validation && props?.validation[k] && !props?.validation[k](data[k], data)) ? `app-input app-border-red` :  `app-input`} 
              value={data[k]} onChange={(event: any) => {onEdit(k, event.target.value)}}/>}

              {props?.options[k] && 
              <select className={(!data[k] && data[k] !== 0 ) || (props?.validation && props?.validation[k] && !props?.validation[k](data[k], data)) ? `app-input app-border-red` :  `app-input`} 
              value={data[k]} onChange={(event: any) => {onEdit(k, event.target.value)}}>
                <option value={''}>{props.header[idx]}</option>
              {
                props.options[k].map( (item: any, key: number) => 
                  <option key={key} value={item}>{item}</option> )
              }
              </select>
              }
            </div>
          );
        })}
        
        <button className='app-btn-secondary ml20 mr20 mt20' 
          disabled={!valid}
          onClick={() => {save();}}>
            Save{!valid}
        </button>
        
        {props.onSaveAndAdd && !props.data &&
        <button className='app-btn-secondary ml20 mr20 mt20' 
        disabled={!valid}
          onClick={() => {saveAndAdd();}}>
            Save & Add
        </button>
        }

        {fail && <p className='app-text-darkred ml20 mr20 mt10'>This data cannot be saved!</p>}

        {!valid && props?.validationDesc && <p className='app-text-darkred ml20 mr20 mt10'>{props?.validationDesc}</p>}
      </div>
    </div>
  );
}
export default JsonObjectEdit;

