import { useState, useEffect, useCallback } from 'react';
import { Logger } from '../../../../../utils/Logger';
import { UIUtils } from '../../../../../utils/UIUtils';
import './ChromacityForm.scss';

function ChromacityForm(props: any) {

  Logger.debug('ChromacityForm component render.');

  const [TYPES] = useState<any>({
    //LINE: 'line',
    RECT: 'rectangle',
    //ELLIPSE: 'ellipse'
  });

  const [name, setName] = useState<any>('');
  const [type, setType] = useState<any>(TYPES.RECT);
  const [vals, setVals] = useState<any>({});
  const [fail, setFail] = useState<boolean>(false);
  const [valid, setValid] = useState<any>({});
  const [ratio, setRatio] = useState<any>('');
  const [ratioOffset, setRatioOffset] = useState<any>('');

  const onTypeChange = useCallback((val: any) => {    
    setFail(false); 
    setType(val);
    const tmp: any = {};
    for (const k of ['x1', 'y1', 'x2', 'y2', 'x3', 'y3', 'x4', 'y4']) {
        tmp[k] = 0;
    }
    setVals(tmp);
    setValid({});
  }, []);

  const init = useCallback(() => {
    const tmp: any = {};
    
    if (props?.data?.values) {
      setName(props?.data?.name);
      setRatio(props?.data?.ratio);
      setRatioOffset(props?.data?.ratioOffset);
      for (const it of props?.data?.values) {
        if (it?.name === 'type') {
          setType(it?.value);
        } else {
          tmp[it.name] = it.value;
        }
      }
    }

    for (const k of ['x1', 'y1', 'x2', 'y2', 'x3', 'y3', 'x4', 'y4']) {
      tmp[k] = ! tmp[k] ? 0 :  tmp[k];
  }

    setVals(tmp);
    
  }, [props.data]);

  useEffect(() => {
    init();
  }, [props, init]);

  useEffect(() => {
    setFail(props.fail);
  }, [props.fail]);

  const save = useCallback((saveAndAdd) => {
    if (props.onSave) {
      let ret: any = {};
      if (props.data) {
        ret = JSON.parse(JSON.stringify(props.data));
      }
      ret.name = name;
      ret.ratio = ratio;
      ret.ratioOffset = ratioOffset;

      ret.values = [
        {
          name: 'type',
          value: type
        }
      ];
      for (const k of Object.keys(vals)) {
        ret.values.push(
          {
            name: k,
            value: +vals[k]
          }
        );
      }
      ret.saveAndAdd = saveAndAdd;
      props.onSave(ret);
      if (saveAndAdd){
        setName('');
      }  
    }
  }, [vals, name, type, props, ratio, ratioOffset]);

  useEffect(() => {
    const v : any = {};
    if (type === 'rectangle') {
    for (const k of Object.keys(vals)) {
        if (!UIUtils.validateNumber(vals[k], 0, 1, 4)) {
          v[k] = true;
        }
      }
    }   
    if (type === 'ellipse') {
      if (!UIUtils.validateNumber(vals['x1'], 0, 1, 4)) {
        v['x1'] = true;
      } else if (!UIUtils.validateNumber(vals['y1'], 0, 1, 4)) {
        v['y1'] = true;
      } else if (!UIUtils.validateNumber(vals['x2'], 0, 1, 4)) {
        v['x2'] = true;
      } else if (!UIUtils.validateNumber(vals['y2'], 0, 1, 4)) {
        v['y2'] = true;
      } else if (!UIUtils.validateNumber(vals['x3'], 0, 360, 0)) {
        v['x3'] = true;
      }
    }
    setValid(v);
  }, [vals, type]);

  const onEdit = useCallback((k: string, v: any) => {
    const t = JSON.parse(JSON.stringify(vals));
    t[k] = v;
    setVals(t);
  }, [vals]);  

  return (
    <div className='app-chromacity-form'>
      <p className='app-chromacity-form-title'>Chromaticity</p>

      <div className='app-chromacity-form-content'>

        <div className='app-chromacity-form-content-div'>
          <p>Name</p>
          <input placeholder='Name' className={!name ? `app-input app-border-red` :  `app-input`} value={name} onChange={(event: any) => {setName(event.target.value)}}/>
        </div>

        <div className='app-chromacity-form-content-div'>
          <p>Type</p>
          <select className='app-input' value={type}  onChange={(event: any) => {onTypeChange(event.target.value);}} >
            {Object.keys(TYPES).map(function(k: string, idx: number){
              return (<option key={idx} value={TYPES[k]}> {TYPES[k]} </option>)
            })}
          </select>
        </div>

        {type === 'rectangle' && Object.keys(vals).map(function(k: any, idx: number) {
          return (
            <div key={idx} className='app-chromacity-form-content-div50'>
              <p>{k}</p>
              <input 
                className={valid[k] ? `app-input app-border-red` :  `app-input`} 
                value={vals[k]} type='number' min="0" onChange={(event: any) => {onEdit(k, event.target.value)}}/>
            </div>
          );
        })}

        {type === 'ellipse' && 
          <div className='app-width-100'>  
            <div  className=' ml20 mt20 mr20'>Center</div>
            
            <div className='app-width-100'>  
              <div className='app-chromacity-form-content-div50 pt0'>
                <p>x</p>
                <input 
                  className={valid['x1'] ? `app-input app-border-red` :  `app-input`} 
                  value={vals['x1']} type='number' min="0" onChange={(event: any) => {onEdit('x1', event.target.value)}}/>
              </div>
              <div className='app-chromacity-form-content-div50 pt0'>
                <p>y</p>
                <input 
                  className={valid['y1'] ? `app-input app-border-red` :  `app-input`} 
                  value={vals['y1']} type='number' min="0" onChange={(event: any) => {onEdit('y1', event.target.value)}}/>
              </div>
            </div>
            
            <div  className=' ml20 mt20 mr20'>Radius</div>
            <div className='app-width-100'>  
              <div className='app-chromacity-form-content-div50 pt0'>
                <p>x</p>
                <input 
                  className={valid['x2'] ? `app-input app-border-red` :  `app-input`} 
                  value={vals['x2']} type='number' min="0" onChange={(event: any) => {onEdit('x2', event.target.value)}}/>
              </div>
              <div className='app-chromacity-form-content-div50 pt0'>
                <p>y</p>
                <input 
                  className={valid['y2'] ? `app-input app-border-red` :  `app-input`} 
                  value={vals['y2']} type='number' min="0" onChange={(event: any) => {onEdit('y2', event.target.value)}}/>
              </div>
            </div>

            <div  className=' ml20 mt20 mr20'>Angle</div>
            <div className='app-width-100'>  
              <div className='app-chromacity-form-content-div50 pt0'>
                <p>x</p>
                <input 
                  className={valid['x3'] ? `app-input app-border-red` :  `app-input`} 
                  value={vals['x3']} type='number' min="0" onChange={(event: any) => {onEdit('x3', event.target.value)}}/>
              </div>
            </div>
          </div>
        }
        
          <button className='app-btn-secondary mt20 ml20 mr20' disabled={Object.keys(valid).length !== 0 || !name}
            onClick={() => {save(false);}}>
              Save
          </button>
          {!props?.data && <button className='app-btn-secondary mt20 ml20 mr20' disabled={Object.keys(valid).length !== 0 || !name}
            onClick={() => {save(true);}}>
              Save & Add
          </button>}

        {Object.keys(valid).length !== 0 && <p className='app-text-darkred ml20 mr20 mt10'>Chromaticity must be a number between 0 and 1, max number of decimals is 4!</p>}

        {fail && <p className='app-text-darkred ml20 mr20 mt10'>This data cannot be saved!</p>}
      </div>
    </div>
  );
}
export default ChromacityForm;

