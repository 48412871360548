import React from 'react';
import AppCtx, { UnsavedActionEnum } from '../../../AppCtx';
import './UnsavedModal.scss';

function UnsavedModal() {
  const appCtxRef = React.useContext(AppCtx);
  return (
    <div className="app-modal">
      <div className='app-modal-backdrop'></div>
       <div className='app-modal-content p20'>
         <h3 className='app-text-center mb10'>Warning!</h3>
          <p>You have unsaved changes! If you continue, any data you have entered will be lost.</p>
          <div className='mt10'>
          <button className='app-btn-secondary  app-width-100 mb10' onClick={() => {appCtxRef.setUi({...appCtxRef.ui, unsavedActionOn: UnsavedActionEnum.SAVE});}}>
            Save & Continue
          </button>
          <button className='app-btn-neutral app-width-100 mb10' onClick={() => {appCtxRef.setUi({...appCtxRef.ui, unsavedActionOn: UnsavedActionEnum.DISCARD});}}>
            Continue Without Save
          </button>

          <span className='app-modal-content-close' onClick={($event) => {appCtxRef.setUi({...appCtxRef.ui, unsavedActionOn: UnsavedActionEnum.EXIST});}}>
          <img src='/assets/img/icons/x-black.svg' alt='' />
        </span>
          </div>
      </div>
    </div>
  );
}
export default UnsavedModal;

