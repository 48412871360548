import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { ProductsService } from '../../../services/ProductsService';
import { Logger } from '../../../utils/Logger';
import Modal, { ModalInterface } from '../../ui-elements/modal/Modal';
import ProductsCtx from '../../../pages/products/ProductsCtx';
import './MoveProduct.scss';
import NavMap from '../../ui-elements/nav-map/NavMap';

function MoveProduct(props: any) {

  Logger.debug('MoveProduct component render.');

  const modalRef = useRef<ModalInterface>();
  const productsCtxRef = useContext(ProductsCtx);
  const modalNavMapRef = useRef<any>();  
  const [name, setName] = useState('');
  const [fail, setFail] = useState(false);
  const [productSelected, setProductSelected] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const save = () => {
    const req: any = {
      id : props?.prod?.id,
      name : props?.prod?.name,
      description : props?.prod?.description,
      fixtureType: props?.prod?.fixtureType,
      product: props?.prod?.product,
      parent : { 
          id: +productSelected.id
        }
    };

    ProductsService.save([req]).then(() => {
      if (props.onSave) {
        props.onSave();
      }
      setName('');
      modalRef?.current?.open(false);
    }).catch(() => {
      setFail(true);
    });
  };

  const open = () => {
    setName(props?.prod?.name ? props?.prod?.name : '');
    setLoading(true);
    setFail(false);
    modalRef?.current?.open(true);
  };

  useEffect(() => {
    if (modalRef.current?.isOpen()) {
      if (productsCtxRef.products) {
        modalNavMapRef?.current?.load(productsCtxRef.products);
        setLoading(false);
      } else {
        ProductsService.all().then((resp: any) => {
          modalNavMapRef?.current?.load(resp.data);
          setLoading(false);
        });
      }
    }
  }, [modalRef.current?.isOpen(), productsCtxRef.products, props.prod, loading]);

  const onSelectProduct = useCallback((item: any) => {
    if (+item?.id !== +props?.prod?.id) {
      setProductSelected(item);
    } else {
      setProductSelected(null);
    } 
    setFail(false);
  }, [productSelected, props?.prod]);

  return (
    <>
      <button className='app-product-move-btn app-btn-neutral' onClick={() => { open(); }}>Move</button>
      <Modal ref={modalRef}>
        <div className='app-product-move'>
          <h2>Move Product</h2>

          <p>Name</p>
          <input placeholder='Name' className={`app-input`} value={name} disabled/>

          <p>Move to:</p>
          <div className='app-product-move-list'>
              
              {!loading && 
                <NavMap 
                  ref={modalNavMapRef}
                  title='Products'
                  selectProp='id' 
                  presentProp='name' 
                  childrenProp='products'
                  filter={true} 
                  loopAll={true}
                  onSelect={onSelectProduct}
                />
              }
          </div>

          {fail && <p className='app-text-darkred'>*This product could not be moved!</p>} 
          <button className='app-btn-secondary' disabled={productSelected?.product || !productSelected} onClick={() => { save(); }}>Save</button>
        </div>
      </Modal>
    </>
  );
}
export default MoveProduct;

