import Logo from '../logo/Logo';
import './Loader.scss';

function Loader() {
  return (
    <div className='app-loader'>
      <span className='app-loader-content'>
        <Logo />
        <p>Where consistensy begins.</p>
        <img src='/assets/img/spinner.gif' alt=''></img>
      </span>
    </div>
  );
}
export default Loader;

