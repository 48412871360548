import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import AppCtx, { UnsavedActionEnum } from '../../../AppCtx';
import { ManufacturersService } from '../../../services/ManufacturersService';
import { ProductsService } from '../../../services/ProductsService';
import { Logger } from '../../../utils/Logger';
import Modal, { ModalInterface } from '../../ui-elements/modal/Modal';
import './ProductChannels.scss';
import axios from 'axios';

function ProductChannels(props: any) {

  Logger.debug('ProductChannels component render.');

  const modalEditRef = useRef<ModalInterface>();
  const modalOrderRef = useRef<ModalInterface>();
  const [selected, setSelected] = useState<any>({number: ''});
  const [manufacturer, setManufacturer] = useState<any>('');
  const [color, setColor] = useState<any>('');
  const [colors, setColors] = useState<any>('');
  const appCtxRef = useContext(AppCtx);
  const [unsavedActionOn, setUnsavedActionOn] = useState<any>(null);
  const [channels, setChannels] = useState<any>([]);
  const [orderedChannels, setOrderedChannels] = useState<any>([]);

  const editTrigger = useCallback((item: any) => {
    setManufacturer('');
    setColor('');
    modalEditRef?.current?.open(true);
  }, []);

  const orderTrigger = useCallback((item: any) => {
    modalOrderRef?.current?.open(true);
  }, []);

  const editExe = useCallback(() => {
    ProductsService.saveChannel([{
      product: {
        id: +props.product.id
      },
      color: {
        id: +color
      }
    }]).then((resp: any) => {
      if (props.refresh) {
        props.refresh();
      }
      modalEditRef?.current?.open(false);
    }, () => {
      modalEditRef?.current?.err("This channel cannot be saved.");      
    });
  }, [color, props]);

  const select = useCallback((item) => {

    if (appCtxRef.ui.unsavedActionOn === UnsavedActionEnum.EXIST) {
      setUnsavedActionOn(item);
      appCtxRef.setUi({...appCtxRef.ui, unsavedActionOn: UnsavedActionEnum.CHECK});
      return;
    }

    setSelected(item);
    if (props.onSelect) {
      props.onSelect(item);
    }
  }, [props, appCtxRef]);

  const reorderChannel = (index : any, direction : any) => {
    const newChannels = [...orderedChannels];
    const currentIndex = index;
    const newIndex = index + direction;

    if (newIndex >= 0 && newIndex < newChannels.length) {
        const currentChannel = newChannels[currentIndex];
        const newChannel = newChannels[newIndex];

        const currentSortOrderProperty = currentChannel.properties?.find((property:any) => property.name === 'sortOrder');
        const newSortOrderProperty = newChannel.properties?.find((property:any) => property.name === 'sortOrder');

        if (currentSortOrderProperty && newSortOrderProperty) {
            const tempSortOrder = currentSortOrderProperty.value;
            currentSortOrderProperty.value = newSortOrderProperty.value;
            newSortOrderProperty.value = tempSortOrder;
        } else {
            if (!currentSortOrderProperty) {
              if (currentChannel.properties) {
                currentChannel.properties.push({ name: 'sortOrder', value: newIndex.toString() });
              } else {
                currentChannel.properties = [{ name: 'sortOrder', value: newIndex.toString() }];
              }
            }
            if (!newSortOrderProperty) {
              if (newChannel.properties) {
                newChannel.properties.push({ name: 'sortOrder', value: currentIndex.toString() });
              } else {
                newChannel.properties = [{ name: 'sortOrder', value: currentIndex.toString() }];
              }
            }
        }
        const sortedChannels = sort(newChannels);
        setOrderedChannels(sortedChannels);
    }
  };

  const editChannelOrder = () => {
    const requests = orderedChannels.map((channel: any) => {
        const req = JSON.parse(JSON.stringify(channel));
        return ProductsService.updateChannel([req]);
    });

    axios.all(requests)
        .then(axios.spread((...responses) => {
            if (props.refresh) {
                props.refresh();
            }
            modalOrderRef?.current?.open(false);
        }))
        .catch(() => {
            modalOrderRef?.current?.err("Reordered channels cannot be saved.");
        });
};

  const sort = (channels: any) => {
    const channelsWithSortOrder = channels.filter((channel: any) => {
      const sortOrderProperty = channel.properties?.find((property: any) => property?.name === 'sortOrder');
      return sortOrderProperty !== undefined && sortOrderProperty.value !== undefined;
    });

    const channelsWithoutSortOrder = channels.filter((channel: any) => {
      const sortOrderProperty = channel.properties?.find((property: any) => property?.name === 'sortOrder');
      return sortOrderProperty === undefined || sortOrderProperty.value === undefined;
    });
    
    const sortedChannels = channelsWithSortOrder.sort((a: any, b: any) => {
      const sortOrderAProperty = a.properties?.find((property: any) => property.name === 'sortOrder');
      const sortOrderBProperty = b.properties?.find((property: any) => property.name === 'sortOrder');

      const sortOrderA = sortOrderAProperty ? parseInt(sortOrderAProperty.value) : 0;
      const sortOrderB = sortOrderBProperty ? parseInt(sortOrderBProperty.value) : 0;

      return sortOrderA - sortOrderB;
    });

    channelsWithoutSortOrder.length && channelsWithoutSortOrder.forEach((channel : any, index : any) => {
      if (!channel.properties) {
          channel.properties = [];
      }
      channel.properties.push({ name: 'sortOrder', value: (index + sortedChannels.length).toString() });
    });

    const orderedChannels = [...sortedChannels, ...channelsWithoutSortOrder];
    const finalOrderedChannels = orderedChannels.map((c: any, i: any) => { 
      const idx = c.properties.findIndex((prop: any) => prop?.name === 'sortOrder');
      c.properties[idx].value = i.toString();
      return c;
    });

    return finalOrderedChannels;
  };
  
  useEffect(() => {
    if (unsavedActionOn && (appCtxRef.ui.unsavedActionOn === UnsavedActionEnum.SAVE || appCtxRef.ui.unsavedActionOn === UnsavedActionEnum.DISCARD)) {
      select(unsavedActionOn);
      setUnsavedActionOn(null);
    } else if (appCtxRef.ui.unsavedActionOn === UnsavedActionEnum.EXIST) {
      setUnsavedActionOn(null);
    }
  }, [appCtxRef.ui.unsavedActionOn, unsavedActionOn, select]);

  useEffect(() => {
    setColor('');
    if (!manufacturer) {
      setColors(null);
      return;
    }
    ManufacturersService.color(manufacturer).then((resp: any) => {
      setColors(resp.data)
    });
  }, [manufacturer]);

  useEffect(() => {
    /*if(props.product?.id !== product?.id) {
      setSelected('');
    }
    setProduct(props.product);*/
    if (props.product?.channels?.length > 0) {
      const requests = props.product.channels.map((channel:any) => ProductsService.getChannel(channel.id));
      axios.all(requests)
        .then(axios.spread((...responses) => {
          const channelDetails = responses.map((response:any) => response.data);
          const sortedChannels = sort(channelDetails);
          setChannels(sortedChannels);
          setOrderedChannels(sortedChannels);
        }))
        .catch(() => {
          console.error("Error fetching channels");
        });
    } else {
      setChannels(props.product?.channels);
      setOrderedChannels(props.product?.channels);
    }
  }, [props.product]);

  useEffect(() => {
    if(!props.channel?.id) {
      setSelected('');
    } else {
      setOrderedChannels((orderedChannels : any) => {
        return orderedChannels.map((c: any) => {
            if (c.id === props.channel.id && c !== props.channel) {
                 const sortOrder = c.properties.find((prop: any) => prop?.name === 'sortOrder');
                 if (sortOrder) {
                     const newSortOrderIndex = props.channel.properties.findIndex((prop: any) => prop?.name === 'sortOrder');
                     if (newSortOrderIndex !== -1) {
                        props.channel.properties[newSortOrderIndex].value = sortOrder.value;
                     } else {
                         props.channel.properties.push({ name: 'sortOrder', value: sortOrder.value });
                     }
                 }
                 return props.channel;
             } else {
                 return c;
             }
        });
      });
    }
    /*if(!props.channel?.id !== channel?.id) {
      setSelected('');
    }
    alert()
    setChannel(props.product);*/
  }, [props.channel]);

  return (
    <>
      <p className='mt15'>Channels: </p>
      <div className='app-products-channels-block'>
        <div className='app-products-channels-block-items'>
          {channels.length > 0 && channels.map(function(row: any, rowIdx: number) {
            return(
            <span className={'app-products-channels-block-item ' + (row?.id === selected?.id ? 'active' : '')} key={rowIdx} onClick={() => {select(row)}}>
              {row?.color?.name} 
            </span>);
          })}
          {channels.length === 0 && <span className='app-text-gray ml10'>No items</span>}
        </div>
        {!props.ro && <div className='app-products-channels-block-add'>
          <img className='' src='/assets/img/icons/plus-black.svg' alt='' onClick={() => {editTrigger(null)}}/>
          <img className='' src='/assets/img/icons/edit-black.svg' alt='' onClick={() => {orderTrigger(null)}} />
        </div>}
      </div>   
      
      <Modal ref={modalEditRef}>
        <div className='app-products-channels-add'>
          <p className='app-products-channels-add-title'>Add Channel</p>

          <div className='app-json-object-edit-content'>

            <div className='ml20 mr20 mt10'>
              <p>Manufacturer</p>
              <select className='app-input' value={manufacturer} onChange={(event: any) => {setManufacturer(event.target.value);}} >
                <option value="">Manufacturer</option>
                {props.manufacturers.length > 0 && props.manufacturers.map(function(row: any, rowIdx: number) {
                  return(<option key={rowIdx} value={row.id}>{row.name}</option>);
                })}
              </select>
            </div>

            {colors && <div className='ml20 mr20 mt10'>
              <p>Color</p>
              <select className='app-input' value={color} onChange={(event: any) => {setColor(event.target.value);}} >
                <option value="">Color</option>
                {colors.length > 0 && colors.map(function(row: any, rowIdx: number) {
                  return(<option key={rowIdx} value={row.id}>{row.name +"(" +row.scmInternalId +"/" +row.orderCode +")"}</option>);
                })}
              </select>
            </div>}
          
            <div className='ml20 mr20 mt30'>
              <button className='app-btn-secondary' 
                disabled={!color}
                onClick={() => {editExe()}}>
                  Save
              </button>
            </div>

          </div>
        </div>
      </Modal>

      <Modal ref={modalOrderRef}>
        <div className="app-products-channels-order">
        <div className='app-products-channels-order-title'>Reorder channels</div>
        <div className="app-products-channels-order-list">
          {orderedChannels.map((channel: any, index : any) => (
            <div key={channel.color?.id} className="app-products-channels-order-list-item">
              <span>{channel.color?.name}</span>
              <div className="app-products-channels-order-list-controls">
                <button onClick={() => reorderChannel(index, -1)} disabled={index === 0}>
                  <img src='/assets/img/icons/chevron-up-gray.svg' alt=''/>
                </button>
                <button onClick={() => reorderChannel(index, 1)} disabled={index === orderedChannels.length - 1}>
                  <img src='/assets/img/icons/chevron-down-gray.svg' alt=''/>
                </button>
              </div>
            </div>
          ))}
        </div>
        <button className='app-btn-secondary' disabled={channels === orderedChannels} onClick={() => {editChannelOrder()}}>
          Save
        </button>
      </div>
      </Modal> 
    </>
  );
}
export default ProductChannels;
