
import React from 'react';
import { APP_PRODUCT_TYPES } from '../../../core/AppConstants';
import { ProductsService } from '../../../services/ProductsService';
import { Logger } from '../../../utils/Logger';
import './ChromacityGraphActiveBins.scss';
import AppCtx, { UnsavedActionEnum } from '../../../AppCtx';

class ChromacityGraphActiveBins extends React.Component<any, any> {

  static contextType = AppCtx;

  state: any = {
    channel: {},
    mounted: false,
    isLinear : false,

    saved: {},
    bins: {},
    dirty: false,
    unsavedActionOn : false,
    
    innersaved: {},
    innerbins: {},
    innerdirty: false,
    innerUnsavedActionOn : false,
    
    positions: [],
    positionssaved: {},
    positionsbins: {},
    positionsdirty: false,
    positionsUnsavedActionOn : false,
  };

  constructor (props: any) {
    Logger.debug('ChromacityGraphActiveBins component init.');
    super(props);   
  }

  componentDidMount() {
    Logger.debug('ChromacityGraphActiveBins component mount.');
    
  }

  componentDidUpdate() {
    Logger.debug('ChromacityGraphActiveBins component update.');
    
    if (this.props?.product) {
      this.state.isLinear = this.props.product.fixtureType === APP_PRODUCT_TYPES.LINEAR;
    }

    //TODO make this efficient
    if (this.state.channel.id !== this.props.channel?.id || JSON.stringify(this.state.channel.properties) !== JSON.stringify(this.props.channel.properties) ) {
      if (this.props?.channel?.chromaticityBinSubsets) {
        // collect active bins
        const activeBins: any = {};
        for (const it of this.props.channel.chromaticityBinSubsets) {
          if (it?.chromaticity.id) {
            activeBins[it?.chromaticity.id] = true;
          }
        }
        this.setState({bins: activeBins, saved: activeBins, dirty: false, channel: this.props.channel});
        this.props.set(activeBins);
      }
      if (this.props?.channel?.chromaticityInnerBins) {
        // collect active bins
        const innerBins: any = {};
        for (const it of this.props.channel.chromaticityInnerBins) {
          if (it?.chromaticity.id) {
            innerBins[it?.chromaticity.id] = true;
          }
        }
        this.setState({innerbins: innerBins, innersaved: innerBins, innerdirty: false, channel: this.props.channel});
        this.props.setinner(innerBins);
      }

      if (this.props?.channel?.properties) {
        
        const positionsRange = [];
        if (this.props?.channel?.properties) {
          for (const it of this.props?.channel?.properties) {
            if (it?.name === 'numberOfEmittersPerPixel') {
              for (let i = 1; i <= +it.value; i++) {
                positionsRange.push(i);
              }
            }
          }
        }
        this.setState({positions: positionsRange});

        if (this.props?.channel?.innerBinPositions) {
          const positionsBins: any = {};
          for (const it of this.props.channel.innerBinPositions) {
            if (it['positionIndex'] <= positionsRange.length)
              positionsBins[it['positionIndex']] = true;
          }
          this.setState({positionsbins: positionsBins, positionssaved: positionsBins, positionsdirty: false});
        }
      }
    }
    if(this.state.unsavedActionOn && this.context.ui.unsavedActionOn === UnsavedActionEnum.SAVE){
      this.save();
    }
    if(this.state.innerUnsavedActionOn && this.context.ui.unsavedActionOn === UnsavedActionEnum.SAVE){
      this.innersave();
    }
    if(this.state.positionsUnsavedActionOn && this.context.ui.unsavedActionOn === UnsavedActionEnum.SAVE){
      this.positionssave();
    }  
  }

  save() {
    const req: any = [];
    for (const it of Object.keys(this.state.bins)) {
      req.push({
        channelId: this.props.channel?.id,
        chromaticity: {
          id: it
        }
      });
    }
    ProductsService.saveChromaticityBinSubset(this.props.channel?.id, req).then((resp: any) => {
      this.setState({saved: this.state.bins, dirty: false, unsavedActionOn : false});
      this.props.unsavedActionUpdate(this.state.innerUnsavedActionOn || this.state.positionsUnsavedActionOn);
      if (!this.state.innerUnsavedActionOn && !this.state.positionsUnsavedActionOn && !this.props.unsavedAction){
        this.context.setUi({...this.context.ui, unsavedActionOn: null});
      } 
    });
  }

  innersave() {
    const req: any = [];
    for (const it of Object.keys(this.state.innerbins)) {
      if (this.state.bins[it]) {
        req.push({
          channelId: this.props.channel?.id,
          chromaticity: {
            id: it
          }
        });
      }
    }
    ProductsService.saveChromaticityInnerSubset(this.props.channel?.id, req).then((resp: any) => {
      this.props.unsavedActionUpdate(this.state.unsavedActionOn || this.state.positionsUnsavedActionOn);
      this.setState({innersaved: this.state.innerbins, innerdirty: false, innerUnsavedActionOn : false});
      if (!this.state.unsavedActionOn && !this.state.positionsUnsavedActionOn && !this.props.unsavedAction){
        this.context.setUi({...this.context.ui, unsavedActionOn: null});
      }
    });
  }

  positionssave() {

    const req: any = [];
    for (const it of Object.keys(this.state.positionsbins)) {
        req.push({
          channelId: this.props.channel?.id,
          positionIndex: it
        });
    }
    ProductsService.saveChromaticityInnerBinPosition(this.props.channel?.id, req).then((resp: any) => {
      this.props.unsavedActionUpdate(this.state.unsavedActionOn || this.state.innerUnsavedActionOn); 
      if (!this.state.unsavedActionOn && !this.state.innerUnsavedActionOn && !this.props.unsavedAction){
        this.context.setUi({...this.context.ui, unsavedActionOn: null});
      }
      this.setState({positionssaved: this.state.positionsbins, positionsdirty: false , positionsUnsavedActionOn : false});
    });
  }

  cancel() {
    this.setState({bins: this.state.saved});
    this.props.set(this.state.saved);
    this.validateDirty(this.state.saved);
  }

  innercancel() {
    this.setState({innerbins: this.state.innersaved});
    this.props.setinner(this.state.innersaved);
    this.innervalidateDirty(this.state.innersaved);
  }

  positionscancel() {
    this.setState({positionsbins: this.state.positionssaved});
    this.positionsvalidateDirty(this.state.positionssaved);
  }

  toggleAll() {
    const bins: any = {};
    if (this.props.chromaticity.length > Object.keys(this.state.bins).length) {
      this.props.chromaticity.map((item: any, idx: number) => {
        bins[item.id] = true;
        return null;
      });
    }
    this.setState({bins});
    this.props.set(bins);
    this.validateDirty(bins);
  }

  innertoggleAll() {
    const bins = Object.keys(this.state.innerbins).length >= Object.keys(this.state.bins).length ? {} :  this.state.bins;
    this.setState({innerbins: bins});
    this.props.setinner(bins);
    this.innervalidateDirty(bins);
  }

  positionstoggleAll() {
    const bins: any = {};
    if (Object.keys(this.state.positionsbins).length < this.state.positions.length) {
      this.state.positions.map((item: any, idx: number) => {
        bins[item] = true;
        return null;
      });
    }
    this.setState({positionsbins: bins});
    this.positionsvalidateDirty(bins);
  }

  toggle(id: any) {
    const copy = JSON.parse(JSON.stringify(this.state.bins));
    if (copy[id]) {
      delete copy[id];
    } else {
      copy[id] = true;
    }
    this.setState({bins: copy});
    this.props.set(copy);
    this.validateDirty(copy);
  }

  innertoggle(id: any) {
    const copy = JSON.parse(JSON.stringify(this.state.innerbins));
    if (copy[id]) {
      delete copy[id];
    } else {
      copy[id] = true;
    }
    this.setState({innerbins: copy});
    this.props.setinner(copy);
    this.innervalidateDirty(copy);
  }

  positionstoggle(id: any) {
    const copy = JSON.parse(JSON.stringify(this.state.positionsbins));
    if (copy[id]) {
      delete copy[id];
    } else {
      copy[id] = true;
    }
    this.setState({positionsbins: copy});
    this.positionsvalidateDirty(copy);
  }

  validateDirty(bins: any) {
    if (!(JSON.stringify(this.state.saved) === JSON.stringify(bins))) {
      this.setState({dirty : true, unsavedActionOn : true});
      this.props.unsavedActionUpdate(true);
      this.context.setUi({...this.context.ui, unsavedActionOn: UnsavedActionEnum.EXIST});
    } else {
      this.props.unsavedActionUpdate(this.state.innerUnsavedActionOn || this.state.positionsUnsavedActionOn);      
      this.setState({dirty : false, unsavedActionOn : false});
      if (!this.state.innerdirty && !this.state.positionsdirty && !this.props.unsavedAction){
        this.context.setUi({...this.context.ui, unsavedActionOn: null});
      }  
    }
  }

  innervalidateDirty(bins: any) {
    if (!(JSON.stringify(this.state.innersaved) === JSON.stringify(bins))) {
      this.setState({innerdirty : true, innerUnsavedActionOn : true});
      this.props.unsavedActionUpdate(true);
      this.context.setUi({...this.context.ui, unsavedActionOn: UnsavedActionEnum.EXIST});
    } else {
      this.props.unsavedActionUpdate(this.state.unsavedActionOn || this.state.positionsUnsavedActionOn);      
      this.setState({innerdirty : false, innerUnsavedActionOn : false});
      if (!this.state.dirty && !this.state.positionsdirty && !this.props.unsavedAction){
        this.context.setUi({...this.context.ui, unsavedActionOn: null});
      }
    }
  }

  positionsvalidateDirty(bins: any) {    
    if (!(JSON.stringify(this.state.positionssaved) === JSON.stringify(bins))) {
      this.setState({positionsdirty : true, positionsUnsavedActionOn : true});
      this.props.unsavedActionUpdate(true);
      this.context.setUi({...this.context.ui, unsavedActionOn: UnsavedActionEnum.EXIST});
    } else {
      this.props.unsavedActionUpdate(this.state.unsavedActionOn || this.state.innerUnsavedActionOn);      
      this.setState({positionsdirty : false, positionsUnsavedActionOn : false});
      if (!this.state.dirty && !this.state.innerdirty && !this.props.unsavedAction){
        this.context.setUi({...this.context.ui, unsavedActionOn: null});
      }
    }
  }

  render() {
    return (
      <div className='app-width-100'>
        <div className='mt15 pl0 app-chromaticity-graph-active-bins'>

          <div className='app-chromaticity-graph-active-bins-header'>
            <span className='mr5'><b>Active Chromaticity Bins</b></span>
            <br />
            {this.state.dirty && <button className='app-btn-secondary ml5 mt5 p5 pl10 pr10' onClick={() => {this.save()}}>
              Save
            </button>}
            <button className='app-btn-neutral ml5 mt5 p5 pl10 pr10' onClick={() => {this.toggleAll()}}>
              {this.props.chromaticity.length > Object.keys(this.state.bins).length ? 'Select All' : 'Deselect All'}
            </button>
            {this.state.dirty && <button className='app-btn-neutral ml5 mt5 p5 pl10 pr10' onClick={() => {this.cancel()}}>
              Cancel
            </button>}
          </div>

          <div className='app-chromaticity-graph-active-bins-list mt5'>
            {this.props.chromaticity.length && this.props.chromaticity.map((item: any, idx: number) => {
              return (
                <div key={idx} className={this.state.bins[item.id] ? 'active' : ''} onClick={() => {this.toggle(item.id)}}>
                  {item.name}
                </div>
              )})}
          </div>

        </div>


        {this.state.isLinear && <div className='mt15 pl0 app-chromaticity-graph-active-bins'>

          <div className='app-chromaticity-graph-active-bins-header'>
            <span className='mr5'><b>Inner Chromaticity Bins</b></span>
            <br />
            {this.state.innerdirty && <button className='app-btn-secondary ml5 mt5 p5 pl10 pr10' onClick={() => {this.innersave()}}>
              Save
            </button>}
            <button className='app-btn-neutral ml5 mt5 p5 pl10 pr10' onClick={() => {this.innertoggleAll()}}>
              {Object.keys(this.state.innerbins).length < Object.keys(this.state.bins).length ? 'Select All' : 'Deselect All'}
            </button>
            {this.state.innerdirty && <button className='app-btn-neutral ml5 mt5 p5 pl10 pr10' onClick={() => {this.innercancel()}}>
              Cancel
            </button>}
          </div>

          <div className='app-chromaticity-graph-active-bins-list mt5'>
            {this.props.chromaticity.length && this.props.chromaticity.map((item: any, idx: number) => {
              if(!this.state.bins[item.id]) {
                return ''
              }
              return (
                <div key={idx} className={this.state.innerbins[item.id] ? 'active' : ''} onClick={() => {this.innertoggle(item.id)}}>
                  {item.name}
                </div>
              )})}
          </div>

        </div>}



        {this.state.isLinear && <div className='mt15 pl0 app-chromaticity-graph-active-bins'>

          <div className='app-chromaticity-graph-active-bins-header'>
            <span className='mr5'><b>Inner Bins Positions</b></span>
            <br />
            {this.state.positionsdirty && <button className='app-btn-secondary ml5 mt5 p5 pl10 pr10' onClick={() => {this.positionssave()}}>
              Save
            </button>}
            <button className='app-btn-neutral ml5 mt5 p5 pl10 pr10' onClick={() => {this.positionstoggleAll()}}>
              {Object.keys(this.state.positionsbins).length < this.state.positions.length ? 'Select All' : 'Deselect All'}
            </button>
            {this.state.positionsdirty && <button className='app-btn-neutral ml5 mt5 p5 pl10 pr10' onClick={() => {this.positionscancel()}}>
              Cancel
            </button>}
          </div>

          <div className='app-chromaticity-graph-active-bins-list mt5'>
            {this.state.positions.length > 0 && this.state.positions.map((item: any, idx: number) => {
              return (
                <div key={idx} className={this.state.positionsbins[item] ? 'active' : ''} onClick={() => {this.positionstoggle(item)}}>
                  Position {item}
                </div>
              )})}
          </div>

        </div>}
     
      </div>
    );
  }

}


export default ChromacityGraphActiveBins;
