import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { ProductsService } from '../../../services/ProductsService';
import { Logger } from '../../../utils/Logger';
import Modal, { ModalInterface } from '../../ui-elements/modal/Modal';
import ProductsCtx from '../../../pages/products/ProductsCtx';
import './CopyProduct.scss';
import NavMap from '../../ui-elements/nav-map/NavMap';

function CopyProduct(props: any) {

  Logger.debug('CopyProduct component render.');

  const modalRef = useRef<ModalInterface>();
  const modalNavMapRef = useRef<any>();
  const productsCtxRef = React.useContext((ProductsCtx));  
  const [name, setName] = useState('');
  const [fail, setFail] = useState(false);
  const [productSelected, setProductSelected] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const save = () => {
    ProductsService.clone(props?.prod?.id, productSelected?.id).then(() => {
      if (props.onSave) {
        props.onSave();
      }
      setName('');
      modalRef?.current?.open(false);
    }).catch(() => {
      setFail(true);
    });
  }

  const open = () => {
    setName(props?.prod?.name ? props?.prod?.name : '');
    setLoading(true);
    setFail(false);
    modalRef?.current?.open(true);
  };

  useEffect(() => {
    if (modalRef.current?.isOpen()) {
      if (productsCtxRef.products) {
        modalNavMapRef?.current?.load(productsCtxRef.products);
        setLoading(false);
      } else {
        ProductsService.all().then((resp: any) => {
          modalNavMapRef?.current?.load(resp.data);
          setLoading(false);
        });
      }
    }
  }, [modalRef.current?.isOpen(), productsCtxRef.products, props.prod, loading]);

  const onSelectProduct = useCallback((item: any) => {
      setProductSelected(item);
      setFail(false);
  }, [productSelected]);

  return (
    <>
      <button className='app-product-copy-btn app-btn-neutral' onClick={() => { open(); }}>Copy</button>
      <Modal ref={modalRef}>
        <div className='app-product-copy'>
          <h2>Copy Product</h2>

          <p>Name</p>
          <input placeholder='Name' className={`app-input`} value={name} disabled/>

          <p>Copy to:</p>
          <div className='app-product-copy-list'>
              
              {!loading && 
                <NavMap 
                  ref={modalNavMapRef}
                  title='Products'
                  selectProp='id' 
                  presentProp='name' 
                  childrenProp='products'
                  filter={true} 
                  loopAll={true}
                  onSelect={onSelectProduct}
                />
              }
          </div>

          {fail && <p className='app-text-darkred'>*This product could not be copied!</p>} 
          <button className='app-btn-secondary' disabled={productSelected?.product || !productSelected} onClick={() => { save(); }}>Save</button>
        </div>
      </Modal>
    </>
  );
}
export default CopyProduct;

