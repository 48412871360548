import axios from "axios";
import { APP_SESSION_LOCALSTORAGE } from "../core/AppConstants";
import { AUTH_URL } from "../core/AppSettings";

export class AuthService {

  static readonly CLIENT_ID = 'optibin';
  static readonly CLIENT_SECRET = 'colorkinetics';

  static login() {
    window.location.href = AUTH_URL +
      '/auth/oauth/authorize?response_type=code&client_id=' + this.CLIENT_ID + '&redirect_uri=' + window.location.origin;
  }

  static logout() {
    localStorage.removeItem(APP_SESSION_LOCALSTORAGE);
    window.location.href = AUTH_URL +
      '/auth/user-logout?client_id=' + this.CLIENT_ID + '&redirect_uri=' + window.location.origin;
  }

  static changePassword() {
    window.location.href = AUTH_URL + '/auth/reset';
  }

  static decode(code: string) {
    const body = new URLSearchParams();
    body.set('client_id', this.CLIENT_ID);
    body.set('client_secret', this.CLIENT_SECRET);
    body.set('grant_type', 'authorization_code');
    body.set('code', code);
    body.set('redirect_uri', window.location.origin);

    return axios({
      method: 'post',
      url: '/auth/oauth/token',
      data: body,
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    })
  }

}
