import { useCallback, useEffect, useState } from 'react';
import { Logger } from '../../../utils/Logger';
import './BiningSelectInventory.scss';
import { UIUtils } from '../../../utils/UIUtils';

function BiningSelectInventory(props: any) {

  Logger.debug('BiningSelectInventory component render.');


  const DELIMITER_INVENTORY_SELECTION = '.====.';

  const [values, setValues] = useState<any>({});
  const [valid, setValid] = useState<any>({});

  useEffect(() => {  
    setValues(props.inventories);
    validate();
  }, [props.inventories]);

  const update = useCallback((k: any, v: any) => {
    const i = JSON.parse(JSON.stringify(props.inventories));
    try {
      if (+v > 0) {
        i[k] = v;
      } else {
        delete i[k];
      }
    } catch(e: any) {}
    
    props.setInventories(i);
  }, [props]);

  const validate = (() => {
    const v : any = {};

    for (const [key, value] of Object.entries(props.inventories)) {
      if (!UIUtils.validateNumber(value, 0, null, 0)) {
            v[key] = true;
         }
    }
    setValid(v);
    props.setValidation(Object.keys(v).length === 0);
  });

  return (
    <div className='app-product-bining'>
      {props.flux && props.flux.length > 0 && props.waveLength && props.waveLength.length > 0 && 
      <div className=''>
      {props.flux.map(function(f: any, fIdx: number) {
          return(
            <div key={fIdx}>
              {props.voltage.map(function(v: any, vIdx: number) {
                return(<div key={vIdx}>
                   {props.waveLength.map(function(w: any, wIdx: number) {
                      return(
                        <p className='app-product-bining-items' key={wIdx}>
                          <span className='mr30'><span className='app-text-gray'>
                            Flux: </span><b>{f.name}</b> 
                          </span>
                          <span className='mr30'><span className='app-text-gray'>
                            Wavelength: </span><b>{w.name}</b> 
                          </span>
                          {v?.id && <span className='mr30'><span className='app-text-gray'>
                            Forward Voltage: </span><b>{v?.name}</b> 
                          </span>}
                          <span>
                            <span className='app-text-gray'>Inventory Count:</span>
                            <input className={valid[props.channel.id + DELIMITER_INVENTORY_SELECTION + f.id + DELIMITER_INVENTORY_SELECTION + w.id + DELIMITER_INVENTORY_SELECTION + '0' + DELIMITER_INVENTORY_SELECTION + (v?.id ? v?.id : 0)] ? 'app-input ml5 app-border-red' : 'app-input ml5'} 
                            type='number' 
                            placeholder='Inventory Count' 
                            value={values[props.channel.id + DELIMITER_INVENTORY_SELECTION + f.id + DELIMITER_INVENTORY_SELECTION + w.id + DELIMITER_INVENTORY_SELECTION + '0' + DELIMITER_INVENTORY_SELECTION + (v?.id ? v?.id : 0)] ? values[props.channel.id + DELIMITER_INVENTORY_SELECTION + f.id + DELIMITER_INVENTORY_SELECTION + w.id + DELIMITER_INVENTORY_SELECTION + '0' + DELIMITER_INVENTORY_SELECTION + (v?.id ? v?.id : 0)] : 0} 
                            onChange={($event) => {update(props.channel.id + DELIMITER_INVENTORY_SELECTION + f.id + DELIMITER_INVENTORY_SELECTION + w.id + DELIMITER_INVENTORY_SELECTION + '0' + DELIMITER_INVENTORY_SELECTION + (v?.id ? v?.id : 0), $event.target.value)}}/>
                          </span>
                        </p>
                        )
                   })}
                </div>)
              })}
            </div>
            )
        })}
      </div>}

      {props.flux && props.flux.length > 0 && props.chromaticity && props.chromaticity.length > 0 && 
      <div className=''>
      {props.flux.map(function(f: any, fIdx: number) {
          return(
            <div key={fIdx}>
              {props.voltage.map(function(v: any, vIdx: number) {
                return(
                  <div key={vIdx}>
                    {props.chromaticity.map(function(c: any, cIdx: number) {
                      return(
                        <p className='app-product-bining-items' key={cIdx}>
                          <span className='mr30'><span className='app-text-gray'>
                            Flux: </span><b>{f.name}</b> 
                          </span>
                          <span className='mr30'><span className='app-text-gray'>
                            Chromaticity: </span><b>{c.name}</b> 
                          </span>
                          {v?.id && <span className='mr30'><span className='app-text-gray'>
                            Forward Voltage: </span><b>{v?.name}</b> 
                          </span>}
                          <span>
                            <span className='app-text-gray'>Inventory Count:</span>
                            <input className={valid[props.channel.id + DELIMITER_INVENTORY_SELECTION + f.id + DELIMITER_INVENTORY_SELECTION + '0' + DELIMITER_INVENTORY_SELECTION + c.id + DELIMITER_INVENTORY_SELECTION + (v?.id ? v?.id : 0)] ? 'app-input ml5 app-border-red' : 'app-input ml5'} 
                            type='number' 
                            min="0" 
                            placeholder='Inventory Count' 
                            value={values[props.channel.id + DELIMITER_INVENTORY_SELECTION + f.id + DELIMITER_INVENTORY_SELECTION + '0' + DELIMITER_INVENTORY_SELECTION + c.id + DELIMITER_INVENTORY_SELECTION + (v?.id ? v?.id : 0)] ? values[props.channel.id + DELIMITER_INVENTORY_SELECTION + f.id + DELIMITER_INVENTORY_SELECTION + '0' + DELIMITER_INVENTORY_SELECTION + c.id + DELIMITER_INVENTORY_SELECTION + (v?.id ? v?.id : 0)] : 0} 
                            onChange={($event) => {update(props.channel.id + DELIMITER_INVENTORY_SELECTION + f.id + DELIMITER_INVENTORY_SELECTION + '0' + DELIMITER_INVENTORY_SELECTION + c.id + DELIMITER_INVENTORY_SELECTION + (v?.id ? v?.id : 0), $event.target.value)}}/>
                          </span>
                        </p>
                        )
                    })}
                  </div>)
              })}
            </div>
            )
        })}
      </div>}
    </div>
  );
}
export default BiningSelectInventory;

