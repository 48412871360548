
import React from 'react';
import { ProductsService } from '../../../services/ProductsService';
import { Logger } from '../../../utils/Logger';
import './ChromacityGraphTargetPolygon.scss';
import AppCtx, { UnsavedActionEnum } from '../../../AppCtx';

class ChromacityGraphTargetPolygon extends React.Component<any, any> {

  static contextType = AppCtx;

  state: any = {
    mounted: false,
    cancelPolygon: [],
    savedTarget: null,
    savedTargetChannelId: null,
    saveEnabled : false,
    unsavedActionOn : false,
    valid: {
      cx: true,
      cy: true,
      rx: true,
      ry: true
    },
    inputs: {
      cx: '',
      cy: '',
      rx: '',
      ry: ''
    },
    vals: {
      cx: '',
      cy: '',
      rx: '',
      ry: ''
    },
    polygon: [
      {
        x: null,
        y: null
      },
      {
        x: null,
        y: null
      },
      {
        x: null,
        y: null
      },
      {
        x: null,
        y: null
      },
    ],
    angle : 0
  };

  constructor (props: any) {
    Logger.debug('ChromacityGraphTargetPolygon component init.');
    super(props);   
  }

  componentDidMount() {
    Logger.debug('ChromacityGraphTargetPolygon component mount.');
    this.setState({mounted: true});
    this.reload(this.props.polygon, false);
  }

  componentDidUpdate() {
    Logger.debug('ChromacityGraphTargetPolygon component update.');
    if(this.state.unsavedActionOn && this.context.ui.unsavedActionOn === UnsavedActionEnum.SAVE){
      this.save();
    } else if (this.context.ui.unsavedActionOn === UnsavedActionEnum.DISCARD) {
      this.cancel();
    }
  }

  componentWillReceiveProps (newProps: any) {
    if (this.props.polygon !== newProps.polygon) {
      if(newProps.editEnabled){
        this.reload(newProps.polygon, false);
      } else {
        this.reload(newProps.polygon, true);
      }
    }
  }

  reload(poly: any, force: boolean) {
    const valid = {
      cx: true,
      cy: true,
      rx: true,
      ry: true
    };
    if (poly?.length === 4) {
      const polygon = poly;
      const vals = {
        cx:  poly[0].x && poly[2].x ? +((poly[0].x + poly[2].x)/ 2).toFixed(4) : 0,
        cy:  poly[1].y && poly[3].y ? +((poly[1].y + poly[3].y) / 2).toFixed(4) : 0,
        rx:  (poly[1].x && poly[1].y) && (poly[3].x && poly[3].y) ?  +((Math.sqrt(Math.pow(poly[1].x - poly[3].x, 2) + Math.pow(poly[1].y - poly[3].y, 2)))/2).toFixed(4) : 0,
        ry:  (poly[0].x && poly[0].y) && (poly[2].x && poly[2].y) ? +((Math.sqrt(Math.pow(poly[0].x - poly[2].x, 2) + Math.pow(poly[0].y - poly[2].y, 2)))/2).toFixed(4) : 0
      };
      const inputs = this.state.inputs;
      const angle = Math.round(((Math.atan2(poly[1].y - vals.cy, poly[1].x - vals.cx)) * 180 / Math.PI + 360) % 360);
      this.setState({angle});

      if (JSON.stringify(vals) !== JSON.stringify(this.state.vals) || force) {
        inputs.cx = '' + vals.cx;
        inputs.cy = '' + vals.cy;
        inputs.rx = '' + vals.rx;
        inputs.ry = '' + vals.ry;
        this.setState({polygon, vals, inputs, valid});
        if (!force && !this.state.unsavedActionOn) {
          this.setState({saveEnabled : true, unsavedActionOn : true});
          this.props.unsavedActionUpdate(true);     
          this.context.setUi({...this.context.ui, unsavedActionOn: UnsavedActionEnum.EXIST});
        }  
      }
      
    }else {
      const polygon = poly;
      const inputs = this.state.inputs;
      inputs.cx = '';
      inputs.cy = '';
      inputs.rx = '';
      inputs.ry = '';
      const vals = inputs;
      this.setState({polygon, vals, inputs,valid});
    }
  }

  cancel() {
    this.reload(this.props.polygon, true);
    this.props.update(this.state.cancelPolygon);
    this.props.edit(false);
    this.setState({unsavedActionOn : false, saveEnabled : false});
    this.props.unsavedActionUpdate(false);
    if (!this.props.unsavedAction){
      this.context.setUi({...this.context.ui, unsavedActionOn: null});
    }
  }

  save() {
    if (!this.props.channel) {
      return;
    }
    const req: any = {
      channelId: this.props.channel?.id,
      leftTopX: this.state.polygon[0].x,
      leftTopY: this.state.polygon[0].y,
      rightTopX: this.state.polygon[1].x,
      rightTopY: this.state.polygon[1].y,
      rightBottomX: this.state.polygon[2].x,
      rightBottomY: this.state.polygon[2].y,
      leftBottomX:  this.state.polygon[3].x,
      leftBottomY:  this.state.polygon[3].y,
    };
    if (this.props.channel?.targetPolygons?.length) {
      req.id = this.props.channel?.targetPolygons[0].id;
    }
    if (this.state.savedTarget && this.props.channel?.id === this.state.savedTargetChannelId) {
      req.id = this.state.savedTarget.id;
    }
    ProductsService.saveTargetPolygon(this.props.channel?.id, [req]).then((resp: any) => {
      ProductsService.getChannel(this.props.channel?.id).then((resp1: any) => {
        if (resp1.data?.targetPolygons?.length > 0) {
          this.setState({savedTarget: resp1.data.targetPolygons[0], savedTargetChannelId: this.props.channel?.id});
        }
      });
      this.props.edit(false);
      this.setState({unsavedActionOn : false, saveEnabled : false, cancelPolygon: this.props.polygon});
      this.props.unsavedActionUpdate(false);
      if (!this.props.unsavedAction){
        this.context.setUi({...this.context.ui, unsavedActionOn: null});
      }
    });
  }

  inputChange(k: string, val: any) {
    const diff : any = {cx: 0, cy: 0};
    const vals = this.state.vals;
    const inputs = this.state.inputs;
    const valid = this.state.valid;
    inputs[k] = val;
    if ((+inputs[k] !== +vals[k]) && (k === 'cx' || k === 'cy')) {
      diff[k] = +inputs[k] - +vals[k];
    }
    if (this.validate(val)) {
      vals[k] = +val;
      valid[k] = true;
    } else {
      valid[k] = false;
      diff[k]= 0;
    }

    const polygon = [];
    this.setState({vals, inputs, valid});
    const saveEnabled = Object.values(this.state.valid).every((v) => v) && Object.values(inputs).every(value => value !== '');
    this.setState({saveEnabled});

    if (saveEnabled) {
      if (this.state.polygon.length === 4) {  
        const rx = +((Math.sqrt(Math.pow(this.state.polygon[1].x - this.state.polygon[3].x, 2) + Math.pow(this.state.polygon[1].y - this.state.polygon[3].y, 2)))/2).toFixed(4);
        const ry = +((Math.sqrt(Math.pow(this.state.polygon[0].x - this.state.polygon[2].x, 2) + Math.pow(this.state.polygon[0].y - this.state.polygon[2].y, 2)))/2).toFixed(4);
        
        const p0 = this.adjustCoords(this.state.polygon[0], ry, vals.ry);    
        const p1 = this.adjustCoords(this.state.polygon[1], rx, vals.rx);
        const p2 = this.adjustCoords(this.state.polygon[2], ry, vals.ry);    
        const p3 = this.adjustCoords(this.state.polygon[3], rx, vals.rx);

        const angle = Math.round(((Math.atan2(p1.y - vals.cy, p1.x - vals.cx)) * 180 / Math.PI + 360) % 360);
        this.setState({angle});
        polygon.push({ x : +(p0.x + diff.cx).toFixed(4), y: +(p0.y + diff.cy).toFixed(4) });
        polygon.push({ x : +(p1.x + diff.cx).toFixed(4), y: +(p1.y + diff.cy).toFixed(4) });
        polygon.push({ x : +(p2.x + diff.cx).toFixed(4), y: +(p2.y + diff.cy).toFixed(4) });
        polygon.push({ x : +(p3.x + diff.cx).toFixed(4), y: +(p3.y + diff.cy).toFixed(4) });
      } else {
        polygon.push({ x : vals.cx, y: vals.cy + vals.ry});  
        polygon.push({ x : vals.cx + vals.rx, y: vals.cy });
        polygon.push({ x : vals.cx, y: vals.cy - vals.ry });
        polygon.push({ x : vals.cx - vals.rx, y: vals.cy }); 
      }       

      this.setState({polygon});
      if (this.props.update) {
        this.props.update(polygon);
      }

      this.setState({unsavedActionOn : true});
      this.props.unsavedActionUpdate(true);     
      this.context.setUi({...this.context.ui, unsavedActionOn: UnsavedActionEnum.EXIST});

    } else {
      this.setState({unsavedActionOn : false});
      this.props.unsavedActionUpdate(false);
      if (!this.props.unsavedAction){
        this.context.setUi({...this.context.ui, unsavedActionOn: null});
      }
    }
  }
  
  validate(num: string) {
    return !isNaN(+num) && +num > 0 && +num < 1; 
  }

  adjustCoords(p:any, a: any, val: any) {
    const dx = p.x - this.state.vals.cx;
    const dy = p.y - this.state.vals.cy;
    const scaledDistance = (Math.sqrt(dx * dx + dy * dy)) * (val / a);
    const angle = Math.atan2(dy, dx);
    return { x: this.state.vals.cx + scaledDistance * Math.cos(angle), y: this.state.vals.cy + scaledDistance * Math.sin(angle) };
  }

  render() {
    return (
      <div className='mt15 app-chromaticity-graph-target-polygon'>

        <div className='app-chromaticity-graph-target-polygon-100'>
          {!this.props?.editEnabled && 
          <button className='app-btn-neutral p5 pl10 pr10 mr5' onClick={() => {this.setState({cancelPolygon: this.props.polygon});this.props.edit(true)}}>
            {this.props?.polygon?.length >= 4 ? 'Edit' : 'Add'} Target Polygon
          </button>}
          {this.props?.editEnabled && 
          <button className='app-btn-secondary p5 pl10 pr10 mr5' disabled={!this.state.saveEnabled} onClick={() => {this.save()}}>
            Save
          </button>
          }
          {this.props?.editEnabled && 
          <button className='app-btn-neutral p5 pl10 pr10 mr5' onClick={() => {this.cancel()}}>
            Cancel
          </button>
          }
        </div>

      
        <div className='mt10 app-chromaticity-graph-target-polygon-column'>
          <div className='app-chromaticity-graph-target-polygon-33 pr10'>
            <p className=''><b>Center</b></p>
            <input className={this.state.valid['cx'] ? 'app-input': 'app-input app-border-red mt5'} placeholder='X' type='text'
              disabled={!this.props?.editEnabled}
              value={this.state.inputs.cx} 
              onChange={(event: any) => {
                this.inputChange('cx', event.target.value);
              }}/>
              
            <input className={this.state.valid['cy'] ? 'app-input mt5': 'app-input app-border-red mt5'} placeholder='Y' type='text'
              disabled={!this.props?.editEnabled}
              value={this.state.inputs.cy} 
              onChange={(event: any) => {
                this.inputChange('cy', event.target.value);
              }}/>
          </div>

          <div className='app-chromaticity-graph-target-polygon-33 pr10'>
            <p className=''><b>Radius</b></p>
            <input className={this.state.valid['rx'] ? 'app-input': 'app-input app-border-red'} placeholder='X' type='text'
              disabled={!this.props?.editEnabled}
              value={this.state.inputs.rx} 
              onChange={(event: any) => {
                this.inputChange('rx', event.target.value);
              }}/>
              
            <input className={this.state.valid['ry'] ? 'app-input mt5': 'app-input app-border-red mt5'} placeholder='Y' type='text'
              disabled={!this.props?.editEnabled}
              value={this.state.inputs.ry} 
              onChange={(event: any) => {
                this.inputChange('ry', event.target.value);
              }}/>
          </div>

          <div className='app-chromaticity-graph-target-polygon-33 pr10'>
            <p className=''><b>Angle</b></p>
            <input className='app-input' placeholder='Angle' type='text'
              disabled={true}
              value={this.state.angle} 
              />
          </div>
        </div>

      </div>
    );
  }

}


export default ChromacityGraphTargetPolygon;
