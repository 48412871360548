import { useContext, useEffect, useRef, useState } from 'react';
import AppCtx from '../../../AppCtx';
import { APP_FEATURES } from '../../../core/AppConstants';
import { ManufacturersService } from '../../../services/ManufacturersService';
import { Logger } from '../../../utils/Logger';
import Modal, { ModalInterface } from '../../ui-elements/modal/Modal';
import './AddManufacturer.scss';

function AddManufacturer(props: any) {

  Logger.debug('AddManufacturer component render.');

  const appCtxRef = useContext(AppCtx);
  
  const modalRef = useRef<ModalInterface>();
  const [name, setName] = useState('');
  const [fail, setFail] = useState(false);

  //const [UPDATE_PERMISSION] = useState<boolean>(appCtxRef?.session?.features[APP_FEATURES.GeneralManufacturerUpdate]);
  const [EXPORT_PERMISSION] = useState<boolean>(appCtxRef?.session?.features[APP_FEATURES.GeneralManufacturerExport]);

  const save = () => {
    const req: any = {
      name
    };
    if (props?.edit?.id) {
      req.id = props?.edit?.id;
    }

    ManufacturersService.save([req]).then(() => {
      if (props.onSave) {
        props.onSave();
      }
      setName('');
      modalRef?.current?.open(false);
    }).catch(() => {
      setFail(true);
    });
  }

  const open = (() => {
    setName(props?.edit?.name ? props?.edit?.name : '');
    modalRef?.current?.open(true);
  });

  const exportItem = () => {
    ManufacturersService.export(props?.edit?.id, props?.navFilter).then((resp: any) => {
      const url = URL.createObjectURL(new Blob([resp.data], { type: 'octet/stream' }));
      const element = document.createElement('a');
      element.setAttribute('href', url);
      element.setAttribute('download', 'export.zip');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }, () => {
    });   
  }


  return (
    <>
      {((!props?.edit || appCtxRef?.session?.details?.identifier === props?.edit?.createdBy) || appCtxRef?.session?.details?.userProtected) && <button className={props?.edit ? 'app-manufacturer-add-btn app-btn-neutral' : 'app-manufacturer-add-btn app-btn-secondary'} onClick={() => { open(); }}>
        {props?.edit ? 'Edit' : 'Add'}
      </button>}
      {EXPORT_PERMISSION && <button className='app-manufacturer-add-btn app-btn-neutral' onClick={() => {exportItem()}}>
        {props?.edit ? 'Export' : 'Export All'}
      </button>}
      <Modal ref={modalRef}>
        <div className='app-manufacturer-add'>
          <h2>{props?.edit ? 'Edit' : 'Add'} Manufacturer</h2>

          <p>Name</p>
          <input placeholder='Name' className={`app-input`} value={name} 
            onChange={(event: any) => {setFail(false); setName(event.target.value);}} />
            
          {fail && <p className='app-text-darkred'>*This manufacturer could not be saved!</p>}

          <button className='app-btn-secondary' disabled={!name} onClick={() => { save(); }}>Save</button>
        </div>
      </Modal>
    </>
  );
}
export default AddManufacturer;

