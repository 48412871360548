import axios from "axios";

export class BiningService {


  static run(data: any) {
    return axios({
      method: 'post',
      url: '/api/binning',
      data
    });
  }


  static save(data: any) {
    return axios({
      method: 'post',
      url: '/api/binning/save',
      data
    });
  }


  static upload(productId: number, file: any) {
    const formData = new FormData();
    formData.append("file", file);
    return axios({
      method: 'post',
      url: '/api/binning/' + productId + '/upload',
      headers: { "Content-Type": "multipart/form-data" },
      data: formData
    });
  }

  static download(productId: number, data: any) {
    return axios({
      method: 'post',
      url: '/api/binning/' + productId + '/download',
      data,
      responseType: 'arraybuffer'
    });
  }

  static updateBinCode(productId: number, pcaId: number, runId: number, oldbincode: number, bincode: number) {
    return axios({
      method: 'post',
      url: '/api/binning/bincode/' + productId + '/' + pcaId + '/' + runId + '/' + oldbincode + '/' + bincode,
      headers: { "Content-Type": "application/json" },
      data: null
    });
  }

  static retire(runId: number) {
    return axios({
      method: 'post',
      url: '/api/binning/retire/' + runId,
      headers: { "Content-Type": "application/json" },
      data: null
    });
  }


}
