import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Logger } from '../../../utils/Logger';
import Modal, { ModalInterface } from '../modal/Modal';
import './YesNoModal.scss';


const YesNoModal = forwardRef((props: any, ref: any) => {

  Logger.debug('YesNoModal component render.');
  
  const modalRef = useRef<ModalInterface>();
  const [err, setErr] = useState<any>(null);

  useImperativeHandle(ref, () => ({

    open(status: boolean) {
      modalRef?.current?.open(status);
    },

    fail(e: string) {
      setErr(e);
    }

  }));

  const yes = () => {
    if (props?.yes) {
      props?.yes(props?.data);
    }
    if (!props?.yesCloseDisabled) {
      modalRef?.current?.open(false);
    }
  }

  const no = () => {
    modalRef?.current?.open(false);
  }
  
  return (
    <Modal ref={modalRef}>
      <div className='app-yes-no-modal'>
        <h2>{props.title ? props.title : 'Are you sure'}</h2>


        {props.subtitle && <p>{props.subtitle}</p>}

        <p>{props.text ? props.text : ''}</p>
        <button className='app-btn-secondary' onClick={() => {yes()}}>
          {props.btnYes ? props.btnYes : 'Yes'}
        </button>
        {!props.btnNoHide && <button className='app-btn-neutral' onClick={() => {no()}}>
          {props.btnNo ? props.btnNo : 'No'}
        </button>}

        {err && <p className='text-darkred'>{err}</p>}
      </div>
    </Modal>
  );
});
export default YesNoModal;