import { useEffect, useRef, useState } from 'react';
import { APP_PRODUCT_TYPES } from '../../../core/AppConstants';
import { ProductsService } from '../../../services/ProductsService';
import { Logger } from '../../../utils/Logger';
import Modal, { ModalInterface } from '../../ui-elements/modal/Modal';
import './AddProduct.scss';

function AddProduct(props: any) {

  Logger.debug('AddProduct component render.');

  const modalRef = useRef<ModalInterface>();
  const [name, setName] = useState('');
  const [description, setDesc] = useState('');
  const [fixturetype, setFixtureType] = useState<any>(APP_PRODUCT_TYPES.LINEAR);
  const [type, setType] = useState('1');
  const [fail, setFail] = useState(false);

  const save = () => {
    const req: any = {
      name,
      description,
      fixtureType: !type ? '' : fixturetype,
      product: type ? true : false,
    };
    if (!props.edit) {
      req.parent = { 
        id: props.parentId ? props.parentId  : 0
      };
    }
    if (props?.edit?.id) {
      req.id = props?.edit?.id;
      req.product = props?.edit?.product;
      req.parent = { 
        id: props?.edit?.parent?.id
      };
    }

    ProductsService.save([req]).then(() => {
      if (props.onSave) {
        props.onSave();
      }
      setName('');
      setDesc('');
      setType('1');
      modalRef?.current?.open(false);
    }).catch(() => {
      setFail(true);
    });
  }

  const open = (() => {
    setName(props?.edit?.name ? props?.edit?.name : '');
    setFixtureType(props?.edit?.fixtureType ? props?.edit?.fixtureType : APP_PRODUCT_TYPES.LINEAR);
    setDesc(props?.edit?.description ? props?.edit?.description : '');
    setType(!props?.edit?.product ? '' : '1');

    modalRef?.current?.open(true);
  });


  return (
    <>
      <button className={props?.edit ? 'app-product-add-btn app-btn-neutral' : 'app-product-add-btn app-btn-secondary'} onClick={() => { open(); }}>
        {props?.edit ? 'Edit' : 'Add'}
      </button>
      <Modal ref={modalRef}>
        <div className='app-product-add'>
          <h2>{props?.edit ? 'Edit' : 'Add'} Product {!type ? 'Folder' : ''}</h2>

          <p>Name</p>
          <input placeholder='Name' className={`app-input`} value={name} 
            onChange={(event: any) => {setFail(false); setName(event.target.value);}} />

          <p className='mt10'>Description</p>
          <input placeholder='Description' className={`app-input`} value={description} 
            onChange={(event: any) => {setFail(false); setDesc(event.target.value);}} />

          {!props.edit && <p className='mt15'>Type</p>}
          {!props.edit && <select className='app-input' value={type} onChange={(event: any) => {setFail(false); setType(event.target.value);}} >
            <option value="">Folder</option>
            <option value="1">Fixture</option>
          </select>}

          {type && <p className='mt10'>Fixture Type</p>}
          {type && 
            <select className='app-input' value={fixturetype} onChange={(event: any) => {setFail(false); setFixtureType(event.target.value);}} >
              {Object.keys(APP_PRODUCT_TYPES).map(function(row: any, rowIdx: number) {
                return(<option key={rowIdx} value={APP_PRODUCT_TYPES[row]}>{APP_PRODUCT_TYPES[row]}</option>)
              })}
            </select>
          }
            
          {fail && <p className='app-text-darkred'>*This product could not be saved!</p>}

          <button className='app-btn-secondary' disabled={!name} onClick={() => { save(); }}>Save</button>
        </div>
      </Modal>
    </>
  );
}
export default AddProduct;

