import { useCallback, useEffect, useRef, useState } from 'react';
import { ManufacturersService } from '../../../../services/ManufacturersService';
import { Logger } from '../../../../utils/Logger';
import Modal, { ModalInterface } from '../../../ui-elements/modal/Modal';
import DataView from '../../../ui-elements/data-view/DataView';
import JsonObjectEdit from '../../../ui-elements/json-object-edit/JsonObjectEdit';
import YesNoModal from '../../../ui-elements/yes-no-modal/YesNoModal';
import { UIUtils } from '../../../../utils/UIUtils';

function ForwardVoltage(props: any) {

  Logger.debug('ForwardVoltage component render.');

  const modalEditRef = useRef<ModalInterface>();
  const modalDelRef = useRef<ModalInterface>();

  const [data, setData] = useState<any>({});
  const [selected, setSelected] = useState<any>(null);

  const [editData, setEditData] = useState<any>(null);
  const [editFail, setEditFail] = useState<boolean>(false);

  const [objToDelete, setObjToDelete] = useState<any>(null);

  //const appCtxRef = React.useContext(AppCtx);
  //const [UPDATE_PERMISSION] = useState<boolean>(appCtxRef?.session?.features[APP_FEATURES.GeneralManufacturerUpdate]);
  

  const load = useCallback((reload: boolean, saveAndAdd: boolean) => {
    if (reload || !data[props?.color?.id]) {
      data[props?.color?.id] = [];
      setData(data);
      setSelected(data[props?.color?.id]);
      ManufacturersService.getForwardvoltage(props?.color?.id).then((resp: any) => {
        const dCopy = JSON.parse(JSON.stringify(data));
        dCopy[props?.color?.id] = resp.data;
        setData(dCopy);
        setSelected(resp.data);
        modalDelRef?.current?.open(false);
        if (!saveAndAdd) {
          modalEditRef?.current?.open(false);
        }
      });
    } else {
      setSelected(data[props?.color?.id]);
    }
  }, [props?.color, data]);

  const saveExe = useCallback((d: any) => {
    d.inUse = true;
    d.color = {
      id: props?.color?.id
    };
    
    ManufacturersService.saveForwardvoltage([d]).then(() => {
      load(true, d.saveAndAdd);
    }).catch(() => {
      setEditFail(true);
    });
  }, [props?.color, load]);

  const save = useCallback((d: any) => {
    d.saveAndAdd = false;
    saveExe(d);
  }, [saveExe]);

  const saveAndAdd = useCallback((d: any) => {
    d.saveAndAdd = true;
    saveExe(d);
  }, [saveExe]);

  const edit = useCallback((item: any) => {
    setEditFail(false);
    setEditData(item);
    modalEditRef?.current?.open(true);
  }, []);

  const del = useCallback((item: any) => {
    setObjToDelete(item);
    modalDelRef?.current?.open(true);
  }, []);

  const delConfirm = useCallback(() => {
    ManufacturersService.retireForwardvoltage(objToDelete?.id).then(() => {
      load(true, false);
    }).catch(() => {
      // TODO
    });
  }, [objToDelete, load]);

  useEffect(() => {
    load(false, false);
  }, [props, load]);


  return (
    <div className='app-forward-voltage'>
      <DataView 
        header={['ID', 'Min', 'Max']}
        keys={['name', 'minValue', 'maxValue']}
        appendunit={{'Min':true, 'Max':true}}
        data={selected} 
        title='Forward Voltage'
        headerIcon={ '/assets/img/icons/plus-black.svg' }
        headerIconClick={() => {edit(null)}}
        rowIcon={ '/assets/img/icons/minus-black.svg' }
        rowIconClick={(idx: number) => {del(selected[idx])}}
        editRow={true}
        editRowClick={(idx: number) => {edit(selected[idx])}}
        validateCreatedBy='true'>
      </DataView>
      
      <Modal ref={modalEditRef}>
        <JsonObjectEdit 
          header={['ID', 'Min', 'Max']}
          keys={['name', 'minValue', 'maxValue']}
          validation={
            {
              'minValue' : (v: any, data: any) => {return (!v.toString().includes(' ') && UIUtils.validateNumber(v, 0, null, 2))},
              'maxValue' : (v: any, data: any) => {return (!v.toString().includes(' ') && UIUtils.validateNumber(v, 0, null, 2))}
            }
          }
          options={{}}
          validationDesc={
            'Min and Max must be positive numbers with max 2 decimals'
          }
          data={editData} 
          onSave={save}
          onSaveAndAdd={saveAndAdd}
          fail={editFail} 
          title='Forward Voltage'>
        </JsonObjectEdit>
      </Modal>

      <YesNoModal ref={modalDelRef} yes={delConfirm} yesCloseDisabled={true}>
      </YesNoModal>
    </div>
  );
}
export default ForwardVoltage;

