import axios from "axios";

export class UserService {

  static details() {
    return axios({
      method: 'get',
      url: '/auth/accounts/user/details'
    })
  }

  static users() {
    return axios({
      method: 'get',
      url: '/auth/accounts/users'
    })
  }

  static addUser(email: String) {
    return axios({
      method: 'post',
      url: '/auth/accounts/user/invite',
      data: {
        'contact': email,
        'requestType': 3 // TODO
      }
    })
  }

  static readdUser(email: String) {
    return axios({
      method: 'put',
      url: '/auth/accounts/user/reinvite',
      data: {
        'contact': email,
        'requestType': 4 // TODO
      }
    })
  }

  static detailsUpdate(body: any) {
    return axios({
      method: 'put',
      url: '/auth/accounts/user/details',
      data: body
    })
  }

  static getAuthorizationRules() { 
    return axios({
      method: 'get',
      url: '/auth/accounts/user/getAuthorizationRules'
    })
  }
}
