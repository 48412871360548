import { useCallback, useContext, useEffect, useState } from 'react';
import AppCtx, { UnsavedActionEnum } from '../../../AppCtx';
import { APP_COLOR_TYPES } from '../../../core/AppConstants';
import { ProductsService } from '../../../services/ProductsService';
import { Logger } from '../../../utils/Logger';
import { UIUtils } from '../../../utils/UIUtils';
import './ProductChannelCommon.scss';

function ProductChannelCommon(props: any) {

  Logger.debug('ProductChannelCommon component render.');

  const appCtxRef = useContext(AppCtx);

  const [flux, setFlux] = useState<any>({});
  const [minSetupFlux, setminSetupFlux] = useState<any>('');
  const [maxSetupFlux, setmaxSetupFlux] = useState<any>('');
  const [minSetupFluxBck, setminSetupFluxBck] = useState<any>('');
  const [maxSetupFluxBck, setmaxSetupFluxBck] = useState<any>('');
  const [minEmitterFlux, setminEmitterFlux] = useState<any>('');
  const [maxEmitterFlux, setmaxEmitterFlux] = useState<any>('');
  const [minEmitterFluxBck, setminEmitterFluxBck] = useState<any>('');
  const [maxEmitterFluxBck, setmaxEmitterFluxBck] = useState<any>('');
  const [fluxTargetValue, setFluxTargetValue] = useState<any>('');
  const [fluxTargetOffsetValue, setFluxTargetOffsetValue] = useState<any>('');
  const [fluxTargetValueBck, setFluxTargetValueBck] = useState<any>('');
  const [fluxTargetOffsetValueBck, setFluxTargetOffsetValueBck] = useState<any>('');
  const [voltage, setVoltage] = useState<any>({});
  const [minSetupVf, setminSetupVf] = useState<any>('');
  const [maxSetupVf, setmaxSetupVf] = useState<any>('');
  const [minSetupVfBck, setminSetupVfBck] = useState<any>('');
  const [maxSetupVfBck, setmaxSetupVfBck] = useState<any>('');
  const [minEmitterVf, setminEmitterVf] = useState<any>('');
  const [maxEmitterVf, setmaxEmitterVf] = useState<any>('');
  const [minEmitterVfBck, setminEmitterVfBck] = useState<any>('');
  const [maxEmitterVfBck, setmaxEmitterVfBck] = useState<any>('');
  const [wavelength, setWavelength] = useState<any>({});
  const [minSetupWavelength, setminSetupWavelength] = useState<any>('');
  const [maxSetupWavelength, setmaxSetupWavelength] = useState<any>('');
  const [minSetupWavelengthBck, setminSetupWavelengthBck] = useState<any>('');
  const [maxSetupWavelengthBck, setmaxSetupWavelengthBck] = useState<any>('');
  const [minEmitterWavelength, setminEmitterWavelength] = useState<any>('');
  const [maxEmitterWavelength, setmaxEmitterWavelength] = useState<any>('');
  const [minEmitterWavelengthBck, setminEmitterWavelengthBck] = useState<any>('');
  const [maxEmitterWavelengthBck, setmaxEmitterWavelengthBck] = useState<any>('');
  const [wavelengthTargetValue, setWavelengthTargetValue] = useState<any>('');
  const [wavelengthTargetOffsetValue, setWavelengthTargetOffsetValue] = useState<any>('');
  const [wavelengthTargetValueBck, setWavelengthTargetValueBck] = useState<any>('');
  const [wavelengthTargetOffsetValueBck, setWavelengthTargetOffsetValueBck] = useState<any>('');
  const [channel, setChannel] = useState<any>(null);
  const [deltaX, setDeltaX] = useState<number>(0);
  const [numberOfPixelsPerFixture, setnumberOfPixelsPerFixture] = useState<number>(0);
  const [ledsNum, setLedsNum] = useState<number>(0);
  const [deltaXBck, setDeltaXBck] = useState<number>(0);
  const [numberOfPixelsPerFixtureBck, setnumberOfPixelsPerFixtureBck] = useState<number>(0);
  const [ledsNumBck, setLedsNumBck] = useState<number>(0);
  const [dirty, setDirty] = useState<any>(false);
  const [valid, setValid] = useState<any>({});
  const [unsavedActionOn, setUnsavedActionOn] = useState<any>(null);
  const [diverseBinsWavelength, setdiverseBinsWavelength] = useState<any>('1');
  const [diverseBinsFlux, setdiverseBinsFlux] = useState<any>('1');
  const [diverseBinsChromaticity, setdiverseBinsChromaticity] = useState<any>('1');
  const [diverseBinsWavelengthBck, setdiverseBinsWavelengthBck] = useState<any>('1');
  const [diverseBinsFluxBck, setdiverseBinsFluxBck] = useState<any>('1');
  const [diverseBinsChromaticityBck, setdiverseBinsChromaticityBck] = useState<any>('1');
  const [voltageStatusFlag, setvoltageStatusFlag] = useState<any>('0');
  const [voltageStatusFlagBck, setvoltageStatusFlagBck] = useState<any>('0');
  const [saveStatusFlag, setsaveStatusFlag] = useState<any>(false);
  const [minFluxUnit, setminFluxUnit] = useState<any>('');
  const [maxFluxUnit, setmaxFluxUnit] = useState<any>('');
  const [minWavelengthUnit, setminWavelengthUnit] = useState<any>('');
  const [maxWavelengthUnit, setmaxWavelengthUnit] = useState<any>('');
  const [fluxMinValues, setFluxMinValues] = useState<any>({});
  const [fluxMaxValues, setFluxMaxValues] = useState<any>({});
  const [wavelengthMinValues, setWavelengthMinValues] = useState<any>({});
  const [wavelengthMaxValues, setWavelengthMaxValues] = useState<any>({});
  const [voltageMinValues, setVoltageMinValues] = useState<any>({});
  const [voltageMaxValues, setVoltageMaxValues] = useState<any>({});

  const loadProperties = useCallback((properties: any) => {
    setValid({});
    let dx = '1';
    let pixnum = '';
    let ledsNum = '';
    let fMin: any = '';
    let fMax: any = '';
    let feMin: any = '';
    let feMax: any = '';
    let fTaget: any = '';
    let fOffset: any = '';
    let vMin: any = '';
    let vMax: any = '';
    let veMin: any = '';
    let veMax: any = '';
    let wMin: any = '';
    let wMax: any = '';
    let weMin: any = '';
    let weMax: any = '';
    let wTaget: any = '';
    let wOffset: any = '';
    let diverseF: any = '1';
    let diverseW: any = '1';
    let diverseC: any = '1';
    let voltageStatusFlagTmp = '0';


    if (properties) {
      for (const p of properties) {
        if (p?.name === 'deltaX') {
          dx = p?.value;
        }
        if (p?.name === 'numberOfPixelsPerFixture') {
          pixnum = p?.value;
        }
        if (p?.name === 'numberOfEmittersPerPixel') {
          ledsNum = p?.value;
        }
        if (p?.name === 'maxSetupFlux' && p?.value) {
          fMax = +p?.value;
        }
        if (p?.name === 'minSetupFlux' && p?.value) {
          fMin = +p?.value
        }
        if (p?.name === 'maxEmitterFlux' && p?.value) {
          feMax = p?.value.toString();
        }
        if (p?.name === 'minEmitterFlux' && p?.value) {
          feMin = p?.value.toString();
        }
        if (p?.name === 'targetFlux' && p?.value) {
          fTaget = +p?.value
        }
        if (p?.name === 'offsetFlux' && p?.value) {
          fOffset = +p?.value
        }
        if (p?.name === 'maxSetupVf' && p?.value) {
          vMax = +p?.value;
        }
        if (p?.name === 'minSetupVf' && p?.value) {
          vMin = +p?.value;
        }
        if (p?.name === 'maxEmitterVf' && p?.value) {
          veMax = p?.value.toString();
        }
        if (p?.name === 'minEmitterVf' && p?.value) {
          veMin = p?.value.toString();
        }
        if (p?.name === 'maxSetupWavelength' && p?.value) {
          wMax = +p?.value;
        }
        if (p?.name === 'minSetupWavelength' && p?.value) {
          wMin = +p?.value;
        }
        if (p?.name === 'maxEmitterWavelength' && p?.value) {
          weMax = p?.value.toString();
        }
        if (p?.name === 'minEmitterWavelength' && p?.value) {
          weMin = p?.value.toString();
        }
        if (p?.name === 'targetWavelength' && p?.value) {
          wTaget = +p?.value
        }
        if (p?.name === 'offsetWavelength' && p?.value) {
          wOffset = +p?.value
        }
        if (p?.name === 'diverseBinsWavelength') {
          diverseW = p?.value
        }
        if (p?.name === 'diverseBinsFlux') {
          diverseF = p?.value
        }
        if (p?.name === 'diverseBinsChromaticity') {
          diverseC = p?.value
        }
        if (p?.name === 'voltageStatusFlag') {
          voltageStatusFlagTmp = p?.value
        }
      }
    }

    if ((feMax !=='' && feMin !=='') && (+fMax === 0 && +fMin ===0)){
      fMax = feMax;
      fMin = feMin;
    }
    if ((veMax !=='' && veMin !=='') && (+vMax === 0 && +vMin ===0)){
      vMax = veMax;
      vMin = veMin;
    }
    if ((weMax !=='' && weMin !=='') && (+wMax === 0 && +wMin ===0)){
      wMax = weMax;
      wMin = weMin;
    }


    setDeltaX(+dx);
    setnumberOfPixelsPerFixture(+pixnum);
    setLedsNum(+ledsNum);
    setmaxSetupFlux(+fMax);
    setminSetupFlux(+fMin);
    setmaxEmitterFlux(feMax);
    setminEmitterFlux(feMin);
    setFluxTargetValue(+fTaget);
    setFluxTargetOffsetValue(+fOffset);
    setmaxSetupVf(+vMax);
    setminSetupVf(+vMin);
    setmaxEmitterVf(veMax);
    setminEmitterVf(veMin);
    setmaxSetupWavelength(+wMax);
    setminSetupWavelength(+wMin);
    setmaxEmitterWavelength(weMax);
    setminEmitterWavelength(weMin);
    setWavelengthTargetValue(+wTaget);
    setWavelengthTargetOffsetValue(+wOffset);
    setvoltageStatusFlag(voltageStatusFlagTmp)
    
    setActive('activeFlux', +fMin, +feMin, +fMax, +feMax );
    setActive('activeWavelength', +wMin, +weMin, +wMax, +weMax );
    setActive('activeVoltage', +vMin, +veMin, +vMax, +veMax );
    props.activeVoltageStatus(voltageStatusFlagTmp);


    setDeltaXBck(+dx);
    setnumberOfPixelsPerFixtureBck(+pixnum);
    setLedsNumBck(+ledsNum);
    setmaxSetupFluxBck(+fMax);
    setminSetupFluxBck(+fMin);
    setFluxTargetValueBck(+fTaget);
    setFluxTargetOffsetValueBck(+fOffset);
    setmaxSetupVfBck(+vMax);
    setminSetupVfBck(+vMin);
    setmaxSetupWavelengthBck(+wMax);
    setminSetupWavelengthBck(+wMin);
    setWavelengthTargetValueBck(+wTaget);
    setWavelengthTargetOffsetValueBck(+wOffset);
    
    setmaxEmitterFluxBck(feMax);
    setminEmitterFluxBck(feMin);
    setmaxEmitterVfBck(veMax);
    setminEmitterVfBck(veMin);
    setmaxEmitterWavelengthBck(weMax);
    setminEmitterWavelengthBck(weMin);

    setdiverseBinsChromaticity(+diverseC);
    setdiverseBinsFlux(+diverseF);
    setdiverseBinsWavelength(+diverseW);

    setdiverseBinsChromaticityBck(+diverseC);
    setdiverseBinsFluxBck(+diverseF);
    setdiverseBinsWavelengthBck(+diverseW);
    setvoltageStatusFlagBck(voltageStatusFlagTmp);
  },[]);

  const cancel = useCallback(() => {
    setValid({});
    setDeltaX(deltaXBck);
    setnumberOfPixelsPerFixture(numberOfPixelsPerFixtureBck);
    setLedsNum(ledsNumBck);
    setmaxSetupFlux(maxSetupFluxBck);
    setminSetupFlux(minSetupFluxBck);
    setFluxTargetValue(fluxTargetValueBck);
    setFluxTargetOffsetValue(fluxTargetOffsetValueBck);
    setmaxSetupVf(maxSetupVfBck);
    setminSetupVf(minSetupVfBck);
    setmaxSetupWavelength(maxSetupWavelengthBck);
    setminSetupWavelength(minSetupWavelengthBck);
    setWavelengthTargetValue(wavelengthTargetValueBck);
    setWavelengthTargetOffsetValue(wavelengthTargetOffsetValueBck);

    setdiverseBinsChromaticity(diverseBinsChromaticityBck);
    setdiverseBinsFlux(diverseBinsFluxBck);
    setdiverseBinsWavelength(diverseBinsWavelengthBck);

    setmaxEmitterFlux(maxEmitterFluxBck);
    setminEmitterFlux(minEmitterFluxBck);
    setmaxEmitterVf(maxEmitterVfBck);
    setminEmitterVf(minEmitterVfBck);
    setmaxEmitterWavelength(maxEmitterWavelengthBck);
    setminEmitterWavelength(minEmitterWavelengthBck);

    setvoltageStatusFlag(voltageStatusFlagBck);

  }, [diverseBinsChromaticityBck, diverseBinsFluxBck, diverseBinsWavelengthBck, deltaXBck, numberOfPixelsPerFixtureBck, 
    ledsNumBck, maxSetupFluxBck, minSetupFluxBck, maxSetupVfBck, minSetupVfBck, maxSetupWavelengthBck, minSetupWavelengthBck, 
    fluxTargetValueBck, fluxTargetOffsetValueBck, wavelengthTargetValueBck, wavelengthTargetOffsetValueBck,
    maxEmitterFluxBck, minEmitterFluxBck, maxEmitterVfBck, minEmitterVfBck, minEmitterWavelengthBck, maxEmitterWavelengthBck,
    voltageStatusFlagBck
  ]);


  useEffect(() => {

    const v : any = {};

    if (!UIUtils.validateNumber(deltaX, 1, null, 0)) {
      v['deltaX'] = true;
    }
    if (channel?.color?.colorType !== APP_COLOR_TYPES.WAVELENGTH  && !UIUtils.validateNumber(diverseBinsChromaticity, 1, null, 0)) {
      v['diverseBinsChromaticity'] = true;
    }
    if (!UIUtils.validateNumber(diverseBinsFlux, 1, null, 0)) {
      v['diverseBinsFlux'] = true;
    }
    if (channel?.color?.colorType === APP_COLOR_TYPES.WAVELENGTH && !UIUtils.validateNumber(diverseBinsWavelength, 1, null, 0)) {
      v['diverseBinsWavelength'] = true;
    }
    if (!UIUtils.validateNumber(numberOfPixelsPerFixture, 0, null, 0)) {
      v['numberOfPixelsPerFixture'] = true;
    }
    if (!UIUtils.validateNumber(ledsNum, 0, null, 0)) {
      v['ledsNum'] = true;
    }
    if (!UIUtils.validateNumber(maxSetupFlux, 0, null, 2)) {
      v['maxSetupFlux'] = true;
    }
    if (!UIUtils.validateNumber(minSetupFlux, 0, null, 2)) {
      v['minSetupFlux'] = true;
    }
    if (!UIUtils.validateNumber(maxEmitterFlux, 0, null, 2) && maxEmitterFlux !== "") {
      v['maxEmitterFlux'] = true;
    }
    if (!UIUtils.validateNumber(minEmitterFlux, 0, null, 2) && minEmitterFlux !== "") {
      v['minEmitterFlux'] = true;
    }
    if (!UIUtils.validateNumber(minSetupVf, 0, null, 2)) {
      v['minSetupVf'] = true;
    }
    if (!UIUtils.validateNumber(maxSetupVf, 0, null, 2)) {
      v['maxSetupVf'] = true;
    }
    if (!UIUtils.validateNumber(minEmitterVf, 0, null, 2) && minEmitterVf !== "") {
      v['minEmitterVf'] = true;
    }
    if (!UIUtils.validateNumber(maxEmitterVf, 0, null, 2) && maxEmitterVf !== "") {
      v['maxEmitterVf'] = true;
    }
    if (!UIUtils.validateNumber(maxSetupWavelength, 0, null, 2)) {
      v['maxSetupWavelength'] = true;
    }
    if (!UIUtils.validateNumber(minSetupWavelength, 0, null, 2)) {
      v['minSetupWavelength'] = true;
    }
    if (!UIUtils.validateNumber(maxEmitterWavelength, 0, null, 2) && maxEmitterWavelength !== "") {
      v['maxEmitterWavelength'] = true;
    }
    if (!UIUtils.validateNumber(minEmitterWavelength, 0, null, 2) && minEmitterWavelength !== "") {
      v['minEmitterWavelength'] = true;
    }

    if (maxSetupFlux && minSetupFlux) {
      if (!UIUtils.validateNumber(fluxTargetValue, 0, null, 2) ||
         (+fluxTargetValue > +maxSetupFlux || +fluxTargetValue < +minSetupFlux)) {
        v['fluxTargetValue'] = true;
      }
      if (!UIUtils.validateNumber(fluxTargetOffsetValue, 0, null, 2) ||
      (+(+fluxTargetValue + (+fluxTargetOffsetValue)).toFixed(2) > +maxSetupFlux || +(+fluxTargetValue - (+fluxTargetOffsetValue)).toFixed(2) < +minSetupFlux)) {
        v['fluxTargetOffsetValue'] = true;
      }
    }
    if (maxEmitterFlux && minEmitterFlux) {
      if (!UIUtils.validateNumber(fluxTargetValue, 0, null, 2) ||
         (+fluxTargetValue > +maxEmitterFlux || +fluxTargetValue < +minEmitterFlux)) {
        v['fluxTargetValue'] = true;
      }
      if (!UIUtils.validateNumber(fluxTargetOffsetValue, 0, null, 2) ||
      (+(+fluxTargetValue + (+fluxTargetOffsetValue)).toFixed(2) > +maxEmitterFlux || +(+fluxTargetValue - (+fluxTargetOffsetValue)).toFixed(2) < +minEmitterFlux)) {
        v['fluxTargetOffsetValue'] = true;
      }
      if( +maxSetupFlux > +maxEmitterFlux || +maxSetupFlux < +minEmitterFlux){
        v['maxSetupFlux'] = true;
      }
      if( +minSetupFlux > +maxEmitterFlux || +minSetupFlux < +minEmitterFlux){
        v['minSetupFlux'] = true;
      }
    }


    if (maxSetupWavelength && minSetupWavelength) {
      if (!UIUtils.validateNumber(wavelengthTargetValue, 0, null, 2)  ||
          (+wavelengthTargetValue > +maxSetupWavelength || +wavelengthTargetValue < +minSetupWavelength)) {
        v['wavelengthTargetValue'] = true;
      }
      if (!UIUtils.validateNumber(wavelengthTargetOffsetValue, 0, null, 2)  ||
      (+(+wavelengthTargetValue + (+wavelengthTargetOffsetValue)).toFixed(2) > +maxSetupWavelength || +(+wavelengthTargetValue - (+wavelengthTargetOffsetValue)).toFixed(2) < +minSetupWavelength)) {
        v['wavelengthTargetOffsetValue'] = true;
      }
    }
    if (maxEmitterWavelength && minEmitterWavelength) {
      if (!UIUtils.validateNumber(wavelengthTargetValue, 0, null, 2)  ||
          (+wavelengthTargetValue > +maxEmitterWavelength || +wavelengthTargetValue < +minEmitterWavelength)) {
        v['wavelengthTargetValue'] = true;
      }
      if (!UIUtils.validateNumber(wavelengthTargetOffsetValue, 0, null, 2)  ||
      (+(+wavelengthTargetValue + (+wavelengthTargetOffsetValue)).toFixed(2) > +maxEmitterWavelength || +(+wavelengthTargetValue - (+wavelengthTargetOffsetValue)).toFixed(2) < +minEmitterWavelength)) {
        v['wavelengthTargetOffsetValue'] = true;
      }
      if( +maxSetupWavelength > +maxEmitterWavelength || +maxSetupWavelength < +minEmitterWavelength){
        v['maxSetupWavelength'] = true;
      }
      if( +minSetupWavelength > +maxEmitterWavelength || +minSetupWavelength < +minEmitterWavelength){
        v['minSetupWavelength'] = true;
      }
    }
    if (minEmitterVf && maxEmitterVf) {
      if( +maxSetupVf > +maxEmitterVf || +maxSetupVf < +minEmitterVf){
        v['maxSetupVf'] = true;
      }
      if( +minSetupVf > +maxEmitterVf || +minSetupVf < +minEmitterVf){
        v['minSetupVf'] = true;
      }
    }

    setValid(v);
    if (dirty && Object.keys(v).length === 0 && saveStatusFlag) {
      appCtxRef.setUi({...appCtxRef.ui, unsavedActionOn: UnsavedActionEnum.EXIST});
      setUnsavedActionOn(true);
    } else {
      appCtxRef.setUi({...appCtxRef.ui, unsavedActionOn: null});
    }


  }, [saveStatusFlag,dirty && deltaX, numberOfPixelsPerFixture, ledsNum, maxSetupFlux, 
    channel?.color?.colorType,
    minSetupFlux, maxSetupVf, minSetupVf, maxSetupWavelength, 
    minSetupWavelength, fluxTargetValue, fluxTargetOffsetValue, wavelengthTargetValue, wavelengthTargetOffsetValue,
    maxEmitterFlux, minEmitterFlux, maxEmitterVf, minEmitterVf, minEmitterWavelength, maxEmitterWavelength,
    diverseBinsChromaticity, diverseBinsFlux, diverseBinsWavelength]);


  const save = useCallback(() => {
    const r = [{
      "id": props.channel?.id, 
      "inUse": true,
      "product": {
          "id": props?.product?.id
      },
      "color": {
          "id": props.channel?.color?.id
      },
      "properties": [

            {
              "name": "numberOfPixelsPerFixture",
              "value": '' + numberOfPixelsPerFixture,
              "category": "basic"
          },
          {
            "name": "numberOfEmittersPerPixel",
            "value": '' +ledsNum,
            "category": "basic"
          },
          {
              "name": "deltaX",
              "value": '' + deltaX,
              "category": "basic"
          },
          {
              "name": "maxSetupFlux",
              "value": '' + maxSetupFlux,
              "category": "basic"
          },
          {
              "name": "minSetupFlux",
              "value": '' + minSetupFlux,
              "category": "basic"
          },
          {
              "name": "maxEmitterFlux",
              "value": '' + maxEmitterFlux,
              "category": "basic"
          },
          {
              "name": "minEmitterFlux",
              "value": '' + minEmitterFlux,
              "category": "basic"
          },
          {
              "name": "maxSetupVf",
              "value": '' + maxSetupVf,
              "category": "basic"
          },
          {
              "name": "minSetupVf",
              "value": '' + minSetupVf,
              "category": "basic"
          },
          {
              "name": "maxEmitterVf",
              "value": '' + maxEmitterVf,
              "category": "basic"
          },
          {
              "name": "minEmitterVf",
              "value": '' + minEmitterVf,
              "category": "basic"
          },
          {
              "name": "maxSetupWavelength",
              "value": '' + maxSetupWavelength,
              "category": "basic"
          },
          {
              "name": "minSetupWavelength",
              "value": '' + minSetupWavelength,
              "category": "basic"
          },
          {
              "name": "maxEmitterWavelength",
              "value": '' + maxEmitterWavelength,
              "category": "basic"
          },
          {
              "name": "minEmitterWavelength",
              "value": '' + minEmitterWavelength,
              "category": "basic"
          },
          {
              "name": "targetWavelength",
              "value": '' + wavelengthTargetValue,
              "category": "basic"
          },
          {
              "name": "offsetWavelength",
              "value": '' + wavelengthTargetOffsetValue,
              "category": "basic"
          },
          {
              "name": "targetFlux",
              "value": '' + fluxTargetValue,
              "category": "basic"
          },
          {
              "name": "offsetFlux",
              "value": '' + fluxTargetOffsetValue,
              "category": "basic"
          },
          {
              "name": "diverseBinsChromaticity",
              "value": '' + diverseBinsChromaticity,
              "category": "basic"
          },
          {
              "name": "diverseBinsFlux",
              "value": '' + diverseBinsFlux,
              "category": "basic"
          },
          {
              "name": "diverseBinsWavelength",
              "value": '' + diverseBinsWavelength,
              "category": "basic"
          },
          {
              "name": "voltageStatusFlag",
              "value": '' + voltageStatusFlag,
              "category": "basic"
          }
      ]
    }]


    const sortOrderProperty = props.channel?.properties?.find((prop:any) => prop?.name === 'sortOrder');
    if ( sortOrderProperty !== undefined ) {
      r[0].properties.push({ name: 'sortOrder', value: sortOrderProperty.value, category: "basic" });
    }

    ProductsService.updateChannel(r).then((r: any) => {
      appCtxRef.setUi({...appCtxRef.ui, unsavedActionOn: null});
      ProductsService.getChannel(props.channel?.id).then((resp: any) => {
        setChannel(resp.data);

        if (props.updateChannel) {
          props.updateChannel(resp.data);
        }
        loadProperties(resp?.data?.properties);
        setValid({});
      });
    });
  }, [appCtxRef, props, deltaX, numberOfPixelsPerFixture, ledsNum,
    diverseBinsChromaticity, diverseBinsFlux, diverseBinsWavelength, voltageStatusFlag,
    maxSetupFlux, minSetupFlux, maxSetupVf, minSetupVf, maxSetupWavelength, minSetupWavelength, 
    maxEmitterFlux, minEmitterFlux, maxEmitterVf, minEmitterVf, minEmitterWavelength, maxEmitterWavelength,
    wavelengthTargetValue, wavelengthTargetOffsetValue, fluxTargetValue, fluxTargetOffsetValue, loadProperties]);

  useEffect(() => {

    const fmin = [];
    const fmax = [];
    const wmin = [];
    const wmax = [];
    const vmin = [];
    const vmax = [];

    if (props.flux && props.flux.length) {
      for (const f of props.flux) {
        fmin.push(+f.minValue);
        fmax.push(+f.maxValue);
      }
      fmin.sort((a, b) => a - b);
      fmax.sort((a, b) => a - b);
    }
    if (props.wavelength && props.wavelength.length) {
      for (const w of props.wavelength) {
        wmin.push(+w.minValue);
        wmax.push(+w.maxValue);
      }
      wmin.sort((a, b) => a - b);
      wmax.sort((a, b) => a - b);
    }
    if (props.voltage && props.voltage.length) {
      for (const v of props.voltage) {
        vmin.push(+v.minValue);
        vmax.push(+v.maxValue);
      }
      vmin.sort((a, b) => a - b);
      vmax.sort((a, b) => a - b);
    }

    setFluxMinValues(fmin);
    setFluxMaxValues(fmax);
    setWavelengthMinValues(wmin);
    setWavelengthMaxValues(wmax);
    setVoltageMinValues(vmin);
    setVoltageMaxValues(vmax);
  
    setFlux(props.flux);
    setVoltage(props.voltage);
    setWavelength(props.wavelength);

    if (!props.channel || !props.channel?.color) {
      return;
    }
    setChannel(props.channel);
    loadProperties(props.channel?.properties);
  }, [props.flux, props.voltage, props.wavelength, props.channel, loadProperties]);

  useEffect(() => {    
    const d = 
      !(+deltaX === +deltaXBck &&
      +numberOfPixelsPerFixture === +numberOfPixelsPerFixtureBck && 
      +ledsNum === +ledsNumBck && 
      +maxSetupFlux === +maxSetupFluxBck &&
      +minSetupFlux === +minSetupFluxBck &&
      maxEmitterFlux === maxEmitterFluxBck &&
      minEmitterFlux === minEmitterFluxBck &&
      +fluxTargetValue === +fluxTargetValueBck &&
      +fluxTargetOffsetValue === +fluxTargetOffsetValueBck &&
      +maxSetupVf === +maxSetupVfBck &&
      +minSetupVf === +minSetupVfBck &&
      maxEmitterVf === maxEmitterVfBck &&
      minEmitterVf === minEmitterVfBck &&
      +maxSetupWavelength === +maxSetupWavelengthBck && 
      +minSetupWavelength === +minSetupWavelengthBck &&
      maxEmitterWavelength === maxEmitterWavelengthBck && 
      minEmitterWavelength === minEmitterWavelengthBck &&
      +wavelengthTargetValue === +wavelengthTargetValueBck && 
      +wavelengthTargetOffsetValue === +wavelengthTargetOffsetValueBck && 
      +diverseBinsChromaticity === +diverseBinsChromaticityBck && 
      +diverseBinsFlux === +diverseBinsFluxBck && 
      voltageStatusFlag === voltageStatusFlagBck &&
      +diverseBinsWavelength === +diverseBinsWavelengthBck);
    setDirty(d);

    let s =
      (+numberOfPixelsPerFixture !== +numberOfPixelsPerFixtureBck && 
      +ledsNum !== +ledsNumBck && 
      +maxSetupFlux !== +maxSetupFluxBck &&
      +minSetupFlux !== +minSetupFluxBck &&
      maxEmitterFlux !== maxEmitterFluxBck &&
      minEmitterFlux !== minEmitterFluxBck &&
      +fluxTargetValue !== +fluxTargetValueBck &&
      +fluxTargetOffsetValue !== +fluxTargetOffsetValueBck);
    if (channel?.color?.colorType === APP_COLOR_TYPES.WAVELENGTH){
      s = (s && (+maxSetupWavelength !== +maxSetupWavelengthBck && 
      +minSetupWavelength !== +minSetupWavelengthBck &&
      maxEmitterWavelength !== maxEmitterWavelengthBck && 
      minEmitterWavelength !== minEmitterWavelengthBck &&
      +wavelengthTargetValue !== +wavelengthTargetValueBck && 
      +wavelengthTargetOffsetValue !== +wavelengthTargetOffsetValueBck));
    }
      if (!props.channel?.properties || props.channel?.properties?.length === 1){
        if (voltageStatusFlag !== voltageStatusFlagBck ){
          (+maxSetupVf !== +maxSetupVfBck &&
              +minSetupVf !== +minSetupVfBck &&
              maxEmitterVf !== maxEmitterVfBck &&
              minEmitterVf !== minEmitterVfBck) ? setsaveStatusFlag(s) : setsaveStatusFlag(false);          
        } else {
          setsaveStatusFlag(s);
        }
      } else {
        setsaveStatusFlag(true);
      }   
    
  }, [
    deltaX, 
    numberOfPixelsPerFixture, 
    ledsNum,
    maxSetupFlux, 
    minSetupFlux, 
    fluxTargetValue,
    fluxTargetOffsetValue,
    maxSetupVf, 
    minSetupVf, 
    maxSetupWavelength, 
    minSetupWavelength, 
    wavelengthTargetValue,
    wavelengthTargetOffsetValue,
    deltaXBck, 
    numberOfPixelsPerFixtureBck, 
    ledsNumBck,
    maxSetupFluxBck, 
    minSetupFluxBck, 
    fluxTargetValueBck,
    fluxTargetOffsetValueBck,
    maxSetupVfBck, 
    minSetupVfBck, 
    maxSetupWavelengthBck, 
    minSetupWavelengthBck,
    wavelengthTargetValueBck,
    wavelengthTargetOffsetValueBck,
    diverseBinsChromaticity, diverseBinsFlux, diverseBinsWavelength,
    diverseBinsChromaticityBck, diverseBinsFluxBck, diverseBinsWavelengthBck,
    maxEmitterFlux, minEmitterFlux, maxEmitterVf, minEmitterVf, minEmitterWavelength, maxEmitterWavelength,
    maxEmitterFluxBck, minEmitterFluxBck, maxEmitterVfBck, minEmitterVfBck, minEmitterWavelengthBck, maxEmitterWavelengthBck,
    voltageStatusFlag, voltageStatusFlagBck]);

    useEffect(() => {
      if (unsavedActionOn && appCtxRef.ui.unsavedActionOn === UnsavedActionEnum.SAVE) {
        const r = [{
          "id": props.channel?.id, 
          "inUse": true,
          "product": {
              "id": props?.product?.id
          },
          "color": {
              "id": props.channel?.color?.id
          },
          "properties": [
    
                {
                  "name": "numberOfPixelsPerFixture",
                  "value": '' +numberOfPixelsPerFixture,
                  "category": "basic"
              },
              {
                "name": "numberOfEmittersPerPixel",
                "value": '' +ledsNum,
                "category": "basic"
              },
              {
                  "name": "deltaX",
                  "value": '' + deltaX,
                  "category": "basic"
              },
              {
                  "name": "maxSetupFlux",
                  "value": '' + maxSetupFlux,
                  "category": "basic"
              },
              {
                  "name": "minSetupFlux",
                  "value": '' + minSetupFlux,
                  "category": "basic"
              },
              {
                  "name": "maxEmitterFlux",
                  "value": '' + maxEmitterFlux,
                  "category": "basic"
              },
              {
                  "name": "minEmitterFlux",
                  "value": '' + minEmitterFlux,
                  "category": "basic"
              },
              {
                  "name": "maxSetupVf",
                  "value": '' + maxSetupVf,
                  "category": "basic"
              },
              {
                  "name": "minSetupVf",
                  "value": '' + minSetupVf,
                  "category": "basic"
              },
              {
                  "name": "maxEmitterVf",
                  "value": '' + maxEmitterVf,
                  "category": "basic"
              },
              {
                  "name": "minEmitterVf",
                  "value": '' + minEmitterVf,
                  "category": "basic"
              },
              {
                  "name": "maxSetupWavelength",
                  "value": '' + maxSetupWavelength,
                  "category": "basic"
              },
              {
                  "name": "minSetupWavelength",
                  "value": '' + minSetupWavelength,
                  "category": "basic"
              },
              {
                  "name": "maxEmitterWavelength",
                  "value": '' + maxEmitterWavelength,
                  "category": "basic"
              },
              {
                  "name": "minEmitterWavelength",
                  "value": '' + minEmitterWavelength,
                  "category": "basic"
              },
              {
                  "name": "targetWavelength",
                  "value": '' + wavelengthTargetValue,
                  "category": "basic"
              },
              {
                  "name": "offsetWavelength",
                  "value": '' + wavelengthTargetOffsetValue,
                  "category": "basic"
              },
              {
                  "name": "targetFlux",
                  "value": '' + fluxTargetValue,
                  "category": "basic"
              },
              {
                  "name": "offsetFlux",
                  "value": '' + fluxTargetOffsetValue,
                  "category": "basic"
              },
              {
                  "name": "diverseBinsChromaticity",
                  "value": '' + diverseBinsChromaticity,
                  "category": "basic"
              },
              {
                  "name": "diverseBinsFlux",
                  "value": '' + diverseBinsFlux,
                  "category": "basic"
              },
              {
                  "name": "diverseBinsWavelength",
                  "value": '' + diverseBinsWavelength,
                  "category": "basic"
              },
              {
                  "name": "voltageStatusFlag",
                  "value": '' + voltageStatusFlag,
                  "category": "basic"
              }
          ]
        }]

        const sortOrderProperty = props.channel?.properties?.find((prop:any) => prop?.name === 'sortOrder');
        if ( sortOrderProperty !== undefined ) {
          r[0].properties.push({ name: 'sortOrder', value: sortOrderProperty.value, category: "basic" });
        }
    
        ProductsService.updateChannel(r).then((r: any) => {
        });
        appCtxRef.setUi({...appCtxRef.ui, unsavedActionOn: null});
        setUnsavedActionOn(null);
      }
    }, [appCtxRef, unsavedActionOn,props,
      deltaX, 
      numberOfPixelsPerFixture, 
      ledsNum,
      maxSetupFlux, 
      minSetupFlux, 
      fluxTargetValue,
      fluxTargetOffsetValue,
      maxSetupVf, 
      minSetupVf, 
      maxSetupWavelength, 
      minSetupWavelength, 
      wavelengthTargetValue,
      voltageStatusFlag,
      maxEmitterFlux, minEmitterFlux, maxEmitterVf, minEmitterVf, minEmitterWavelength, maxEmitterWavelength,
      diverseBinsChromaticity, diverseBinsFlux, diverseBinsWavelength,
      wavelengthTargetOffsetValue,]);

      const setActive = useCallback((fcn: any, min1: any, min2: any, max1: any, max2: any) => {
        if (props[fcn]) {
          props[fcn]({
            min: (min1 > min2 ? min1 : min2),
            max: (max1 > max2 ? max2 : max1)  
          });
        }
      }, [props]);

      useEffect (() => {
        let fMinUnit: any = '';
        let fMaxUnit: any = '';
        let wMinUnit: any = '';
        let wMaxUnit: any = '';

        if (flux && flux.length ) {
          for (const f of flux) {
            if (minEmitterFlux !== '' && +minEmitterFlux === +f.minValue){
              fMinUnit = f.unit;
            }
            if (maxEmitterFlux !== '' && +maxEmitterFlux === +f.maxValue){
              fMaxUnit = f.unit;
            }
          }
        }
        if (wavelength && wavelength.length) {
          for (const w of wavelength) {
            if (minEmitterWavelength !== '' && +minEmitterWavelength === +w.minValue){
              wMinUnit = w.unit;
            }
            if (maxEmitterWavelength !== '' && +maxEmitterWavelength === +w.maxValue){
              wMaxUnit = w.unit;
            }
          }
        }
        setminFluxUnit(fMinUnit);
        setmaxFluxUnit(fMaxUnit);
        setminWavelengthUnit(wMinUnit);
        setmaxWavelengthUnit(wMaxUnit);

      }, [minEmitterFlux, maxEmitterFlux, minEmitterWavelength, maxEmitterWavelength]);


  return (
    <div className='app-product-channel-details-common-properties'>

      {(dirty || Object.keys(valid).length > 0) && <div className='app-w-100 mb10'>
        {saveStatusFlag && Object.keys(valid).length === 0  &&  <button className='app-btn-secondary p5 pl10 pr10 ml10 mt10' onClick={() => {save()}}>
          Save
        </button>}
        <button className='app-btn-neutral p5 pl10 pr10 ml5 mt10' onClick={() => {cancel()}}>
          Cancel
        </button>
      </div>}

      {channel && <div className='app-product-channel-details-common-properties-block' >
        <h6 className='app-text-white'><b>_</b></h6>
        <div className='app-w-50 pr10'>
          <p className='mt5'><b>LEDs Per Pixel</b></p>
          <input type='number'
          className={valid['ledsNum'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
          placeholder='LEDs Per Pixel' value={ledsNum} onChange={(event: any) => {setLedsNum(event.target.value);}} />
        </div>
        <div className='app-w-50 pr10'>
          <p className='mt5'><b>Number Of Pixels</b></p>
          <input type='number' 
          className={valid['numberOfPixelsPerFixture'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
           placeholder='Number Of Pixels' value={numberOfPixelsPerFixture} onChange={(event: any) => {setnumberOfPixelsPerFixture(event.target.value);}} />
        </div>
        <div className='app-w-50 pr10'>  
          <p className='mt5'><b>Delta X</b></p>
          <input type='number' 
          className={valid['deltaX'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
           placeholder='Delta X' value={deltaX} onChange={(event: any) => {setDeltaX(event.target.value);}}/>
        </div>
        {channel?.color?.colorType !== APP_COLOR_TYPES.WAVELENGTH &&
          <div className='app-w-50 pr10'>
            <p className='mt5'><b>Diverse Chromaticity</b></p>
            <input type='number'  
            className={valid['diverseBinsChromaticity'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
                  placeholder='Diverse Chromaticity' 
              value={diverseBinsChromaticity} 
              onChange={(event: any) => {setdiverseBinsChromaticity(event.target.value);}} />
          </div>}
      </div>}



      <div className='app-product-channel-details-common-properties-block' >
        <h6><b>Forward Voltage Bins</b> 
          <select className='app-input ml15' value={voltageStatusFlag} onChange={(event: any) => {setvoltageStatusFlag(event.target.value); props.activeVoltageStatus(event.target.value);}}>
              <option value={1}>On</option>
              <option value={0}>Off</option>
          </select>
        </h6>
 
        <div className='app-w-50 pr10 mt0'>
        <p className='mt5 m0 app-w-100'><b>Minimum Forward Voltage Bin</b></p>  
          <select className='app-input app-width-100' 
            disabled={!voltageMinValues?.length || voltageStatusFlag === '0'} 
            value={minEmitterVf} 
            onChange={(event: any) => {
              setminEmitterVf(event.target.value);
              setActive('activeVoltage', +event.target.value, +minSetupVf, +maxSetupVf,  +maxEmitterVf);
              }} >
              <option value="">Select</option>
              {voltageMinValues && voltageMinValues.length && voltageMinValues.map(function(row: any, rowIdx: number) {
                return(<option key={rowIdx} value={row}>{row}</option>)
            })}
          </select>
        </div>
        <div className='app-w-50 pl10 mt0'>
        <p className='mt5 m0 app-w-100'><b>Minimum Forward Voltage</b></p>
          <input type='number' 
          className={valid['minSetupVf'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
            placeholder='Minimum Forward Voltage' 
            disabled={voltageStatusFlag === '0'}
            value={minSetupVf} 
            onChange={(event: any) => {
              setminSetupVf(event.target.value);
              setActive('activeVoltage', +event.target.value, +minEmitterVf, +maxSetupVf,  +maxEmitterVf);
              }} />
        </div>

  
        <div className='app-w-50 pr10 mt0'>
        <p className='mt5 m0 app-w-100'><b>Maximum Forward Voltage Bin</b></p>  
          <select className='app-input app-width-100' 
            disabled={!voltageMaxValues?.length || voltageStatusFlag === '0'} 
            value={maxEmitterVf} 
            onChange={(event: any) => {
              setmaxEmitterVf(event.target.value);
              setActive('activeVoltage', +minEmitterVf, +minSetupVf, +maxSetupVf, +event.target.value );
              }} >
              <option value="">Select</option>
              {voltageMaxValues && voltageMaxValues.length && voltageMaxValues.map(function(row: any, rowIdx: number) {
                return(<option key={rowIdx} value={row}>{row}</option>)
            })}
          </select>
        </div>
        <div className='app-w-50 pl10 mt0'>
        <p className='mt5 m0 app-w-100'><b>Maximum Forward Voltage</b></p>
          <input type='number' 
          className={valid['maxSetupVf'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
             placeholder='Maximum Forward Voltage' 
             disabled={voltageStatusFlag === '0'}
            value={maxSetupVf} 
            onChange={(event: any) => {
              setmaxSetupVf(event.target.value);
              setActive('activeVoltage', +minEmitterVf, +minSetupVf, +maxEmitterVf, +event.target.value );
              }} />
        </div>

      </div>



      <div className='app-product-channel-details-common-properties-block' >
        <h6><b>Flux Bins</b></h6>
      
        <div className='app-w-50 pr10 mt0'>
        <p className='mt5 m0 app-w-100'><b>Minimum Flux Bin</b></p>  
          <select className='app-input app-width-100' 
            disabled={!fluxMinValues?.length} 
            value={minEmitterFlux} 
            onChange={(event: any) => {
              setminEmitterFlux(event.target.value); 
              setActive('activeFlux', +event.target.value, +minSetupFlux,  +maxSetupFlux, +maxEmitterFlux);
              }} >
              <option value="">Select</option>
              {fluxMinValues && fluxMinValues.length && fluxMinValues.map(function(row: any, rowIdx: number) {
                return(<option key={rowIdx} value={row}>{row}</option>)
            })}
          </select>
        </div>
        <div className='app-w-50 pl10 mt0'>
        <p className='mt5 m0 app-w-100'><b>Minimum Flux</b></p>
          <div className='app-product-channel-details-common-properties-block-item'>
            <input type='number'
            className={valid['minSetupFlux'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
            placeholder='Minimum Flux' 
            value={minSetupFlux} 
            onChange={(event: any) => {
              setminSetupFlux(event.target.value); 
              setActive('activeFlux', +event.target.value, +minEmitterFlux,  +maxSetupFlux, +maxEmitterFlux);
              }} />
            <span>{minFluxUnit}</span>
          </div>    
        </div>

        <div className='app-w-50 pr10 mt0'>
          <p className='mt5 m0 app-w-100'><b>Maximum Flux Bin</b></p>
          <select className='app-input app-width-100' 
            disabled={!fluxMaxValues?.length} 
            value={maxEmitterFlux} 
            onChange={(event: any) => {
              setmaxEmitterFlux(event.target.value); 
              setActive('activeFlux', +minSetupFlux, +minEmitterFlux,  +maxSetupFlux, +event.target.value);
              }} >
              <option value="">Select</option>
              {fluxMaxValues && fluxMaxValues.length && fluxMaxValues.map(function(row: any, rowIdx: number) {
                return(<option key={rowIdx} value={row}>{row}</option>)
            })}
          </select>
        </div>
        <div className='app-w-50 pl10 mt0'>
          <p className='mt5 m0 app-w-100'><b>Maximum Flux</b></p>
          <div className='app-product-channel-details-common-properties-block-item'>
            <input type='number' 
            className={valid['maxSetupFlux'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
            placeholder='Maximum Flux' 
            value={maxSetupFlux} 
            onChange={(event: any) => {
              setmaxSetupFlux(event.target.value); 
              setActive('activeFlux', +minSetupFlux, +minEmitterFlux,  +maxEmitterFlux, +event.target.value);
              }} />
            <span>{maxFluxUnit}</span>
          </div>    
        </div>

        
        <div className='app-w-50 pr10 mt0'>
          <p className='mt15 m0 app-w-100'><b>Target</b></p>
          <input type='number'  
          className={valid['fluxTargetValue'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
                placeholder='Target' 
            value={fluxTargetValue} 
            onChange={(event: any) => {setFluxTargetValue(event.target.value);}} />
        </div>
        <div className='app-w-50 pl10 mt0'>
          <p className='mt15 m0 app-w-100'><b>Offset</b></p>
          <input type='number'  
          className={valid['fluxTargetOffsetValue'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
               placeholder='Offset' 
            value={fluxTargetOffsetValue} 
            onChange={(event: any) => {setFluxTargetOffsetValue(event.target.value);}} />
        </div>
        <div className='m0 app-w-100'>
          <p>*Target + Offset must be in Max-Min range</p>
        </div>

        <div className='app-w-50 pr10 mt0'>
          <p className='mt15 m0 app-w-100'><b>Diverse Flux</b></p>
          <input type='number'  
          className={valid['diverseBinsFlux'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
                placeholder='Diverse Flux' 
            value={diverseBinsFlux} 
            onChange={(event: any) => {setdiverseBinsFlux(event.target.value);}} />
        </div>
      
      </div>

      {channel?.color?.colorType === APP_COLOR_TYPES.WAVELENGTH && 
      <div className='app-product-channel-details-common-properties-block' >
        <h6><b>Wavelength Bins</b></h6>
        
        <div className='app-w-50 pr10 mt0'>
        <p className='mt5 m0 app-w-100'><b>Minimum Wavelength Bin</b></p>  
          <select className='app-input app-width-100' 
            disabled={!wavelengthMinValues?.length} 
            value={minEmitterWavelength} 
            onChange={(event: any) => {
              setminEmitterWavelength(event.target.value);
              setActive('activeWavelength', +event.target.value, +minSetupWavelength,  +maxSetupWavelength, +maxEmitterWavelength);
              }} >
              <option value="">Select</option>
              {wavelengthMinValues && wavelengthMinValues.length && wavelengthMinValues.map(function(row: any, rowIdx: number) {
                return(<option key={rowIdx} value={row}>{row}</option>)
            })}
          </select>
        </div>
        <div className='app-w-50 pl10 mt0'>
          <p className='mt5 m0 app-w-100'><b>Minimum Wavelength</b></p>
          <div className='app-product-channel-details-common-properties-block-item'>
            <input type='number' 
            className={valid['minSetupWavelength'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
            placeholder='Minimum Wavelength' 
            value={minSetupWavelength} 
            onChange={(event: any) => {
              setminSetupWavelength(event.target.value);
              setActive('activeWavelength', +event.target.value, +minEmitterWavelength,  +maxSetupWavelength, +maxEmitterWavelength);
              }} />
            <span>{minWavelengthUnit}</span>
          </div>
        </div>
   
        <div className='app-w-50 pr10 mt0'>
        <p className='mt5 m0 app-w-100'><b>Maximum Wavelength Bin</b></p>  
          <select className='app-input app-width-100' 
            disabled={!wavelengthMaxValues?.length} 
            value={maxEmitterWavelength} 
            onChange={(event: any) => {
              setmaxEmitterWavelength(event.target.value);
              setActive('activeWavelength', +minEmitterWavelength, +minSetupWavelength,  +event.target.value, +maxSetupWavelength);
              }} >
              <option value="">Select</option>
              {wavelengthMaxValues && wavelengthMaxValues.length && wavelengthMaxValues.map(function(row: any, rowIdx: number) {
                return(<option key={rowIdx} value={row}>{row}</option>)
            })}
          </select>
        </div>
        <div className='app-w-50 pl10 mt0'>
          <p className='mt5 m0 app-w-100'><b>Maximum Wavelength</b></p>
          <div className='app-product-channel-details-common-properties-block-item'>
            <input type='number' 
            className={valid['maxSetupWavelength'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
            placeholder='Maximum Wavelength' 
            value={maxSetupWavelength} 
            onChange={(event: any) => {
              setmaxSetupWavelength(event.target.value);
              setActive('activeWavelength', +minEmitterWavelength, +minSetupWavelength,  +event.target.value, +maxEmitterWavelength);
              }} />
            <span>{maxWavelengthUnit}</span>
          </div>
        </div>

        
        <div className='app-w-50 pr10 mt0'>
        <p className='mt15 m0 app-w-100'><b>Target</b></p>
        <input type='number' 
          className={valid['wavelengthTargetValue'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
                  placeholder='Target' 
            value={wavelengthTargetValue} 
            onChange={(event: any) => {setWavelengthTargetValue(event.target.value);}} />
        </div>
        <div className='app-w-50 pl10 mt0'>
        <p className='mt15 m0 app-w-100'><b>Offset</b></p>
          <input type='number' 
          className={valid['wavelengthTargetOffsetValue'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
                   placeholder='Offset' 
            value={wavelengthTargetOffsetValue} 
            onChange={(event: any) => {setWavelengthTargetOffsetValue(event.target.value);}} />
        </div>
        <div className='m0 app-w-100'>
          <p>*Target + Offset must be in Max-Min range</p>
        </div>

        <div className='app-w-50 pr10 mt0'>
          <p className='mt15 m0 app-w-100'><b>Diverse Wavelength</b></p>
          <input type='number'  
          className={valid['diverseBinsWavelength'] ? `app-input app-width-100 app-border-red` :  `app-input app-width-100`}
                placeholder='Diverse Wavelength' 
            value={diverseBinsWavelength} 
            onChange={(event: any) => {setdiverseBinsWavelength(event.target.value);}} />
        </div>

      </div>}
    </div>
  );
}
export default ProductChannelCommon;

