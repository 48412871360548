import { Logger } from '../../../utils/Logger';
import './ProductFolder.scss';

function ProductFolder(props: any) {

  Logger.debug('ProductFolder component render.');

  const clickItem = (item: any) => {
    if (props.itemClick) {
      props.itemClick(item);
    }
  }

  return (
    <div className='app-products-folder'>
      {props.products.length === 0 && <h3 className='app-text-center app-text-gray'>No Items</h3>}
      {props.products.map(function(row: any, rowIdx: number) {
        return(
          <div key={rowIdx} className='app-products-folder-item' onClick={() => {clickItem(row);}}>
            <div>
              <span>
                {!row?.product &&
                  <img className='mr10' src='/assets/img/icons/folder-black.svg' alt='' />
                }
                {row?.product &&
                  <img className='mr10' src='/assets/img/icons/sun-black.svg' alt='' />
                }
                {row?.name}
              </span>
            </div>
          </div>);
      })}
    </div>
  );
}
export default ProductFolder;

