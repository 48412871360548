import { forwardRef, useImperativeHandle, useState } from 'react';
import { APP_COLOR_TYPES } from '../../../core/AppConstants';
import { Logger } from '../../../utils/Logger';
import './ProductChannelShortlistedBins.scss';

const ProductChannelShortlistedBins = forwardRef((props: any, ref: any) => {

  Logger.debug('ProductChannelShortlistedBins component render.');

  const [data, setData] = useState<any>([]);

  useImperativeHandle(ref, () => ({

    refresh() {
      const flux = [];  
      if (props.activeFlux && props.flux?.length) {
        for (const it of props.flux) {
		  if ((it.minValue <= props.activeFlux?.min && props.activeFlux?.min < it.maxValue) 
				|| (it.minValue < props.activeFlux?.max && props.activeFlux?.max <= it.maxValue) 
				|| (props.activeFlux?.min < it.minValue && it.maxValue < props.activeFlux?.max)) {
            flux.push(it.name);
		  }
        }
      }

      const voltage = [];  
      if (props.activeVoltageStatus === '1') {
        if (props.activeVoltage && props.voltage?.length) {
          for (const it of props.voltage) {
            if ((it.minValue <= props.activeVoltage?.min && props.activeVoltage?.min < it.maxValue) 
				|| (it.minValue < props.activeVoltage?.max && props.activeVoltage?.max <= it.maxValue) 
				|| (props.activeVoltage?.min < it.minValue && it.maxValue < props.activeVoltage?.max)) {
              voltage.push(it.name);
            }
          }
        }
      } else {
        voltage.push(null);
      }

      const bins = [];
      if (props.channel?.color?.colorType !== APP_COLOR_TYPES.CHROMATICITY) {
        if (props.activeWavelength && props.wavelength?.length) {
          for (const it of props.wavelength) {
            if ((it.minValue <= props.activeWavelength?.min && props.activeWavelength?.min < it.maxValue) 
				|| (it.minValue < props.activeWavelength?.max && props.activeWavelength?.max <= it.maxValue) 
				|| (props.activeWavelength?.min < it.minValue && it.maxValue < props.activeWavelength?.max)) {
              bins.push(it.name);
            } 
          }
        }
      } else {
        if (props.activeBins && props.chromaticity?.length) {
          for (const it of props.chromaticity) {
            if (props.activeBins[it.id]) {
              bins.push(it.name);
            }
          }
        }
      }

      const d = [];
      for(const f of flux) {
        for(const b of bins) {
          for(const v of voltage) {
            d.push({
              b,
              f,
              v
            });
          }
        }
      }
      setData(d); 
    },
  }));


  return (
    <div className='app-product-channel-shortlisted-bins'>
      <h4 className='mb10'><b>Shortlisted Bins</b></h4>

      <div className='app-product-channel-shortlisted-bins-content'>
        {!data.length && <p className='app-text-gray'>No Items</p>}
        {data && data.length > 0 && data.map(function(row: any, rowIdx: number) {
          return(
          <p key={rowIdx}>
            <span className='mr10'>Flux: <b>{row.f}</b> </span>
            <span className='mr10'>{props.channel?.color?.colorType === APP_COLOR_TYPES.CHROMATICITY ? 'Chromaticity:' : 'Wavelength'} <b>{row.b}</b> </span>
            {props.activeVoltageStatus === '1' && <span className='mr10'>Voltage: <b>{row.v}</b> </span>}
          </p>)
        })}
      </div>
      
    </div>
  );
});
export default ProductChannelShortlistedBins;

