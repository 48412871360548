import axios from "axios";

export class ProductsService {

  static all() {
    return axios({
      method: 'get',
      url: '/api/product'
    });
  }


  static get(id: number) {
    return axios({
      method: 'get',
      url: '/api/product/' + id
    });
  }


  static save(data: any) {
    return axios({
      method: 'post',
      url: '/api/product',
      data
    });
  }


  static edit(data: any) {
    return axios({
      method: 'put',
      url: '/api/product/' + data.id,
      data
    });
  }


  static clone(id: number, parentId: number) {
    return axios({
      method: 'post',
      url: '/api/product/clone/' + id + '/' + parentId
    });
  }


  static getChannel(id: number) {
    return axios({
      method: 'get',
      url: '/api/channel/' + id
    });
  }


  static saveTargetPolygon(id: number, data: any) {
    return axios({
      method: 'post',
      url: '/api/channel/' + id + '/targetpolygon',
      data
    });
  }


  static saveChromaticityBinSubset(id: number, data: any) {
    return axios({
      method: 'post',
      url: '/api/channel/' + id + '/chromaticitybinsubset',
      data
    });
  }

  static saveChromaticityInnerSubset(id: number, data: any) {
    return axios({
      method: 'post',
      url: '/api/channel/' + id + '/chromaticityinnerbin',
      data
    });
  }

  static saveChromaticityInnerBinPosition(id: number, data: any) {
    return axios({
      method: 'post',
      url: '/api/channel/' + id + '/innerbinposition',
      data
    });
  }

  static retireChannel(id: any) {
    return axios({
      method: 'put',
      url: '/api/channel/retire',
      data : [{id}]
    });
  }

  static retirePCANumber(id: any) {
    return axios({
      method: 'put',
      url: '/api/product/retire/pca',
      data : [{id}]
    });
  }
  
  static retire(id: number) {
    return axios({
      method: 'delete',
      url: '/api/product/' + id
    });
  }

  static updateChannel(data: any) {
    return axios({
      method: 'post',
      url: '/api/channel/',
      data
    });
  }

  static saveChannel(data: any) {
    return axios({
      method: 'post',
      url: '/api/channel',
      data
    });
  }


  static getFlux(id: number) {
    return axios({
      method: 'get',
      url: '/api/color/' + id + '/flux'
    });
  }

  static saveFlux(data: any) {
    return axios({
      method: 'post',
      url: '/api/flux',
      data
    });
  }

  static retireFlux(id: number) {
    return axios({
      method: 'put',
      url: '/api/flux/retire',
      data : [{id}]
    });
  }

  static getForwardvoltage(id: number) {
    return axios({
      method: 'get',
      url: '/api/color/' + id + '/forwardvoltage'
    });
  }

  static retireForwardvoltage(id: number) {
    return axios({
      method: 'put',
      url: '/api/forwardvoltage/retire',
      data : [{id}]
    });
  }

  static saveForwardvoltage(data: any) {
    return axios({
      method: 'post',
      url: '/api/forwardvoltage',
      data
    });
  }

  static getChromaticity(id: number) {
    return axios({
      method: 'get',
      url: '/api/color/' + id + '/chromaticity'
    })
  }

  static saveChromaticity(data: any) {
    return axios({
      method: 'post',
      url: '/api/chromaticity',
      data
    });
  }

  static retireChromaticity(id: number) {
    return axios({
      method: 'put',
      url: '/api/chromaticity/retire',
      data : [{id}]
    });
  }

  static getWavelength(id: number) {
    return axios({
      method: 'get',
      url: '/api/color/' + id + '/wavelength'
    })
  }

  static saveWavelength(data: any) {
    return axios({
      method: 'post',
      url: '/api/wavelength',
      data
    });
  }

  static retireWavelength(id: number) {
    return axios({
      method: 'put',
      url: '/api/wavelength/retire',
      data : [{id}]
    });
  }

  static color(id: number) {
    return axios({
      method: 'get',
      url: '/api/manufacturer/' + id + '/color'
    });
  }

  static saveColor(data: any) {
    return axios({
      method: 'post',
      url: '/api/color',
      data
    });
  }

  static cloneColor(id: number, data: any) {
    return axios({
      method: 'post',
      url: '/api/color/' + id + '/clone',
      data
    });
  }

  static retireColor(id: number) {
    return axios({
      method: 'put',
      url: '/api/color/retire',
      data : [{id}]
    });
  }

  static deleteColor(id: number) {
    return axios({
      method: 'delete',
      url: '/api/color/' + id
    });
  }

}
