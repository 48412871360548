import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { APP_COLOR_TYPES } from '../../../core/AppConstants';
import { ManufacturersService } from '../../../services/ManufacturersService';
import { ProductsService } from '../../../services/ProductsService';
import { Logger } from '../../../utils/Logger';
import ChromacityGraph from '../../chromaticity/chromacity-graph/ChromacityGraph';
import { ModalInterface } from '../../ui-elements/modal/Modal';
import YesNoModal from '../../ui-elements/yes-no-modal/YesNoModal';
import ProductChannelShortlistedBins from '../product-channel-common-shortlisterd-bins/ProductChannelShortlistedBins';
import ProductChannelCommon from '../product-channel-common/ProductChannelCommon';
import './ProductChannelDetails.scss';

function ProductChannelDetails(props: any) {

  Logger.debug('ProductChannelDetails component render.');

  const [chromaticity, setChromaticity] = useState<any>({});
  const [channel, setChannel] = useState<any>({});
  const [flux, setFlux] = useState<any>({});
  const [voltage, setVoltage] = useState<any>({});
  const [wavelength, setWavelength] = useState<any>({});

  const [activeBins, setActiveBins] = useState<any>(null);
  const [activeFlux, setActiveFlux] = useState<any>(null);
  const [activeVoltage, setActiveVoltage] = useState<any>(null);
  const [activeVoltageStatus, setActiveVoltageStatus] = useState<any>(false);
  const [activeWavelength, setActiveWavelength] = useState<any>(null);

  const modalDelRef = useRef<ModalInterface>();
  const shortlistedRef = useRef<any>();

  useEffect(() => {
    axios
    .all([ManufacturersService.getChromaticity(props.channel?.color?.id), 
      ManufacturersService.getFlux(props.channel?.color?.id), 
      ManufacturersService.getForwardvoltage(props.channel?.color?.id), 
      ManufacturersService.getWavelength(props.channel?.color?.id), 
      ProductsService.getChannel(props.channel?.id)])
    .then(axios.spread((chromaticityResp, fluxResp, voltageResp, wavelengthResp, channelResp) => {
      setChromaticity(chromaticityResp.data);
      setFlux(fluxResp.data);
      setVoltage(voltageResp.data);
      setWavelength(wavelengthResp.data);
      setChannel(channelResp.data);
    }))
    .catch((error) => {
      // Handle any errors that occurred during the API calls
      console.error(error);
    });
  }, [props.channel]);
  
  const setActiveFluxFcn = useCallback((e: any) => {
    setActiveFlux(e);
  }, []);
  const setActiveVoltageFcn = useCallback((e: any) => {
    setActiveVoltage(e);
  }, []);
  const setActiveWavelengthFcn = useCallback((e: any) => {
    setActiveWavelength(e);
  }, []);

  const updateChannel = useCallback((e: any) => {
    setChannel(e);
    if (props.updateChannel) {
      props.updateChannel(e);
    }
  }, []);

  const delConfirm = useCallback(() => {
    ProductsService.retireChannel(props.channel?.id).then((resp: any) => {
      if (props.refresh) {
        props.refresh();
      }
      modalDelRef?.current?.open(false);
    }, () => {
    });
    
  }, [props]);
  
  
  useEffect(() => {
    shortlistedRef?.current.refresh();
  }, [
    activeBins,
    activeFlux,
    activeVoltage,
    activeWavelength,
    activeVoltageStatus
  ]);


  return (
    <div className='app-product-channel-details'>
      <div className='app-product-channel-details-left'>
        
        <div className='app-product-channel-details-container'>
          <h4 className='mb10'>
            <b>Channel Details:</b>
            <img className='app-product-channel-details-container-x' src='/assets/img/icons/x-black.svg' alt=''  onClick={($event) => {
              modalDelRef?.current?.open(true);$event.stopPropagation()}}/>  
          </h4>
          <h6 className=''><b>Color: <span className='app-text-secondary'>{props.channel?.color?.name}</span></b></h6>
          <p className=''>
            Manufacturer: <b className='mr15'>{props.channel?.color?.manufacturer?.name}</b> 
            12NC: <b className='mr15'>{props.channel?.color?.scmInternalId}</b>
            Order Code: <b>{props.channel?.color?.orderCode}</b>
          </p>
        </div>

        <div className='app-product-channel-details-container p0'>
          <ProductChannelCommon 
            flux={flux}
            voltage={voltage}
            wavelength={wavelength}
            channel={channel} 
            product={props?.product}
            updateChannel={updateChannel}
            activeFlux={setActiveFluxFcn}
            activeVoltage={setActiveVoltageFcn}
            activeWavelength={setActiveWavelengthFcn}
            activeVoltageStatus={setActiveVoltageStatus}>
          </ProductChannelCommon>
        </div>

        {channel?.color?.colorType === APP_COLOR_TYPES.CHROMATICITY && 
        <div className='app-product-channel-details-container'>
          <h4 className=''><b>Chromaticity </b></h4>
          <ChromacityGraph 
            chromaticity={chromaticity} 
            channel={channel} 
            product={props?.product}
            activeBins={(e: any) => {setActiveBins(e)}}>
          </ChromacityGraph>
        </div>}
        
      </div>

      <div className='app-product-channel-details-right'>
        <div className='app-product-channel-details-container app-product-channel-details-shortlisted'>     
          <ProductChannelShortlistedBins ref={shortlistedRef}
            chromaticity={chromaticity} 
            activeBins={activeBins}
            activeFlux={activeFlux}
            activeVoltage={activeVoltage}
            activeWavelength={activeWavelength}
            activeVoltageStatus={activeVoltageStatus}
            flux={flux}
            voltage={voltage}
            wavelength={wavelength}
            channel={channel} 
            product={props?.product}>
          </ProductChannelShortlistedBins>
        </div>
      </div>

      <YesNoModal ref={modalDelRef} yes={delConfirm} yesCloseDisabled={true}>
      </YesNoModal>

    </div>
  );
}
export default ProductChannelDetails;

