import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import AppCtx from '../../AppCtx';
import ProductChannels from '../../components/products/product-channels/ProductChannels';
import ProductFolder from '../../components/products/product-folder/ProductFolder';
import Loader from '../../components/ui-elements/loader/Loader';
import NavMap from '../../components/ui-elements/nav-map/NavMap';
import { ManufacturersService } from '../../services/ManufacturersService';
import { ProductsService } from '../../services/ProductsService';
import { Logger } from '../../utils/Logger';
import './Bining.scss';
import '../products/Products.scss';
import BiningCtx from './BiningCtx';
import BiningSelectInventory from '../../components/bining/bining-select-inventory/BiningSelectInventory';
import { BiningService } from '../../services/BiningService';
import BiningResult from '../../components/bining/bining-result/BiningResult';
import { APP_COLOR_TYPES, APP_FEATURES } from '../../core/AppConstants';
import YesNoModal from '../../components/ui-elements/yes-no-modal/YesNoModal';
import { QueryService } from '../../services/QueryService';
import { DateUtils } from '../../utils/DateUtils';
import axios from 'axios';
import { UIUtils } from '../../utils/UIUtils';

function Bining() {

  Logger.debug('Bining component render.');

  const DELIMITER_INVENTORY_SELECTION = '.====.';

  const modalNavMapRef = useRef<any>();
  const uploadFileRef = useRef<any>();

  const appCtxRef = useContext(AppCtx);

  const [UPDATE_PERMISSION] = useState<boolean>(appCtxRef?.session?.features[APP_FEATURES.AlgorithmBinningExecute]);
  const [READ_PERMISSION] = useState<boolean>(appCtxRef?.session?.features[APP_FEATURES.AlgorithmBinningExecute]);

  const [savedResult, setSavedResult] = useState<boolean>(false);
  const [numberOfSetups, setNumberOfSetups] = useState<any>(0);
  const [spoolSize, setSpoolSize] = useState<any>(0);
  const [comments, setComments] = useState<any>('');
  const [pcanum, setPcaNum] = useState<any>('');
  const [inventories, setInventories] = useState<any>({});

  const [loading, setLoading] = useState<any>(false);
  const [hideModileNav, setSideModileNav] = useState<boolean>(true);
  const [products, setProducts] = useState<any>([]);
  const [productsData, setProductsData] = useState<any>({});
  const [productSelected, setProducSelected] = useState<any>(null);
  const [channelSelected, setChannelSelected] = useState<any>(null);
  const [manufacturers, setManufacturers] = useState<any>([]);

  const [chromaticity, setChromaticity] = useState<any>([]);
  const [flux, setFlux] = useState<any>([]);
  const [wavelength, setWavelength] = useState<any>([]);
  const [voltage, setVoltage] = useState<any>([]);

  const [errTitle, setErrTitle] = useState<any>('');
  const modalErrRef = useRef<any>();

  const [result, setResult] = useState<any>(null);

  const [binningResStatus, setbinningResStatus] = useState<any>(false);

  const [valid, setValid] = useState<any>({});
  const [validInventory, setValidInventory] = useState<any>(true);


  const BiningCtxValue = {
    products, setProducts, productsData, setProductsData
  };

  const load = useCallback(() => {

    if (appCtxRef.core.products) {
      setProducts(appCtxRef.core.products);
      modalNavMapRef?.current?.load(appCtxRef.core.products);
      
      const timeoutRef = setTimeout(() => {
        setLoading(false);
      }, 200);
      return () => {
        clearTimeout(timeoutRef);
      };

    } else {
      ProductsService.all().then((resp: any) => {
        setProducts(resp.data);
        appCtxRef.setCore({...appCtxRef.core, products: resp.data});
        modalNavMapRef?.current?.load(resp.data);
        setLoading(false);
      });
    }
    
  }, [appCtxRef]);

  const reset = useCallback(() => {
    setResult(null);
    setNumberOfSetups(0);
    setSpoolSize(0);
    setComments('');
    setPcaNum(productSelected?.pcaNumbers.length > 0 ? productSelected?.pcaNumbers[0].id : 0);
    setInventories({});
  }, [productSelected]); 


  const onSelectProduct = useCallback((item: any) => {
    reset();

    if (!productSelected || item?.id !== productSelected?.id) {
      setChannelSelected(null);
    }

    if (item?.id && !productsData[item?.id]) {
      ProductsService.get(item?.id).then((resp: any) => {
        productsData[item?.id] = resp.data;
        const it = productsData[item?.id];
        it.products = item.products;
        setProducSelected(it);
        setSideModileNav(true);
        setProductsData(productsData);
        setPcaNum(it?.pcaNumbers.length > 0 ? it?.pcaNumbers[0].id : 0);
      });
    } else {
      const it = productsData[item?.id];
      if (it) {
        it.products = item.products;
      }
      setProducSelected(it);
      setSideModileNav(true);
      setPcaNum(it?.pcaNumbers.length > 0 ? it?.pcaNumbers[0].id : 0);
    }
  }, [productSelected, productsData, reset]);  

  const selectItem = useCallback((it: any) => {
    modalNavMapRef?.current?.select(it);
  }, []);
  
  useEffect(() => {
    if (appCtxRef.core.manufacturers) {
      setManufacturers(appCtxRef.core.manufacturers);
    } else {
      ManufacturersService.all().then((resp: any) => {
        setManufacturers(resp.data);
      });
    }
    load();
  }, [load, appCtxRef.core.manufacturers]);

  useEffect(() => {  
    if (!channelSelected) {
      return;
    }
    setFlux([]);
    setChromaticity([]);
    setWavelength([]);
    setVoltage([]);

    if (channelSelected?.color?.colorType === APP_COLOR_TYPES.CHROMATICITY) {
      axios
        .all([
          ManufacturersService.getBinningChromaticity(channelSelected?.color?.id, channelSelected?.id), 
          ManufacturersService.getBinningFlux(channelSelected?.color?.id, channelSelected?.id), 
          ProductsService.getChannel(channelSelected?.id), 
          ManufacturersService.getBinningForwardVoltage(channelSelected?.color?.id, channelSelected?.id)
        ]).then(axios.spread((chromaticityResp, fluxResp, channelResp, voltageResp) => {
          setChromaticity(chromaticityResp.data);
          setFlux(fluxResp.data);
          setVoltage(voltageResp.data);
          setWavelength([]);
          let vlt = [null];
          for (const p of channelResp?.data?.properties) {
            if (p?.name === 'voltageStatusFlag' && p?.value === '1') {
              vlt = voltageResp.data;
            }
          }    
          setVoltage(vlt);  
        }));
    } else if (channelSelected?.color?.colorType === APP_COLOR_TYPES.WAVELENGTH) {
      axios
        .all([
          ManufacturersService.getBinningWavelength(channelSelected?.color?.id, channelSelected?.id), 
          ManufacturersService.getBinningFlux(channelSelected?.color?.id, channelSelected?.id), 
          ProductsService.getChannel(channelSelected?.id), 
          ManufacturersService.getBinningForwardVoltage(channelSelected?.color?.id, channelSelected?.id)
        ]).then(axios.spread((wlResp, fluxResp, channelResp, voltageResp) => {
          setChromaticity([]);
          setFlux(fluxResp.data);
          setVoltage(voltageResp.data);
          setWavelength(wlResp.data);
          let vlt = [null];
          for (const p of channelResp?.data?.properties) {
            if (p?.name === 'voltageStatusFlag' && p?.value === '1') {
              vlt = voltageResp.data;
            }
          }    
          setVoltage(vlt);    
        })).catch((error) => {
          // Handle any errors that occurred during the API calls
          console.error(error);
        });
    }

  }, [channelSelected]);

  const onFileUpload = (event: any) => {
    try {
      if (event?.target?.files?.length > 0) {
        BiningService.upload(
          productSelected.id,
          event.target.files[0]
        ).then((resp: any) => {

          if(resp?.data?.spoolSize) {
            setSpoolSize(resp?.data?.spoolSize);
          }
          if(resp?.data?.numberOfSetups) {
            setNumberOfSetups(resp?.data?.numberOfSetups);
          }

          if (resp?.data?.inputInventories) {
            const newInventory: any =  {};
            for (const i of resp?.data?.inputInventories) {
              newInventory[i?.channelId + DELIMITER_INVENTORY_SELECTION + i?.flux?.id + DELIMITER_INVENTORY_SELECTION + (i?.waveLength?.id ? i?.waveLength?.id : 0) + DELIMITER_INVENTORY_SELECTION + (i?.chromaticity?.id ? i?.chromaticity?.id : 0) + DELIMITER_INVENTORY_SELECTION + (i?.forwardVoltage?.id ? i.forwardVoltage.id : 0)] = '' + i.inventoryCount;
            }
            setInventories(newInventory);
          }
        uploadFileRef.current.value = '';
          
        }, () => {
          setErrTitle('Uploaded Inventory File does not match with the product definition.');
          modalErrRef?.current?.open(true);
        });
      }
    } catch(err: any) {
    }
  }

  const download = () => {
    const inputInventories = [];
    for (const i of Object.keys(inventories)) {
      const k = i.split(DELIMITER_INVENTORY_SELECTION);

      if (k.length > 0) {
        if (+k[0] > 0) {
          const item :any = {
            channelId: +k[0],
            flux: {
              id: +k[1]
            },
            forwardVoltage: {
              id: +k[4]
            },
            inventoryCount: +inventories[i]
          };

          if (+k[2] > 0) {
            item['waveLength'] = {id: +k[2]};
          }
          if (+k[3] > 0) {
            item['chromaticity'] = {id: +k[3]};
          }
  
          inputInventories.push(item);
        }        
      }
    }

    const pca = pcanum ? +pcanum : productSelected?.pcaNumbers.length > 0 ? productSelected?.pcaNumbers[0].id : 0;
    
    const d = {
      productId: productSelected.id,
      pcaNumber: {
        id: pca
      },
      comments: comments,
      inputInventories,
      spoolSize: spoolSize,
      numberOfSetups: numberOfSetups
    };
    
    BiningService.download(productSelected?.id, d).then((resp: any) => {
      const url = URL.createObjectURL(new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const element = document.createElement('a');
      element.setAttribute('href', url);
      const date = new Date(); // Current date
      const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;

      element.setAttribute('download', 'Inventory File_' + formattedDate + '_' + productSelected?.name + '.xls');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }, () => {
      setErrTitle('Download failed.');
      modalErrRef?.current?.open(true);
    });   
  }

  const exportResult = () => {
    QueryService.export(null, null, null, result.id, null).then((resp: any) => {
      const url = URL.createObjectURL(new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const element = document.createElement('a');
      element.setAttribute('href', url);

      const date = new Date(); // Current date and time
      const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()} - ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')} ${DateUtils.getTZ().replaceAll('%2B','+')}`;

      const pca = result?.pcaNumber?.pcaNumber ? '_' + result?.pcaNumber?.pcaNumber  : '';

      element.setAttribute('download', formattedDate + '_' + productSelected?.name + pca + '.xls');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
  }

  const run = useCallback(() => {
    setLoading(true);

    const inputInventories = [];
    for (const i of Object.keys(inventories)) {
      const k = i.split(DELIMITER_INVENTORY_SELECTION);

      if (k.length > 0) {

        const item :any = {
          channelId: +k[0],
          flux: {
            id: +k[1]
          },
          forwardVoltage: {
            id: +k[4]
          },
          inventoryCount: +inventories[i]
        };

        if (+k[2] > 0) {
          item['waveLength'] = {id: +k[2]};
        }
        if (+k[3] > 0) {
          item['chromaticity'] = {id: +k[3]};
        }

        inputInventories.push(item);
      }
    }

    const pca = pcanum ? +pcanum : productSelected?.pcaNumbers.length > 0 ? productSelected?.pcaNumbers[0].id : 0;
    
    const d = {
      productId: productSelected.id,
      pcaNumber: {
        id: pca
      },
      comments: comments,
      inputInventories,
      spoolSize: spoolSize,
      numberOfSetups: numberOfSetups
    };
    
    BiningService.run(d).then((resp: any) => {
      setSavedResult(false);

      const tmp:any = {};
      if (resp.data.channelRecipes) {
        for (const it of resp.data.channelRecipes) {
          tmp[it.channelId] = it.binCode;
        }
      }
      
      setbinningResStatus(productSelected.channels.length === Object.keys(tmp).length);
      if(!(productSelected.channels.length === Object.keys(tmp).length)){
        if (resp.data.binningErrors.length) {
          let error = '';
          const errorMap:any = {};       
          for (const er of resp.data.binningErrors) {
            const { errorDescription, channelId } = er;
            if (!errorMap[errorDescription]) {
              errorMap[errorDescription]= [];
            }
            const channel = productSelected?.channels?.find((c:any) => c.id === channelId && c.color && c.color.name);
            errorMap[errorDescription].push(channel.color.name);
          }

          for (const e of Object.keys(errorMap)) {
            if (error.length === 0)
              error += `Binning action failed: ${e} (${errorMap[e].join(', ')}) `;
            else 
              error += `, ${e} (${errorMap[e].join(', ')}) `;
          }
          setErrTitle(error);  
        } else {
          setErrTitle('Binning action failed: The following channels might have insufficent inventory!');
        }
        throw new Error;
      }
      setResult(resp.data);

      setLoading(false);

    }).catch(() => {
        setLoading(false);
        modalErrRef?.current?.open(true);
    });
  }, [comments, numberOfSetups, spoolSize, productSelected, pcanum, inventories]);
  
  const save = () => {
    BiningService.save({
      "id": result?.id,
      "saved": true
   }).then(() => {
      setSavedResult(true);
    }, () => {
      setErrTitle('Binning result save failed.');
      modalErrRef?.current?.open(true);
    });
  }

  useEffect(() => {

    const v : any = {};

    if (!UIUtils.validateNumber(numberOfSetups, 1, null, 0)) {
      v['numberOfSetups'] = true;
    }
    if (!UIUtils.validateNumber(spoolSize, 1, null, 0)) {
      v['spoolSize'] = true;
    }

    setValid(v);

  }, [numberOfSetups, spoolSize]);
  
  if (!READ_PERMISSION) {
    return (
      <div className="app-products app-bining">
      <span className='app-no-permission-content'>
        You don't have permission to access this page.
        <br />
        Please contact your administrator.
      </span>
      </div>
    );
  }
  
  return (
    <BiningCtx.Provider value={BiningCtxValue}>
      <div className="app-products app-bining">
        
        <div className='app-products-content'>

          <p className='app-products-header-mobile'>
            <span onClick={() => {setSideModileNav(!hideModileNav)}}>
              <img src='/assets/img/icons/grid-black.svg' alt='' />
            </span>
          </p>

          {productSelected?.product && 
            <div className='app-products-header'>

              {UPDATE_PERMISSION && !result && 
              <div className='app-products-header-title app-bining-control'>
                <span className='app-products-header-title-text mb10'>
                  Binning Product: 
                  <span className='app-products-header-title-text-active'> {productSelected?.name}</span>
                </span>
                <input type="file" ref={uploadFileRef} className='app-bining-file-upload' onChange={onFileUpload} />
                <button className='app-btn-secondary' disabled={Object.keys(inventories).length === 0 || Object.keys(valid).length !== 0 || !validInventory} onClick={() => {run()}}>Run</button>
                <button className='app-btn-neutral' onClick={() => {reset()}}>Reset</button>
                <button className='app-btn-neutral' onClick={() => {uploadFileRef?.current?.click();}}>Upload</button>
                <button className='app-btn-neutral' disabled={!UIUtils.validateNumber(numberOfSetups, 0, null, 0) || !UIUtils.validateNumber(spoolSize, 0, null, 0) || !validInventory} onClick={() => {download();}}>Download</button>
              </div> }

              {result && 
              <div className='app-products-header-title app-bining-control'>
                <span className='app-products-header-title-text mb10'>
                  Recipe For Product: 
                  <span className='app-products-header-title-text-active'> {productSelected?.name}</span>                  
                </span>
                {!savedResult && binningResStatus && <button className='app-btn-secondary' onClick={() => {save()}}>Save</button>}
                {!savedResult && <button className='app-btn-neutral' onClick={() => {setResult(null)}}>Cancel</button>}
                {savedResult && <button className='app-btn-neutral' onClick={() => {reset()}}>Finish</button>}
                {binningResStatus && <button className='app-btn-neutral' onClick={() => {exportResult();}}>Export</button>}
              </div> }

              {result && <p className='mb20'>
                <span className='app-text-gray'>Date:</span> {new Date().toLocaleString()}, 
                <span className='app-text-gray'> Run by:</span> {result.updatedBy}
                </p>}

              {productSelected.pcaNumbers?.length > 0 && 
              <div className='mt10 app-flex'>
                <div className='app-bining-control mr15'>
                  <b>12NC:</b>
                  <br />
                  {productSelected?.pcaNumbers?.length > 0 && productSelected.pcaNumbers.map(function(row: any, rowIdx: number) {
                      if (+row.id === +pcanum) {
                        return <span className='app-text-gray' key={rowIdx + 'ad'}>{row.internal12nc}</span>;
                      }
                      return <span className='app-text-gray' key={rowIdx}></span>;
                  })}
                </div>
                <div className='app-bining-control'>
                  <b>PCA Number:</b>
                  <br />
                  <select className='app-input' disabled={result}
                  value={pcanum} onChange={(e: any) => {setPcaNum(e.target.value)}}>
                      {productSelected.pcaNumbers.map(function(row: any, rowIdx: number) {
                        return(<option key={rowIdx} value={row?.id}>{row?.pcaNumber}</option>)
                    })}
                  </select>
                </div>
                <div className='app-bining-control'>
                  <b>Number Of Setups:</b>
                  <br />
                  <input className={valid['numberOfSetups'] ? 'app-input app-border-red' : 'app-input'} placeholder="Number Of Setups" type="number" min="0" disabled={result}
                  value={numberOfSetups} onChange={(e: any) => {setNumberOfSetups(e.target.value)}}/>
                </div>
                <div className='app-bining-control'>
                  <b>Spool Size:</b>
                  <br />
                  <input className={valid['spoolSize'] ? 'app-input app-border-red' : 'app-input'} placeholder="Spool Size" type="number" min="0" disabled={result}
                  value={spoolSize} onChange={(e: any) => {setSpoolSize(e.target.value)}}/>
                </div>

              </div>}
              
              {productSelected?.product && 
                <ProductChannels 
                  ro={true} 
                  product={productSelected} 
                  manufacturers={manufacturers} 
                  onSelect={setChannelSelected}>
                </ProductChannels>
              }

              <p className='mt10'>
                <b>Comment:</b>
                <br />
                <input placeholder='Comment' className='app-input app-width-100'  disabled={result}
                  value={comments} onChange={(e: any) => {setComments(e.target.value)}}/>
              </p>
            </div>
          }

          {productSelected?.product && !channelSelected && 
          <h2 className='app-width-100 app-text-gray app-text-center mt40'>No Selected Channel</h2>}
          
          {!result && channelSelected && 
          <BiningSelectInventory
            chromaticity={chromaticity}
            flux={flux}
            waveLength={wavelength}
            voltage={voltage}
            inventories={inventories}
            setInventories={setInventories}
            channel={channelSelected} 
            product={productSelected}
            setValidation={setValidInventory}>
          </BiningSelectInventory>}

          {result && channelSelected &&
          <BiningResult 
            chromaticity={chromaticity}
            flux={flux}
            waveLength={wavelength}
            voltage={voltage}
            result={result}
            inventories={inventories}
            channel={channelSelected} 
            spoolSize={spoolSize}
            product={productSelected}>
          </BiningResult>
          }



          {!productSelected?.product && <h2 className='app-width-100 app-text-center mt10 mb10'><b>Binning</b></h2>}
          {!productSelected?.product && productSelected?.products && 
            <ProductFolder products={productSelected?.products} itemClick={selectItem}></ProductFolder>
          }
          {!productSelected && products?.length > 0 && 
            <ProductFolder products={products} itemClick={selectItem}></ProductFolder>
          }
          
        </div>


        <div className={`app-products-nav-map ${hideModileNav ? "app-products-nav-map-hide" : ""}`} >
          <NavMap 
            ref={modalNavMapRef}
            title='Products'
            selectProp='id' 
            presentProp='name' 
            childrenProp='products'
            filter={true} 
            loopAll={true}
            onSelect={onSelectProduct}
          />
        </div>

        {loading && !appCtxRef.ui.loading && <Loader />}

        <YesNoModal ref={modalErrRef} btnNoHide={true} btnYes={'OK'} title={errTitle}>
        </YesNoModal>
      </div>
    </BiningCtx.Provider>
  );
}

export default Bining;
