import { useCallback, useEffect, useRef, useState } from 'react';
import { ManufacturersService } from '../../../../services/ManufacturersService';
import Modal, { ModalInterface } from '../../../ui-elements/modal/Modal';
import YesNoModal from '../../../ui-elements/yes-no-modal/YesNoModal';
import './Chromaticity.scss';
import ChromacityForm from './chromacity-form/ChromacityForm';
import { Logger } from '../../../../utils/Logger';
import ChromacityGraph from '../../../chromaticity/chromacity-graph/ChromacityGraph';
import ChromaticityDataView from './chromaticity-data-view/ChromaticityDataView';

function Chromaticity(props: any) {

  Logger.debug('Chromaticity component render.');

  const modalEditRef = useRef<ModalInterface>();
  const modalDelRef = useRef<ModalInterface>();
  const modalGraphRef = useRef<ModalInterface>();

  const [data, setData] = useState<any>({});
  const [selected, setSelected] = useState<any>(null);
  const [selectedOrig, setSelectedOrig] = useState<any>(null);

  const [editData, setEditData] = useState<any>(null);
  const [editFail, setEditFail] = useState<boolean>(false);

  const [objToDelete, setObjToDelete] = useState<any>(null);

  //const [UPDATE_PERMISSION] = useState<boolean>(appCtxRef?.session?.features[APP_FEATURES.GeneralManufacturerUpdate]);
  const [chromaticity, setChromaticity] = useState<any>({});
  
  const processColorChromaticity = useCallback( (d: any) => {
    const arr = [];
    if (d.length > 0) {
      for (const item of d) {

        const res: any = {};
        if (item?.values?.length > 0) {
          res['name'] = item?.name;
          res['createdBy'] = item?.createdBy;
          for (const val of item?.values) {
            if (val?.name) {
              res[val?.name] = val?.value;
            }
          }
        }
        arr.push(res);
      }
    } 

    setSelectedOrig(d);
    setSelected(arr);
  }, []);

  const load = useCallback((reload: boolean, close: boolean) => {
    if (reload || !data[props?.color?.id]) {
      data[props?.color?.id] = [];
      setData(data);
      setSelected(data[props?.color?.id]);
      ManufacturersService.getChromaticity(props?.color?.id).then((resp: any) => {
        setChromaticity(resp.data);
        data[props?.color?.id] = resp.data;
        processColorChromaticity(resp.data);
        modalDelRef?.current?.open(false);
        if (close) {
          modalEditRef?.current?.open(false);
        }
      });
    } else {
      processColorChromaticity(data[props?.color?.id]);
    }
  }, [props?.color, data, processColorChromaticity]);

  const save = useCallback((d: any) => {
    d.inUse = true;
    d.color = {
      id: props?.color?.id
    };
    
    ManufacturersService.saveChromaticity([d]).then(() => {
      load(true, !d.saveAndAdd);
    }).catch(() => {
      setEditFail(true);
    });
  }, [props?.color, load]);

  const edit = useCallback((item: any) => {
    setEditFail(false);
    setEditData(item);
    
    modalEditRef?.current?.open(true);
  }, []);

  const del = useCallback((item: any) => {
    setObjToDelete(item);
    modalDelRef?.current?.open(true);
  }, []);

  const delConfirm = useCallback(() => {
    ManufacturersService.retireChromaticity(objToDelete?.id).then(() => {
      load(true, true);
    }).catch(() => {
      // TODO
    });
  }, [objToDelete, load]);

  useEffect(() => {
    load(false, true);
  }, [props?.color, load]);


  return (
    <div className='app-chromaticity'>
      
      <ChromaticityDataView 
        header={['ID', 'x1', 'y1', 'x2', 'y2', 'x3', 'y3', 'x4', 'y4']}
        keys={['name', 'x1', 'y1', 'x2', 'y2', 'x3', 'y3', 'x4', 'y4']}
        data={selected} 
        title='Chromaticity'
        headerIcon={'/assets/img/icons/plus-black.svg'}
        headerIconClick={() => {edit(null)}}
        rowIcon={'/assets/img/icons/minus-black.svg'}
        rowIconClick={(idx: number) => {del(selectedOrig[idx])}}
        editRow={true}
        editRowClick={(idx: number) => {edit(selectedOrig[idx])}}>
      </ChromaticityDataView>
      
      <button className='app-btn-neutral pl10 pr10 app-chromaticity-graph-btn' 
        disabled={!selected || Object.keys(selected).length === 0}
        onClick={ () => {modalGraphRef?.current?.open(true) }}>
        <img alt='' className='app-cursor-pointer' src='/assets/img/icons/line-chart-black.svg' />
      </button>

      <Modal ref={modalEditRef}>
        <ChromacityForm
          data={editData} 
          fail={editFail}
          onSave={save}>
        </ChromacityForm>
      </Modal>

      <YesNoModal ref={modalDelRef} yes={delConfirm} yesCloseDisabled={true}>
      </YesNoModal>

      <Modal ref={modalGraphRef}>
        <h4 className='mt15 ml15'><b>Chromaticity</b></h4>

        <p className='ml15 mt5'>Manufacturer: <b>{props?.manufacturer?.name}</b>, Color: <b>{props?.color?.name}</b></p>
        <div className='m15'>
        <ChromacityGraph 
          hideControls="true"
          chromaticity={chromaticity} >
        </ChromacityGraph>
        </div>
      </Modal>
    </div>
  );
}
export default Chromaticity;

