import React from "react";
import AppCtx from "../AppCtx";
import { AuthService } from "../services/AuthService";
import { ManufacturersService } from "../services/ManufacturersService";
import { ProductsService } from "../services/ProductsService";
import { UserService } from "../services/UserService";
import { Logger } from "../utils/Logger";
import { UrlUtils } from "../utils/UrlUtils";
import { APP_SESSION_LOCALSTORAGE } from "./AppConstants";

class AppAuth extends React.Component {

  static contextType = AppCtx;

  state = {
    executed: false
  }

  constructor (props: any) {
    Logger.debug('AppAuth component init.');
    super(props);   
  }

  componentDidMount() {
    Logger.debug('AppAuth component mount.');
    if(!this.state.executed) {
      Logger.debug('AppAuth component execute.');
      this.execute();
    }
    
    this.setState({executed: true});
  }

  execute() {
    Logger.debug('AppAuth component did mount.'); 

    let s = localStorage.getItem(APP_SESSION_LOCALSTORAGE);
    if (s) {
      this.context.setSession(JSON.parse(s));
      this.context.setUi({...this.context.ui, loading: false});

      //preload
      ProductsService.all().then((products: any) => {
        this.context.setCore({...this.context.core, products: products.data});
      });
      ManufacturersService.all().then((mnf: any) => {
        this.context.setCore({...this.context.core, manufacturers: mnf.data});
      });
      return;
    }
    
    let code = UrlUtils.getQeuryParam('code');
    if (code) {

      // remove ?code from url
      window.history.pushState(null, '', window.location.href.split('?')[0]);

      AuthService.decode(code).then((data: any) => {
        this.context.setSession({...this.context.session, auth: data.data});        
        UserService.details().then((details: any) => {
          
          UserService.getAuthorizationRules().then((features: any) => {

            const formatedFeatures: any = {};
            for (const item of features.data) {
              formatedFeatures[item?.feature?.name] = true;
            }

            this.context.setSession({...this.context.session, 
              status: true,
              details: details.data,
              features: formatedFeatures
            });
            localStorage.setItem(APP_SESSION_LOCALSTORAGE, JSON.stringify(this.context.session));
            this.context.setUi({...this.context.ui, loading: false});

            //preload
            ProductsService.all().then((products: any) => {
              this.context.setCore({...this.context.core, products: products.data});
            });
          });

        });
      });
    } else if (!this.context.session.status) {
      AuthService.login();
    }  
  }
  
  // !!! Never rerender, run only once at startup
  render() {
    return null;
  }
  shouldComponentUpdate = () => false

}
export default AppAuth;