import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import AppCtx from '../../../AppCtx';
import { APP_COLOR_TYPES } from '../../../core/AppConstants';
import { ManufacturersService } from '../../../services/ManufacturersService';
import { QueryService } from '../../../services/QueryService';
import { DateUtils } from '../../../utils/DateUtils';
import { Logger } from '../../../utils/Logger';
import BiningResult from '../../bining/bining-result/BiningResult';
import ProductChannels from '../../products/product-channels/ProductChannels';
import './QueryDetails.scss';
import Loader from '../../ui-elements/loader/Loader';
import { ModalInterface } from '../../ui-elements/modal/Modal';
import YesNoModal from '../../ui-elements/yes-no-modal/YesNoModal';
import { BiningService } from '../../../services/BiningService';

function QueryDetails(props: any) {
  Logger.debug('QueryDetails component render.');

  const appCtxRef = useContext(AppCtx);
  const modalDelResRef = useRef<ModalInterface>();

  const [numberOfSetups, setNumberOfSetups] = useState<any>(0);
  const [spoolSize, setSpoolSize] = useState<any>(0);
  const [comments, setComments] = useState<any>('');
  const [pcanum, setPcaNum] = useState<any>('');

  const [channelSelected, setChannelSelected] = useState<any>(null);
  const [manufacturers, setManufacturers] = useState<any>([]);

  const [chromaticity, setChromaticity] = useState<any>([]);
  const [flux, setFlux] = useState<any>([]);
  const [wavelength, setWavelength] = useState<any>([]);
  const [voltage, setVoltage] = useState<any>([]);

  const [result, setResult] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);

  useEffect(() => {

    if (props.selectedId) {
      setLoading(true);
      QueryService.queryItem(props.selectedId).then((resp: any) => {
        setResult(resp.data);
        setPcaNum(resp.data.pcaNumber ? resp.data.pcaNumber.id : '');
        setSpoolSize(resp.data.spoolSize ? resp.data.spoolSize : '');
        setNumberOfSetups(resp.data.numberOfSetups ? resp.data.numberOfSetups : '');
        setComments(resp.data.comments);
        setLoading(false);
      });
    }

    if (appCtxRef.core.manufacturers) {
      setManufacturers(appCtxRef.core.manufacturers);
    } else {
      ManufacturersService.all().then((resp: any) => {
        setManufacturers(resp.data);
      });
    }
  }, [props, appCtxRef.core.manufacturers]);

  useEffect(() => {  
    if (!channelSelected) {
      return;
    }
    if (channelSelected?.color?.colorType === APP_COLOR_TYPES.CHROMATICITY) {
      ManufacturersService.getChromaticity(channelSelected?.color?.id).then((resp: any) => {
        setChromaticity(resp.data);
        setWavelength([]);
      });
    } else if (channelSelected?.color?.colorType === APP_COLOR_TYPES.WAVELENGTH) {
      ManufacturersService.getWavelength(channelSelected?.color?.id).then((resp: any) => {
        setChromaticity([]);
        setWavelength(resp.data);
      });
    }
    ManufacturersService.getFlux(channelSelected?.color?.id).then((resp: any) => {
      setFlux(resp.data);
    });
    ManufacturersService.getBinningForwardVoltage(channelSelected?.color?.id, channelSelected?.id).then((resp: any) => {
      setVoltage(resp.data);
    });

  }, [channelSelected, result]);

  const reload = useCallback(() => {
    if (props.selectedId) {
      setLoading(true);
      QueryService.queryItem(props.selectedId).then((resp: any) => {
        setResult(resp.data);
        setPcaNum(resp.data.pcaNumber ? resp.data.pcaNumber.id : '');
        setSpoolSize(resp.data.spoolSize ? resp.data.spoolSize : '');
        setNumberOfSetups(resp.data.numberOfSetups ? resp.data.numberOfSetups : '');
        setComments(resp.data.comments);
        setLoading(false);
      });
    }

    if (appCtxRef.core.manufacturers) {
      setManufacturers(appCtxRef.core.manufacturers);
    } else {
      ManufacturersService.all().then((resp: any) => {
        setManufacturers(resp.data);
      });
    }
  }, [props, appCtxRef.core.manufacturers]);

  const exportResult = () => {
    QueryService.export(null, null, null, result.id, null).then((resp: any) => {
      const url = URL.createObjectURL(new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const element = document.createElement('a');
      element.setAttribute('href', url);

      const date = new Date(result.createDate); // Current date and time
      const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()} - ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')} ${DateUtils.getTZ().replaceAll('%2B','+')}`;
      const pca = result?.pcaNumber?.pcaNumber ? '_' + result?.pcaNumber?.pcaNumber  : '';

      element.setAttribute('download', formattedDate + '_' + props.productSelected?.name + pca + '.xls');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
  }
  

  const onDeleteSelected = useCallback(() => {
    if(!result) {
      return
    }
    BiningService.retire(result?.id).then(() => {
      props?.refresh();
    }, () => {});
    console.log(result)
  }, [result, props]);

  return (
    <div className='app-products-content app-query-details'>

    {props.productSelected?.product && 
      <div className='app-products-header'>

        <div className='app-products-header-title app-bining-control'>
          <span className='app-products-header-title-text mb10'>
            Recipe For Product: 
            <span className='app-products-header-title-text-active'> {props.productSelected?.name}</span>
          </span>
          <button className='app-btn-secondary' onClick={() => {modalDelResRef.current?.open(true)}}>Delete</button>
          <button className='app-btn-neutral' onClick={() => {exportResult();}}>Export</button>
        </div> 

        {result?.createDate && <p className='mb20'>
          <span className='app-text-gray'>Date:</span> {DateUtils.format(result.createDate, null)}, 
          <span className='app-text-gray'> Run by:</span> {result.updatedBy}
          </p>}

        {props?.productSelected.pcaNumbers?.length > 0 && 
        <div className='mt10 app-flex'>
          <div className='app-bining-control mr15'>
            <b>12NC:</b>
            <br />
            {props?.productSelected?.pcaNumbers.length > 0 && props.productSelected.pcaNumbers.map(function(row: any, rowIdx: number) {
                if (+row.id === +pcanum) {
                  return <span className='app-text-gray' key={rowIdx + 's'}>{row.internal12nc}</span>
                }
                return <span className='app-text-gray' key={rowIdx}></span>;
            })}
          </div>
          <div className='app-bining-control'>
            <b>PCA Number:</b>
            <br />
            <select className='app-input' disabled={true}
            value={pcanum} onChange={(e: any) => {setPcaNum(e.target.value)}}>
                {props.productSelected.pcaNumbers.map(function(row: any, rowIdx: number) {
                  return(<option key={rowIdx} value={row?.id}>{row?.pcaNumber}</option>)
              })}
            </select>
          </div>
          <div className='app-bining-control'>
            <b>Number Of Setups:</b>
            <br />
            <input className='app-input' placeholder="Number Of Setups" type="number"  disabled={result}
            value={numberOfSetups} onChange={(e: any) => {setNumberOfSetups(e.target.value)}}/>
          </div>
          <div className='app-bining-control'>
            <b>Spool Size:</b>
            <br />
            <input className='app-input' placeholder="Spool Size" type="number"  disabled={result}
            value={spoolSize} onChange={(e: any) => {setSpoolSize(e.target.value)}}/>
          </div>
        </div>}
        
        {props.productSelected?.product && 
          <ProductChannels 
            ro={true} 
            product={props.productSelected} 
            manufacturers={manufacturers} 
            onSelect={setChannelSelected}>
          </ProductChannels>
        }

        <p className='mt10'>
          <b>Comment:</b>
          <br />
          <input placeholder='Comment' className='app-input app-width-100'  disabled={result}
            value={comments} onChange={(e: any) => {setComments(e.target.value)}}/>
        </p>

      </div>
    }

    {props.productSelected?.product && !channelSelected && 
    <h2 className='app-width-100 app-text-gray app-text-center mt40'>No Selected Channel</h2>}
    


    {result && channelSelected &&
    <BiningResult 
      chromaticity={chromaticity}
      flux={flux}
      waveLength={wavelength}
      voltage={voltage}
      result={result}
      channel={channelSelected} 
      spoolSize={spoolSize}
      allowBinUpdate={true}
      reload={reload}
      product={props.productSelected}>
    </BiningResult>
    }

    {loading && <Loader />}

    <YesNoModal ref={modalDelResRef} btnNo={'No'} btnYes={'Yes'} yes={() => {onDeleteSelected()}} title={'Are you sure that you want to delete selected binning result?'}>
    </YesNoModal>

    
  </div>
  );
}

export default memo(QueryDetails);
