import React from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import AppCtx, { UnsavedActionEnum } from '../../../AppCtx';
import { Logger } from '../../../utils/Logger';
import './NavMap.scss';

const NavMap = forwardRef((props: any, ref: any) => {

  Logger.debug('NavMap component render.');

  const [active, setActive] = useState<any>(null);
  const [search, setSearch] = useState('');
  const [searchDebounce, setSearchDebounce] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItemsAll, setFilteredItemsAll] = useState([]);
  const [selected, setSelected] = useState<any>([]);
  const [items, setItems] = useState<any>([]);
  const [unsavedActionOn, setUnsavedActionOn] = useState<any>(null);
  const appCtxRef = React.useContext(AppCtx);
  
  useImperativeHandle(ref, () => ({

    load(data: any) {
      load(data);
    },

    select(item: any) {
      select(item, false);
    },

  }));

  const load = useCallback((data: any) => { 
    let list =  data;
    let selectedProcessing = selected;

    let checkList = list;
    for (let i = 0; i < selectedProcessing?.length; i++) {
      let found = false;
      if (!checkList) {
        break;
      }
      for (const it of checkList) {
        if (selectedProcessing[i]?.id === it.id) {
          found = true;
          selectedProcessing[i] = it;
          checkList = it[props.childrenProp];
          break;
        }
      }
      if (!found) {
        selectedProcessing = selectedProcessing.slice(0, i);
        setSelected(selectedProcessing);
        break;
      }
    }


    setItems(list);

    let selectedItem: any = selectedProcessing?.length > 0 ? selectedProcessing[selectedProcessing.length - 1] : null;
    if (!selectedItem && list.length > 0 && props.selectFirst) {
      selectedItem = list[0];
      selectedProcessing?.push(selectedItem);
      setSelected(selectedProcessing);
    } 

    setActive(selectedItem);
    if (props.onSelect && !props.updateOnlyOnActions) {
      props.onSelect(selectedItem);
    }

  }, [
    selected,
    props
  ]);

  useEffect(() => {
    let list = items;
    
    let selectedItem: any = selected?.length > 0 ? selected[selected.length - 1] : null;
    if (props.childrenProp && selectedItem) {
      const selectedItemParent = selected.length > 1 ? selected[selected.length - 2] : null;
    
      if (selectedItem[props.childrenProp]) {
        list = selectedItem[props.childrenProp];
      } else if (selectedItemParent && selectedItemParent[props.childrenProp]) {
        list = selectedItemParent[props.childrenProp];
      } 
    }

    if (props.filterAll) {
      list = list.filter((item: any) => {
        return !search || (item[props.presentProp] + JSON.stringify(item.searchParams)).toLowerCase().indexOf(search.toLowerCase()) >= 0;
      });
    } else {
      list = list.filter((item: any) => {
        return !search || (item[props.presentProp] + JSON.stringify(item.pcaNumbers)).toLowerCase().indexOf(search.toLowerCase()) >= 0;
      });
    }

    setFilteredItems(list);

    //TODO revisite very intensive
    if (search && props.loopAll) {
      const foindItems: any = [];
      let prods: any = [];
      
      for (const item of items) {
        if (!item.product) {
          prods = prods.concat(item.products);
        }
        if ((item.name + JSON.stringify(item.pcaNumbers)).toLowerCase().indexOf(search.toLowerCase()) >= 0) {
          foindItems.push(item);
        }   
      }

      while(prods.length > 0) {
        let newprods: any = [];
        for (const itemProd of prods) {
          if (!itemProd.product) {
            newprods = newprods.concat(itemProd.products);
          }
          if ((itemProd.name + JSON.stringify(itemProd.pcaNumbers)).toLowerCase().indexOf(search.toLowerCase()) >= 0) {
            foindItems.push(itemProd);
          }
        }
        prods = newprods;
      }

      setFilteredItemsAll(foindItems);
    }

    if (search && props.filterAll) {
      const foundItems: any = [];
      
      for (const item of items) {
        if ((item.name + JSON.stringify(item.searchParams)).toLowerCase().indexOf(search.toLowerCase()) >= 0) {
          foundItems.push(item);
        }   
      }
      setFilteredItemsAll(foundItems);
    }
  }, [items, search, selected, props.presentProp, props.childrenProp, props.loopAll, props.filterAll]);

  const findParents: any = (jsonArray: any, targetId: any, parents: any) => {
    parents = !parents ? [] : parents;
    for (const item of jsonArray) {
      if (item.id === targetId) {
        return [...parents, item];
      } else if (item.products && item.products.length > 0) {
        const result = findParents(item.products, targetId, [...parents, item]);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  const selectItem = useCallback((item: any) => {
    // TODO careful if needed optimize
    const s = findParents(items, item.id);
    setSelected(s);
    setActive(item);
    if (props.onSelect) {
      props.onSelect(item);
    }
    setSearch('');
    setSearchDebounce('');
  }, [props, items]);

  const bradcrumb = useCallback((idx: number) => {
    
    setSearch('');
    setSearchDebounce('');
    const s = selected.slice(0, idx + 1);
    let selectedItem: any = s?.length > 0 ? s[s.length - 1] : null;
    setSelected(s);
    setActive(selectedItem);
    if (props.onSelect) {
      props.onSelect(selectedItem);
    }

  }, [selected, props]);

  const reset = useCallback(() => {
    
    // level change
    if (selected?.length > 1) {
      setSearch('');
      setSearchDebounce('');
      setItems(items);
    }

    // init select
    let s = null;
    if (items?.length > 0 && props.selectFirst) {
      s = items[0];
    }
    setSelected(s ? [s] : []);
    setActive(s);
    if (props.onSelect && !props.updateOnlyOnActions) {
      props.onSelect(s);
    }

  }, [items, selected, props]);

  const select = useCallback((item: any, isAllSelect: boolean) => {

    if (appCtxRef.ui.unsavedActionOn === UnsavedActionEnum.EXIST) {
      setUnsavedActionOn(item);
      appCtxRef.setUi({...appCtxRef.ui, unsavedActionOn: UnsavedActionEnum.CHECK});
      return;
    }

    if (!item) {
      reset();
      return;
    }

    for (let i = 0; i < selected?.length; i++) {
      if (item.id === selected[i].id) { 
        bradcrumb(i);
        return;
      }
    }
    
    selectItem(item);
  }, [selected, bradcrumb, selectItem, reset, appCtxRef]);

  useEffect(() => {
    if (unsavedActionOn && (appCtxRef.ui.unsavedActionOn === UnsavedActionEnum.SAVE || appCtxRef.ui.unsavedActionOn === UnsavedActionEnum.DISCARD)) {
      select(unsavedActionOn, false);
      setUnsavedActionOn(null);
    } else if (appCtxRef.ui.unsavedActionOn === UnsavedActionEnum.EXIST) {
      setUnsavedActionOn(null);
    }
  }, [appCtxRef.ui.unsavedActionOn, unsavedActionOn, select]);

  const filter = useCallback((s: any) => {

    setSearchDebounce(s);

    //debounce
    const handler = setTimeout(() => {      
      setSearch(s);

      if (props.onFilter) {
        props.onFilter(s);
      }
    }, 500);
    return () => {
      clearTimeout(handler);
    };
    
  }, [props]);

  return props.selectProp ? (
    <div className='app-nav-map'>

      <div className='app-nav-map-filter'>
        {props.filter && 
          <input placeholder='Search' className='app-input' value={searchDebounce} onChange={(event: any) => {filter(event.target.value);}} />
        }

      </div>
      <div className='app-nav-map-items'>
        {props.title && 
          <p className='app-nav-map-beadcrumb' >
            <span onClick={() => {select(null, false);}}>{props.title}</span>
            {selected?.length > 0 && 
              selected.map(function(item: any, idx: number){
                return item[props.childrenProp] ? (
                  <span key={idx} onClick={() => {select(item, false);}}> / {item[props.presentProp]}</span>
                ) : ''
              })
            }
          </p>
        }

        {filteredItems?.length === 0 && 
          <div className='ml20 app-text-gray'>No items</div>
        }

        {filteredItems?.map(function(item: any, idx: number){
          return item[props.selectProp] ? 
          (
            <div 
              key={idx} 
              className={`
                app-nav-map-item 
                ${active && item[props.selectProp] === active[props.selectProp] ? "active" : ""}
              `} 
              onClick={() => {select(item, false)}}>
              {item[props.presentProp]}
              { item[props.childrenProp] && <img src='/assets/img/icons/chevron-right-gray.svg' alt=""/>}
            </div>
          ) : '';
        })}


        {search && props.loopAll && filteredItemsAll?.length > 0 && 
          <div>
            <hr className='mt40'/>
            <p className='app-nav-map-beadcrumb mt20' >Filtered items by 'Name' and 'PCA Number'</p>
            {filteredItemsAll.map(function(item: any, idx: number){
              return item[props.selectProp] ? 
              (
                <div 
                  key={idx} 
                  className={`
                    app-nav-map-item 
                    ${active && item[props.selectProp] === active[props.selectProp] ? "active" : ""}
                  `} 
                  onClick={() => {select(item, true)}}>
                  * {item[props.presentProp]}
                  { item[props.childrenProp] && <img src='/assets/img/icons/chevron-right-gray.svg' alt=""/>}
                </div>
              ) : '';
            })}
          </div>
        }

        {search && props.filterAll && filteredItemsAll?.length > 0 && 
          <div>
            <hr className='mt40'/>
            <p className='app-nav-map-beadcrumb mt20' >Filtered items by 'Name', 'Color Name' and '12NC'</p>
            {filteredItemsAll.map(function(item: any, idx: number){
              return item[props.selectProp] ? 
              (
                <div 
                  key={idx} 
                  className={`
                    app-nav-map-item 
                    ${active && item[props.selectProp] === active[props.selectProp] ? "active" : ""}
                  `} 
                  onClick={() => {select(item, true)}}>
                  * {item[props.presentProp]}
                </div>
              ) : '';
            })}
          </div>
        }

      </div>
    </div>
  ) : 
  (<div>ERROR NavMap: 'selectProp' not set!</div>);
});
export default NavMap;

