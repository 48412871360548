import React from 'react';
import { useRef, useState, useEffect } from 'react';
import ManufacturersCtx from '../../../../pages/manufacturers/ManufacturersCtx';
import { ManufacturersService } from '../../../../services/ManufacturersService';
import { Logger } from '../../../../utils/Logger';
import Modal, { ModalInterface } from '../../../ui-elements/modal/Modal';
import './CloneColor.scss';
import { UIUtils } from '../../../../utils/UIUtils';
import AppCtx from '../../../../AppCtx';

function CloneColor(props: any) {

  Logger.debug('CloneColor component render.');

  const modalRef = useRef<ModalInterface>();
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [id, setId] = useState('');
  const [targetmanufacturer, setTargetManufacturer] = useState<any>('');
  const [fail, setFail] = useState(false);
  const manufacturersCtxRef = React.useContext((ManufacturersCtx));
  const [valid, setValid] = useState<any>({});
  const appCtxRef = React.useContext(AppCtx);

  const save = () => {
    const req: any = {
      name,
      colorType: props?.color?.colorType,
      scmInternalId: id,
      orderCode: code,
      manufacturer: {
        id: +targetmanufacturer
      }
    };

    ManufacturersService.cloneColor(props?.color?.id, req).then(() => {
      ManufacturersService.all().then((resp: any) => {
        manufacturersCtxRef.setManufacturers(resp.data);
        appCtxRef.setCore({...appCtxRef.core, manufacturers: resp.data});
      });
      if (props.onSave) {
        props.onSave(+targetmanufacturer);
      }
      setName('');
      setCode('');
      setId('');
      setTargetManufacturer('');
      modalRef?.current?.open(false);

    }).catch(() => {
      setFail(true);
    });
  }

  const trigger = () => {
    if (props?.color?.name) {
      setName(props?.color?.name + ' (Clone)');
    }
    setId('');
    setCode('');
    setTargetManufacturer(props?.manufacturerId);
    modalRef?.current?.open(true);
  }

  useEffect(() => {
    const v : any = {};

    if (!name) {
      v['name'] = true;
    }
    if (!code) {
      v['code'] = true;
    }
    if (!UIUtils.validateNumber(id, 0, null, 0) || id.length !== 12) {
      v['id'] = true;
    }

    setValid(v);
  },[name ,code ,id]);

  return (
    <>
      <button className='app-color-add-btn app-btn-neutral' onClick={() => {trigger()}}>
        Clone
      </button>
      <Modal ref={modalRef}>
        <div className='app-color-add'>
          <h2>Clone LED</h2>

          <p>Name</p>
          <input placeholder='Name' className={valid['name'] ? `app-input app-border-red` :  `app-input`} value={name} 
            onChange={(event: any) => {setFail(false); setName(event.target.value);}} />

          <p>Order Code</p>
          <input placeholder='Order Code' className={valid['code'] ? `app-input app-border-red` :  `app-input`} value={code} 
            onChange={(event: any) => {setFail(false); setCode(event.target.value);}} />

          <p>12NC</p>
          <input type='number' placeholder='12NC' className={valid['id'] ? `app-input app-border-red` :  `app-input`} value={id} 
            onChange={(event: any) => {setFail(false);setId(event.target.value);}} />

          <p className='mt15'>Manufacturer</p>
          <select className='app-input' value={targetmanufacturer}  onChange={(event: any) => {setFail(false); setTargetManufacturer(event.target.value);}} >

          {manufacturersCtxRef.manufacturers.map(function(row: any, rowIdx: number) {
            return(
              <option value={row.id} key={rowIdx}>{row.name}</option>
            );
          })}
          </select>
            
          {fail && <p className='app-text-darkred'>*This color could not be saved! It might be that 12nc already exists!</p>}

          <button className='app-btn-secondary' disabled={Object.keys(valid).length !== 0} onClick={() => { save(); }}>Save</button>
        </div>
      </Modal>
    </>
  );
}
export default CloneColor;

