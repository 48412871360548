import { useContext, useRef } from 'react';
import { ManufacturersService } from '../../../../services/ManufacturersService';
import { Logger } from '../../../../utils/Logger';
import { ModalInterface } from '../../../ui-elements/modal/Modal';
import YesNoModal from '../../../ui-elements/yes-no-modal/YesNoModal';
import './DelColor.scss';
import AppCtx from '../../../../AppCtx';
import ManufacturersCtx from '../../../../pages/manufacturers/ManufacturersCtx';

function DelColor(props: any) {

  Logger.debug('DelColor component render.');

  const modalRef = useRef<ModalInterface>();
  const appCtxRef = useContext(AppCtx);
  const manufacturersCtxRef = useContext((ManufacturersCtx));

  const save = () => {
    ManufacturersService.retireColor(props.delId).then(() => {
      ManufacturersService.all().then((resp: any) => {
        manufacturersCtxRef.setManufacturers(resp.data);
        appCtxRef.setCore({...appCtxRef.core, manufacturers: resp.data});
      });
      if (props.onSave) {
        props.onSave();
      }
      modalRef?.current?.open(false);
    }).catch(() => {
      modalRef?.current?.err("Delete action failed.");
    });
  }

  return (
    <>
      <button className='app-color-del-btn app-btn-secondary' onClick={() => {modalRef?.current?.open(true);}}>
        Delete
      </button>
      <YesNoModal ref={modalRef} yes={save} yesCloseDisabled={true}
      subtitle={'Warning! Deleting color will affect all related products.'}>
      </YesNoModal>
    </>
  );
}
export default DelColor;

