import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import AppCtx from '../../AppCtx';
import { PAGE_NAVIGATION } from '../../core/AppSettings';
import { AuthService } from '../../services/AuthService';
import { Logger } from '../../utils/Logger';
import Logo from '../ui-elements/logo/Logo';
import './Navbar.scss';

function Navbar() {
  Logger.debug('Navbar component render.');
  
  const [active, setActive] = useState('');
  const appCtxRef = React.useContext(AppCtx);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {

    let found = false;
    for(const item of PAGE_NAVIGATION) {
      if (location.pathname.indexOf(item.path) >= 0) {
        setActive(item.name);
        found = true;
        break;
      }      
    }

    if (!found) {
      if (location.pathname === '/') {
        setActive(PAGE_NAVIGATION[0].name);
      } else {
        setActive('');
      }
    }

  }, [location.pathname]);

  const logout = () => {
    AuthService.logout();
    appCtxRef.setUi({...appCtxRef.ui, loading: true});
  }

  const profile = () => {
    navigate('/profile', { replace: true });
  }

  return (
    <div className='app-navbar'>

      <span className='app-navbar-menu-mobile' onClick={() => {appCtxRef.setUi({...appCtxRef.ui, sidebar: !appCtxRef.ui.sidebar});}}>
        <img src='/assets/img/icons/menu-white.svg' alt='' />
      </span>
      <span className='app-navbar-title-mobile'>{active}</span>

      <span className='app-navbar-title'><Logo /></span>
        

      <Dropdown className='app-navbar-profile'>
        <Dropdown.Toggle >
          <img src='/assets/img/icons/user-white.svg' alt=""/>
          {appCtxRef.session?.details?.name}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => {profile()}}>Profile</Dropdown.Item>
          <Dropdown.Item onClick={() => {logout()}}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
        
    </div>
  );
}
export default Navbar;