import { lazy, Suspense, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import './App.scss';
import AppCtx, { CoreAppContextInitValue, SessionAppContextInitValue, UIAppContextInitValue, UnsavedActionEnum } from './AppCtx';
import Navbar from './components/navbar/Navbar';
import Sidebar from './components/sidebar/Sidebar';
import AppAuth from './core/AppAuth';
import AppInterceptor from './core/AppInterceptor';
import { Logger } from './utils/Logger';
import Loader from './components/ui-elements/loader/Loader';
import Manufacturers from './pages/manufacturers/Manufacturers';
import Products from './pages/products/Products';
import Bining from './pages/bining/Bining';
import Query from './pages/query/Query';
import UnsavedModal from './components/sidebar/unsaved-modal/UnsavedModal';


/* Lazy User accounts pages */
const Accounts = lazy(() => import('./pages/accounts/Accounts'));
const Users = lazy(() => import('./components/accounts/users/Users'));
const UsersEdit = lazy(() => import('./components/accounts/users-edit/UsersEdit'));
const Roles = lazy(() => import('./components/accounts/roles/Roles'));
const RolesEdit = lazy(() => import('./components/accounts/roles-edit/RolesEdit'));
const Clients = lazy(() => import('./components/accounts/clients/Clients'));
const ClientsEdit = lazy(() => import('./components/accounts/clients-edit/ClientsEdit'));
const Profile = lazy(() => import('./pages/profile/Profile'));



function App() {

  Logger.debug('App component render.');

  /* setup init app context */
  const [ui, setUi] = useState(UIAppContextInitValue);
  const [session, setSession] = useState(SessionAppContextInitValue);
  const [core, setCore] = useState(CoreAppContextInitValue);
  const appContextState = { ui, setUi, session, setSession, core, setCore };

  
  return (
    
    <AppCtx.Provider value={appContextState}>

      <AppInterceptor />
      <AppAuth />

      {session?.status && 
        <div className={`app-layout ${ui.sidebar ? "app-layout-expand" : ""}`}>
          <div className="app-layout-navbar">
            <Navbar />
          </div>
          <div className="app-layout-sidebar">
            <Sidebar />
          </div>
          <div className="app-layout-main">
            <Suspense fallback={<div></div>}>
              <Routes>
                <Route path="/" element={<Manufacturers />} />
                <Route path="products" element={<Products />} />
                <Route path="bining" element={<Bining />} />
                <Route path="query" element={<Query />} />
                <Route path="accounts" element={<Accounts />} >
                  <Route path="" element={<Users />} />
                  <Route path="roles" element={<Roles />} />
                  <Route path="roles/:id" element={<RolesEdit />} />
                  <Route path="clients" element={<Clients />} />
                  <Route path="clients/:id" element={<ClientsEdit />} />
                  <Route path=":id" element={<UsersEdit />} />
                  <Route path="*" element={<Navigate to="/accounts" />} />
                </Route>
                <Route path="profile" element={<Profile />} />

                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      }
      {session?.status && ui.sidebar && 
        <div className='app-layout-sidebar-backdrop-mobile' onClick={() => {setUi({...ui, sidebar: false})}}>
        </div>
      }

      {session?.status && 
        <div className={`app-layout-sidebar-mobile ${ui.sidebar ? "app-layout-sidebar-mobile-expand" : ""}`}>
          <Sidebar />
        </div>
      }

      {ui.loading &&
        <div className="app-layout-loading">
          <Loader />
        </div>
      }

      {ui.unsavedActionOn === UnsavedActionEnum.CHECK &&
        <div className="app-layout-loading">
          <UnsavedModal />
        </div>
      }

      {ui.toast?.length > 0 &&
        <div className="app-layout-toast">
          {JSON.stringify(ui.toast)}
        </div>
      }
    </AppCtx.Provider>
  );
}

export default App;

