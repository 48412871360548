import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import AppCtx from '../../AppCtx';
import AddProduct from '../../components/products/add-product/AddProduct';
import DelProduct from '../../components/products/del-product/DelProduct';
import ProductChannelDetails from '../../components/products/product-channel-details/ProductChannelDetails';
import ProductChannels from '../../components/products/product-channels/ProductChannels';
import ProductFolder from '../../components/products/product-folder/ProductFolder';
import ProductNumbers from '../../components/products/product-numbers/ProductNumbers';
import Loader from '../../components/ui-elements/loader/Loader';
import NavMap from '../../components/ui-elements/nav-map/NavMap';
import { APP_FEATURES } from '../../core/AppConstants';
import { ManufacturersService } from '../../services/ManufacturersService';
import { ProductsService } from '../../services/ProductsService';
import { Logger } from '../../utils/Logger';
import './Products.scss';
import ProductsCtx from './ProductsCtx';
import MoveProduct from '../../components/products/move-product/MoveProduct';
import CopyProduct from '../../components/products/copy-product/CopyProduct';

function Products() {

  Logger.debug('Products component render.');

  const modalNavMapRef = useRef<any>();

  const appCtxRef = useContext(AppCtx);

  //const [UPDATE_PERMISSION] = useState<boolean>(appCtxRef?.session?.features[APP_FEATURES.GeneralProductUpdate]);
  const [READ_PERMISSION] = useState<boolean>(appCtxRef?.session?.features[APP_FEATURES.GeneralProduct]);

  const [loading, setLoading] = useState<any>(false);
  const [hideModileNav, setSideModileNav] = useState<boolean>(true);
  const [products, setProducts] = useState<any>([]);
  const [productsData, setProductsData] = useState<any>({});
  const [productSelected, setProducSelected] = useState<any>(null);
  const [channelSelected, setChannelSelected] = useState<any>(null);
  const [manufacturers, setManufacturers] = useState<any>([]);

  const ProductsCtxValue = {
    products, setProducts, productsData, setProductsData
  };

  const load = useCallback(() => {
    if (appCtxRef.core.products) {
      setProducts(appCtxRef.core.products);
      modalNavMapRef?.current?.load(appCtxRef.core.products);
      
      const timeoutRef = setTimeout(() => {
        setLoading(false);
      }, 200);
      return () => {
        clearTimeout(timeoutRef);
      };

    } else { 
      ProductsService.all().then((resp: any) => {
        setProducts(resp.data);
        appCtxRef.setCore({...appCtxRef.core, products: resp.data});
        modalNavMapRef?.current?.load(resp.data);
        setLoading(false);
      });
    }
  }, [appCtxRef]);

  const loadAndUpdateSelected = useCallback(() => {
    ProductsService.all().then((resp: any) => {
      setProducts(resp.data);
      appCtxRef.setCore({...appCtxRef.core, products: resp.data});
      modalNavMapRef?.current?.load(resp.data);
      setLoading(false);

      if (productSelected) {
        ProductsService.get(productSelected.id).then((respDetails: any) => {
          const freshProductData = respDetails.data;
          freshProductData.products = productsData[freshProductData.id].products;
          productsData[freshProductData.id] = freshProductData;
          setProducSelected(freshProductData);
          setProductsData(productsData);
        });
      }
    });
  }, [productSelected, productsData, appCtxRef]);

  const loadSelected = useCallback(() => {
    if (productSelected) {
      ProductsService.get(productSelected.id).then((respDetails: any) => {
        const freshProductData = respDetails.data;
        freshProductData.products = productsData[freshProductData.id].products;
        productsData[freshProductData.id] = freshProductData;
        setProducSelected(freshProductData);
        setProductsData(productsData);
      });
    }
  }, [productSelected, productsData]);

  const delSelected = useCallback(() => {
    if (productSelected) {
      ProductsService.get(productSelected.id).then((respDetails: any) => {
        const freshProductData = respDetails.data;
        freshProductData.products = productsData[freshProductData.id].products;
        productsData[freshProductData.id] = freshProductData;
        setProducSelected(freshProductData);
        setProductsData(productsData);
        setChannelSelected(null);
      });
    }
  }, [productSelected, productsData]);

  const deleteAndLoad = useCallback(() => {
    ProductsService.all().then((resp: any) => {
      setProducts(resp.data);
      appCtxRef.setCore({...appCtxRef.core, products: resp.data});
      modalNavMapRef?.current?.load(resp.data);
      setLoading(false);
    });
  }, [appCtxRef]);

  const onSelectProduct = useCallback((item: any) => {

    if (!productSelected || item?.id !== productSelected?.id) {
      setChannelSelected(null);
    }

    if (item?.id && !productsData[item?.id]) {
      ProductsService.get(item?.id).then((resp: any) => {
        productsData[item?.id] = resp.data;
        const it = productsData[item?.id];
        it.products = item.products;
        setProducSelected(it);
        setSideModileNav(true);
        setProductsData(productsData);
      });
    } else {
      const it = productsData[item?.id];
      if (it) {
        it.products = item.products;
      }
      setProducSelected(it);
      setSideModileNav(true);
    }
  }, [productSelected, productsData]);  

  const selectItem = useCallback((it: any) => {
    modalNavMapRef?.current?.select(it);
  }, []);
  
  useEffect(() => {
    if (appCtxRef.core.manufacturers) {
      setManufacturers(appCtxRef.core.manufacturers);
    } else {
      ManufacturersService.all().then((resp: any) => {
        setManufacturers(resp.data);
      });
    }
    load();
  }, []);

  // no permission
  if (!READ_PERMISSION) {
    return (
      <div className="app-products">
        <span className='app-no-permission-content'>
          You don't have permission to access this page.
          <br />
          Please contact your administrator.
        </span>
      </div>
    );
  }
  
  return (
    <ProductsCtx.Provider value={ProductsCtxValue}>
      <div className="app-products">
        
        <div className='app-products-content'>

          <p className='app-products-header-mobile'>
            <span onClick={() => {setSideModileNav(!hideModileNav)}}>
              <img src='/assets/img/icons/grid-black.svg' alt='' />
            </span>
          </p>


          {productSelected && 
            <div className='app-products-header'>

              <div className='app-products-header-title'>
                <span className='app-products-header-title-text mb10'>
                  {productSelected?.product ? 'Product:' : 'Folder:'}
                  <span className='app-products-header-title-text-active'> {productSelected?.name}</span>
                </span>
                { productSelected?.product && <CopyProduct onSave={loadAndUpdateSelected} prod={productSelected}></CopyProduct>}
                { productSelected?.product && <span className='ml10 mt10'></span>}
                <MoveProduct onSave={deleteAndLoad} prod={productSelected}></MoveProduct>
                <span className='ml10 mt10'></span>
                { (appCtxRef?.session?.details?.identifier === productSelected?.createdBy || appCtxRef?.session?.details?.userProtected) && <AddProduct onSave={loadAndUpdateSelected} edit={productSelected}></AddProduct>}
                { (appCtxRef?.session?.details?.identifier === productSelected?.createdBy || appCtxRef?.session?.details?.userProtected) && <span className='ml10 mt10'></span>}
                { (appCtxRef?.session?.details?.identifier === productSelected?.createdBy || appCtxRef?.session?.details?.userProtected) && <DelProduct delId={productSelected?.id} propduct={productSelected?.product} onSave={deleteAndLoad}></DelProduct>}
              </div> 
              
              <p className='app-manufacturers-header-subtitle'>
              {productSelected?.product && <span>Fixture Type: <b className='mr20'>{productSelected?.fixtureType}</b></span>}
              <span>
                Description: <b className='mr20'>{productSelected?.description}</b>
              </span>
              </p>
            
              
              {productSelected?.product && 
                <ProductNumbers product={productSelected} onUpdate={loadSelected}></ProductNumbers>
              } 
              
              {productSelected?.product && 
                <ProductChannels product={productSelected} channel={channelSelected} manufacturers={manufacturers} onSelect={setChannelSelected} refresh={loadSelected}></ProductChannels>
              }
            </div>
          }

          {!productSelected?.product && productSelected?.products && 
            <ProductFolder products={productSelected?.products} itemClick={selectItem}></ProductFolder>
          }

          {!productSelected && products?.length > 0 && 
            <ProductFolder products={products} itemClick={selectItem}></ProductFolder>
          }
          
          {productSelected?.product && !channelSelected && <h2 className='app-width-100 app-text-gray app-text-center mt40'>No Selected Channel</h2>}
          {channelSelected && <ProductChannelDetails channel={channelSelected} product={productSelected} updateChannel={setChannelSelected} refresh={delSelected}></ProductChannelDetails>}

        </div>


        <div className={`app-products-nav-map ${hideModileNav ? "app-products-nav-map-hide" : ""}`} >
          <div className='app-products-nav-map-wrapper'>
            <NavMap 
              ref={modalNavMapRef}
              title='Products'
              selectProp='id' 
              presentProp='name' 
              childrenProp='products'
              filter={true} 
              loopAll={true}
              onSelect={onSelectProduct}
            />
          </div>
          <div className='app-products-nav-map-buttons'>
            <AddProduct onSave={loadAndUpdateSelected} parentId={!productSelected?.product ? productSelected?.id : productSelected?.parent?.id}></AddProduct>
          </div>
        </div>

        {loading && !appCtxRef.ui.loading && <Loader />}
        
      </div>
    </ProductsCtx.Provider>
  );
}

export default Products;
