import { useCallback, useRef, useState, useEffect } from 'react';
import { ProductsService } from '../../../services/ProductsService';
import { Logger } from '../../../utils/Logger';
import Modal, { ModalInterface } from '../../ui-elements/modal/Modal';
import YesNoModal from '../../ui-elements/yes-no-modal/YesNoModal';
import './ProductNumbers.scss';
import { UIUtils } from '../../../utils/UIUtils';

function ProductNumbers(props: any) {

  Logger.debug('ProductNumbers component render.');

  const modalEditRef = useRef<ModalInterface>();
  const modalDelRef = useRef<ModalInterface>();
  const [editTitle, setEditTitle] = useState<string>('');
  const [edit, setEdit] = useState<any>(null);
  const [num1, setnum1] = useState<any>('');
  const [num2, setnum2] = useState<any>('');
  const [internal12nc, setinternal12nc] = useState<any>('');

  const [objToDelete, setObjToDelete] = useState<any>(null);
  const [valid, setValid] = useState<any>({});
  
  const editTrigger = useCallback((item: any) => {
    if (!item) {
      setEdit(null);
      setEditTitle('Add PCA Number');
      setnum1('');
      setnum2('');
      setinternal12nc('');
    } else {
      setEditTitle('Edit PCA Number');
      setEdit(item);

      const parts = item.pcaNumber.split('-');
      setnum1(parts[1] ? parts[1] : '');
      setnum2(parts[2] ? parts[2] : '');
      setinternal12nc(item.internal12nc ? item.internal12nc : '');
    }
    modalEditRef?.current?.open(true);
  }, []);

  const editExe = useCallback((number: any) => {
    const p = JSON.parse(JSON.stringify(props.product));
   

    const nums = [];
    if (p.pcaNumbers && edit) {
      for(const it of p.pcaNumbers) {
        if (it.id !== edit.id) {
          nums.push(it);
        }
      }
    }

    nums.push({
      id: edit ? edit.id : null,
      internal12nc: internal12nc,
      pcaNumber: number, 
      inUse: true
    });

    p.pcaNumbers = nums;
    ProductsService.save([p]).then((resp: any) => {

      if (props.onUpdate) {
        props.onUpdate();
      }

      modalEditRef?.current?.open(false);
    }, () => {

      modalEditRef?.current?.err(editTitle + " failed. Please make sure that PCA number and/or 12nc is unique!");
      
    });
  }, [editTitle, internal12nc, props, edit]);

  const del = useCallback((item: any) => {
    setObjToDelete(item);
    modalDelRef?.current?.open(true);
  }, []);

  const delConfirm = useCallback(() => {
    ProductsService.retirePCANumber(objToDelete).then((resp: any) => {
      if (props.onUpdate) {
        props.onUpdate();
      }
      modalDelRef?.current?.open(false);
    }, () => {
      modalDelRef?.current?.err("Delete action failed.");
    });
  }, [objToDelete, props]);

  useEffect(() => {
    const v : any = {};

    if (!UIUtils.validateNumber(num1, 0, null, 0) || num1.length !== 6) {
      v['num1'] = true;
    }
    if (!UIUtils.validateNumber(num2, 0, null, 0) || num2.length !== 2) {
      v['num2'] = true;
    }
    if ((!UIUtils.validateNumber(internal12nc, 0, null, 0) && internal12nc !== '') || internal12nc.length !== 12) {
      v['internal12nc'] = true;
    }
    setValid(v);
  },[num1, num2, internal12nc]);

  return (
    <>
      <p className='mt15'>
        PCA Numbers: 
        <span className='app-products-numbers-block-add' onClick={() => {editTrigger(null)}}>
          <img className='' src='/assets/img/icons/plus-black.svg' alt='' /> 
        </span>
      </p>
      <div className='app-products-numbers-block'>
        <div className='app-products-numbers-block-items' >
          {props.product.pcaNumbers.length > 0 && props.product.pcaNumbers.map(function(row: any, rowIdx: number) {
            return(
            <span className='app-products-numbers-block-item' key={rowIdx} onClick={($event) => {editTrigger(row);$event.stopPropagation()}}>
              {row?.pcaNumber} 
              <img className='ml15' src='/assets/img/icons/x-black.svg' alt='' onClick={($event) => {del(row?.id);$event.stopPropagation()}}/>
              <br />
              <span className='app-text-gray'>12NC: <b>{row.internal12nc ? row.internal12nc : '-'}</b></span>
            </span>);
          })}
          {props.product.pcaNumbers.length === 0 && <span className='app-text-gray'>No items</span>}
        </div>
        
      </div>   
      
      <Modal ref={modalEditRef}>
        <h4 className='m20'><b>{editTitle}</b></h4>

        <div className='ml20 mr20'>
          <span>PCA - </span>
          <input type='number' className={valid['num1'] ? `app-input app-border-red` :  `app-input`} value={num1} onChange={(event: any) => {setnum1(event?.target?.value)}}/>
          <span> - </span>
          <input type='number' className={valid['num2'] ? `app-input app-border-red` :  `app-input`} value={num2} onChange={(event: any) => {setnum2(event?.target?.value)}}/>
        </div>
        
        <div className='ml20 mt10'>
          <span>12NC - </span>
          <input type='number' className={valid['internal12nc'] ? `app-input app-border-red` :  `app-input`} value={internal12nc} onChange={(event: any) => {setinternal12nc(event?.target?.value)}}/>
        </div>

        <p className='app-text-darkred m20'>PCA number must be in format: 'PCA-XXXXXX-XX'</p>
        
        <div className='m20'>
          <button className='app-btn-secondary app-width-100' 
          disabled={Object.keys(valid).length !== 0}
            onClick={() => {editExe('PCA-' + num1 + '-' + num2)}}>
              Save
          </button>
        </div>
      </Modal> 

      <YesNoModal ref={modalDelRef} yes={delConfirm} yesCloseDisabled={true}>
      </YesNoModal>
    </>
  );
}
export default ProductNumbers;

