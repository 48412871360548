import axios from "axios";
import React from "react";
import AppCtx from "../AppCtx";
import { AuthService } from "../services/AuthService";
import { Logger } from "../utils/Logger";
import { AUTH_URL } from "./AppSettings";

class AppInterceptor extends React.Component {

  static contextType = AppCtx;

  state = {
    executed: false
  }

  constructor (props: any) {
    Logger.debug('AppInterceptor component init.');
    super(props);   
  }

  componentDidMount() {
    Logger.debug('AppInterceptor component mount.');
    if(!this.state.executed) {
      Logger.debug('AppInterceptor component execute.');
      this.execute();
    }
    
    this.setState({executed: true});
  }

  execute() {

    axios.interceptors.request.use((request: any) => {

      if (!/.html/.test(request.url)
      && !/.Pagination/.test(request.url)
      && !/.json/.test(request.url)
      && !/.pem/.test(request.url)
      && !/http./.test(request.url)
      && !/www./.test(request.url)) {           
        request.url = AUTH_URL + request.url;

        if (this.context && this.context.session && this.context.session.auth && this.context.session.auth.access_token) {
          request.headers['authorization'] = 'Bearer ' + this.context.session.auth.access_token;
        }
      }
      
      return request;
    }, (error: any) => {
      return Promise.reject(error);
    });
  
  
    axios.interceptors.response.use((response: any) => {
      return response;
    }, (error: any) => {
      
      if (error?.response.status === 401) {
        AuthService.logout();
        this.context.setUi({...this.context.ui, loading: true});
      }  
      return Promise.reject(error);
    });
  }
  
  // !!! Never rerender, run only once at startup
  render() {
    return null;
  }
  shouldComponentUpdate = () => false

}
export default AppInterceptor;