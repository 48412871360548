import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import AppCtx from '../../AppCtx';
import './Query.scss';
import { Logger } from '../../utils/Logger';
import NavMap from '../../components/ui-elements/nav-map/NavMap';
import Loader from '../../components/ui-elements/loader/Loader';
import { ProductsService } from '../../services/ProductsService';
import { QueryService } from '../../services/QueryService';
import { DateUtils } from '../../utils/DateUtils';
import QueryDetails from '../../components/query/query-details/QueryDetails';
import { APP_FEATURES } from '../../core/AppConstants';

function Query() {
  Logger.debug('Query component render.');

  const modalNavMapRef = useRef<any>();
  const modalNavMapResultsRef = useRef<any>();

  const appCtxRef = useContext(AppCtx);

  const [READ_PERMISSION] = useState<boolean>(appCtxRef?.session?.features[APP_FEATURES.AlgorithmBinningQuery]);

  const [from, setFrom] = useState<any>('');
  const [to, setTo] = useState<any>('');
  const [bincode, setBincode] = useState<any>('');
  const [results, setResults] = useState<any>([]);
  const [selected, setSelected] = useState<any>(null);

  const [loading, setLoading] = useState<any>(false);
  const [hideModileNav, setSideModileNav] = useState<boolean>(true);
  const [productsData, setProductsData] = useState<any>({});
  const [productSelected, setProducSelected] = useState<any>(null);

  const load = useCallback(() => {

    if (appCtxRef.core.products) {
      modalNavMapRef?.current?.load(appCtxRef.core.products);
     
      const timeoutRef = setTimeout(() => {
        setLoading(false);
      }, 200);
      return () => {
        clearTimeout(timeoutRef);
      };
    } else {
      ProductsService.all().then((resp: any) => {
        appCtxRef.setCore({...appCtxRef.core, products: resp.data});
        modalNavMapRef?.current?.load(resp.data);
        setLoading(false);
      });
    }
    
  }, [appCtxRef]);

  useEffect(() => {
    load();
  }, [load]);

  const exportItem = useCallback(() => {
    QueryService.export(productSelected?.id, from, to, null, bincode).then((resp: any) => {
      const url = URL.createObjectURL(new Blob([resp.data], { type: 'octet/stream' }));
      const element = document.createElement('a');
      element.setAttribute('href', url);
      
      const date = new Date(); // Current date
      const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;

      element.setAttribute('download', formattedDate  + '_' + productSelected?.name + '.zip');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
  }, [from, to, bincode, productSelected]); 

  const query = useCallback(() => {
    QueryService.query(productSelected?.id, from, to, bincode).then((resp: any) => {
      const res = resp.data?.map(function(row: any) {
        row.createDate = DateUtils.format(row.createDate, null);
        return row;
      });
      modalNavMapResultsRef?.current?.load(res);
      setResults(res);
      modalNavMapResultsRef?.current?.select(null);
      setSelected(null);
    });
  }, [from, to, bincode, productSelected]); 

  const reset = useCallback(() => {
    setResults([]);
    modalNavMapResultsRef?.current?.load([]);
    setSelected(null);
  }, []); 

  const onSelectProduct = useCallback((item: any) => {
    reset();

    if (item?.id && !productsData[item?.id]) {
      ProductsService.get(item?.id).then((resp: any) => {
        productsData[item?.id] = resp.data;
        const it = productsData[item?.id];
        it.products = item.products;
        setProducSelected(it);
        setSideModileNav(true);
        setProductsData(productsData);
      }, () => {
        setProducSelected(null);
        setSideModileNav(true);
        setProductsData({});
      });
    } else {
      const it = productsData[item?.id];
      if (it) {
        it.products = item.products;
      }
      setProducSelected(it);
      setSideModileNav(true);
    }
  }, [productsData, reset]);  

  if (!READ_PERMISSION) {
    return (
      <div className="app-products app-query">
      <span className='app-no-permission-content'>
        You don't have permission to access this page.
        <br />
        Please contact your administrator.
      </span>
      </div>
    );
  }
  
  
  return (
      <div className="app-products app-query">
        
        <div className='app-products-content'>

          <div className="app-query-filter app-width-100 p20">
            <div>
              <p><b>From:</b></p>
              <input placeholder='From' type="date" className='app-input mr10'  
                  value={from} onChange={(e: any) => {setFrom(e.target.value)}}/>
            </div>
            <div>
              <p><b>To:</b></p>
              <input placeholder='To' type="date" className='app-input mr10'  
                  value={to} onChange={(e: any) => {setTo(e.target.value)}}/>
            </div>
            <div>
              <p><b>Bincode:</b></p>
              <input placeholder='Bincode' className='app-input mr10'  
                  value={bincode} onChange={(e: any) => {setBincode(e.target.value)}}/>
            </div>
            <div>
              <button className='app-btn-secondary' onClick={() => {query()}}>Query</button>
            </div>
          </div>

            <div className="app-query-data ml20 mr20">
              {selected && <QueryDetails productSelected={productSelected} selectedId={selected?.id} refresh={query}></QueryDetails>}
            </div>
            <div className="app-query-list mr20">
              <div className='app-query-list-wrapper'>
                    <NavMap 
                      ref={modalNavMapResultsRef}
                      title='Recipes'
                      selectProp='id' 
                      presentProp='createDate' 
                      filter={false} 
                      selectFirst={false}
                      onSelect={setSelected}
                    />
              </div>
              {results.length > 0 && <div className='app-query-list-buttons'>
                <button className='app-btn-neutral' onClick={() => {exportItem()}}>Export</button>
              </div>}
            </div>
        </div>     


        <div className={`app-products-nav-map ${hideModileNav ? "app-products-nav-map-hide" : ""}`} >
          <NavMap 
            ref={modalNavMapRef}
            title='Products'
            selectProp='id' 
            presentProp='name' 
            childrenProp='products'
            filter={true} 
            loopAll={true}
            onSelect={onSelectProduct}
          />
        </div>

        {loading && !appCtxRef.ui.loading && <Loader />}

      </div>
  );
}

export default Query;
