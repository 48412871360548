import { useRef } from 'react';
import { ProductsService } from '../../../services/ProductsService';
import { Logger } from '../../../utils/Logger';
import { ModalInterface } from '../../ui-elements/modal/Modal';
import YesNoModal from '../../ui-elements/yes-no-modal/YesNoModal';
import './DelProduct.scss';

function DelProduct(props: any) {

  Logger.debug('DelProduct component render.');

  const modalRef = useRef<ModalInterface>();

  const save = () => {
    ProductsService.retire(props.delId).then(() => {
      if (props.onSave) {
        props.onSave();
      }
      modalRef?.current?.open(false);
    }).catch(() => {
      modalRef?.current?.err("Delete action failed.");
    });
  }

  return (
    <>
      <button className='app-product-del-btn app-btn-secondary' onClick={() => {modalRef?.current?.open(true);}}>
        Delete
      </button>
      <YesNoModal ref={modalRef} yes={save} yesCloseDisabled={true}
      subtitle={props.product ? '' : 'Warning! By deleting this folder all related products will be also deleted.'}>
      </YesNoModal>
    </>
  );
}
export default DelProduct;

