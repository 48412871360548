import { useRef } from 'react';
import { ManufacturersService } from '../../../services/ManufacturersService';
import { Logger } from '../../../utils/Logger';
import { ModalInterface } from '../../ui-elements/modal/Modal';
import YesNoModal from '../../ui-elements/yes-no-modal/YesNoModal';
import './DelManufacturer.scss';

function DelManufacturer(props: any) {

  Logger.debug('DelManufacturer component render.');

  const modalRef = useRef<ModalInterface>();

  const save = () => {
    ManufacturersService.retire(props.delId).then(() => {
      if (props.onSave) {
        props.onSave();
      }
      modalRef?.current?.open(false);
    }).catch(() => {
      modalRef?.current?.err("Delete Action failed.");
    });
  }

  return (
    <>
      <button className='app-manufacturer-del-btn app-btn-secondary' onClick={() => {modalRef?.current?.open(true);}}>
        Delete
      </button>
      <YesNoModal ref={modalRef} yes={save} yesCloseDisabled={true}
      subtitle={'Warning! Deleting manufacturer will affect all related products.'}>
      </YesNoModal>
    </>
  );
}
export default DelManufacturer;

