import { useContext, useEffect, useRef, useState } from 'react';
import { ManufacturersService } from '../../../../services/ManufacturersService';
import { Logger } from '../../../../utils/Logger';
import Modal, { ModalInterface } from '../../../ui-elements/modal/Modal';
import './AddColor.scss';
import { UIUtils } from '../../../../utils/UIUtils';
import AppCtx from '../../../../AppCtx';
import ManufacturersCtx from '../../../../pages/manufacturers/ManufacturersCtx';

function AddColor(props: any) {

  Logger.debug('AddColor component render.');

  const modalRef = useRef<ModalInterface>();
  const [name, setName] = useState<any>('');
  const [code, setCode] = useState<any>('');
  const [id, setId] = useState<any>('');
  const [type, setType] = useState('CHROMATICITY');
  const [fail, setFail] = useState(false);
  const [valid, setValid] = useState<any>({});
  const appCtxRef = useContext(AppCtx);
  const manufacturersCtxRef = useContext((ManufacturersCtx));

  const save = () => {
    const req: any = {
      name,
      colorType: type,
      scmInternalId: id,
      orderCode: code,
      manufacturer: {
        id: props?.manufacturerId
      },
      inUse: true
    };
    if (props?.edit?.id) {
      req.id = props?.edit?.id;
    }

    ManufacturersService.saveColor([req]).then((resp: any) => {
      ManufacturersService.all().then((resp: any) => {
        manufacturersCtxRef.setManufacturers(resp.data);
        appCtxRef.setCore({...appCtxRef.core, manufacturers: resp.data});
      });
      if (props.onSave) {
        props.onSave(props?.edit?.id ? null : resp.data);
      }
      setName('');
      setCode('');
      setId('');
      setType('CHROMATICITY');
      modalRef?.current?.open(false);
    }).catch(() => {
      setFail(true);
    });
  }

  const open = (() => {
    setName(props?.edit?.name ? props?.edit?.name : '');
    setType(props?.edit?.colorType ? props?.edit?.colorType : 'CHROMATICITY');
    setId(props?.edit?.scmInternalId ? props?.edit?.scmInternalId : '');
    setCode(props?.edit?.orderCode ? props?.edit?.orderCode : '');

    modalRef?.current?.open(true);
  });

  useEffect(() => {
    const v : any = {};

    if (!name) {
      v['name'] = true;
    }
    if (!code) {
      v['code'] = true;
    }
    if (!UIUtils.validateNumber(id, 0, null, 0) || id.length !== 12) {
      v['id'] = true;
    }

    setValid(v);
  },[name ,code ,id]);


  return (
    <>
      <button className={props?.edit ? 'app-color-add-btn app-btn-neutral' : 'app-color-add-btn app-btn-secondary'} onClick={() => { open(); }}>
        {props?.edit ? 'Edit' : 'Add LED'}
      </button>
      <Modal ref={modalRef}>
        <div className='app-color-add'>
          <h2>{props?.edit ? 'Edit' : 'Add'} LED</h2>

          <p>Name</p>
          <input placeholder='Name' className={valid['name'] ? `app-input app-border-red` :  `app-input`} value={name} 
            onChange={(event: any) => {setFail(false); setName(event.target.value);}} />

          <p>Order Code</p>
          <input placeholder='Order Code' className={valid['code'] ? `app-input app-border-red` :  `app-input`} value={code} 
            onChange={(event: any) => {setFail(false); setCode(event.target.value);}} />

          <p>12NC</p>
          <input type='number' placeholder='12NC' className={valid['id'] ? `app-input app-border-red` :  `app-input`} value={id} 
            onChange={(event: any) => {setFail(false);setId(event.target.value);}} />

          <p className='mt15'>Type</p>
          <select className='app-input' value={type}  onChange={(event: any) => {setFail(false); setType(event.target.value);}} >
            <option value="CHROMATICITY">Chromaticity</option>
            <option value="WAVELENGTH">Wavelength</option>
          </select>
            
          {fail && <p className='app-text-darkred'>*This color could not be saved! It might be that 12nc already exists!</p>}

          <button className='app-btn-secondary' disabled={Object.keys(valid).length !== 0} onClick={() => { save(); }}>Save</button>
        </div>
      </Modal>
    </>
  );
}
export default AddColor;

